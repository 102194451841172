<app-header-property></app-header-property>
<app-menu></app-menu>

<div class="row p-2">
  <div class="col-md-3">
    <app-property-slide (payloadEvent)="onPayloadReceived($event)"></app-property-slide>
  </div>

  <div class="col-md-9">
    <!-- Show Loading Indicator While Data is Being Fetched -->
    <ng-container *ngIf="loading$ | async; else showListings">
      <app-loading></app-loading>
    </ng-container>

    <!-- Listings Section -->
    <ng-template #showListings>
      <ng-container *ngIf="listings.length > 0; else noListings">
        <ng-container *ngFor="let listing of listings;">
          <app-property-card [listing]="listing" [editButton]="false"></app-property-card>
        </ng-container>

        <!-- Pagination -->
        <div class="pagination-container">
          <span class="page-info">Page {{ currentPage + 1 }} of {{ totalPages }}</span>

          <div class="pagination">
            <button
              class="page-link"
              (click)="changePage(currentPage - 1)"
              [disabled]="currentPage === 0"
            >
              &lt; Previous Page
            </button>

            <ng-container *ngFor="let page of getPages(); let i = index">
              <button
                class="page-number"
                [class.active]="currentPage === i"
                (click)="changePage(i)"
              >
                {{ i + 1 }}
              </button>
            </ng-container>

            <button
              class="page-link"
              (click)="changePage(currentPage + 1)"
              [disabled]="currentPage + 1 >= totalPages"
            >
              Next Page &gt;
            </button>
          </div>
        </div>

        <!-- Scroll to Top Button -->
        <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
          ⬆
        </div>
      </ng-container>
    </ng-template>

    <!-- No Listings Found Message (Custom Not Found Section) -->
    <ng-template #noListings>
      <div class="not-found-container">
        <div class="not-found">
          <h1>Data Not Found</h1>
          <p style="margin-bottom: 10px;">Sorry, the data you are looking for does not exist.</p>
          <a routerLink="/" (click)="refresh()" class="back-link">Go back to Home</a>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<app-footer></app-footer>
