<!-- <app-header></app-header><br> -->
<div class="wrapper">
  <app-header></app-header>
<div class="content"><br>
  <div *ngIf="listingData">
  <div class="col-12 first-container">
    <div class="listing-image">
      <ng-container *ngIf="Images.length > 0; else noImage">
        <app-image-slide [slides]="Images"></app-image-slide>
      </ng-container>
      <ng-template #noImage>
        <app-image-slide [slides]="['../../assets/images/Images_not_found (1).png']"></app-image-slide>

      </ng-template>
    </div>
    </div>

    <div class="col-12 first-container">
      <mat-card class="project-overview " style="text-align: center;">
        <mat-card-title class="project-title mb-3">
          {{ title  | capitalizeFirstLetter}}<br>
        </mat-card-title><hr>

        <mat-card-content>
          <div class="project-details">
            <div class="detail">
              <mat-icon>work_outline</mat-icon>
              <span>Project Name :</span>
              <strong>{{projectName | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>straighten</mat-icon>
              <span>Type :</span>
              <strong>{{inventoryType | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>location_city</mat-icon>
              <span>Area:</span>
              <strong>     {{ location  | capitalizeFirstLetter}}, {{ cityName | capitalizeFirstLetter}}, {{ stateName | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>inventory_2</mat-icon>
              <span>Total Inventory :</span>
              <strong>{{totalInventory || 0}}</strong>
            </div>
            <!-- <div class="detail">
              <mat-icon>{{ status ? 'check_circle' : 'cancel' }}</mat-icon>
              <span>Status :</span>
              <strong>{{ status ? 'Active' : 'Inactive' }}</strong>
            </div> -->

            <div class="detail">
              <mat-icon>assignment</mat-icon>
              <span>Rera Approved :</span>
              <strong> {{reraApproved | capitalizeFirstLetter}} </strong>
            </div>
            <div class="detail">
              <mat-icon>price_change</mat-icon>
              <span>Price :</span>
              <strong>₹{{fromPrice | formatNumber: fromPriceUnit }} - ₹{{toPrice  | formatNumber :toPriceUnit }}</strong>
            </div>
            <div class="detail">
              <mat-icon>attach_money</mat-icon>
              <span>Loan Available :</span>
              <strong>{{loanAvailable | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>home</mat-icon>
              <span>Total Size :</span>
              <strong>{{size}}&nbsp;{{sizeUnit}}</strong>
            </div>
          </div>
          <hr>
          <div class="detail1">
            <span>Description</span>
            <div class="description">{{description}}</div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-12 first-container">
      <mat-card class="project-overview " style="text-align: center;">
        <mat-card-title class="project-title mb-3">
          Project Specifications
          <br>
        </mat-card-title><hr>
        <mat-card-content>
          <div class="project-details">
            <div class="detail">
              <mat-icon>construction</mat-icon>
              <span>Builder:</span>
              <strong>{{builder | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>signal_wifi_4_bar</mat-icon>
              <span>Connectivity :</span>
              <strong>{{connectivity | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>build</mat-icon>
              <span>Construction Approvals:</span>
              <strong>     {{ constructionApprovals  | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>list</mat-icon>
              <span>Flat Purchase Checklist :</span>
              <strong>{{flatPurchaseChecklist | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon aria-label="Investment Perspective Icon">info</mat-icon>

              <span>Investment Perspective :</span>
              <strong>{{investmentPerspective | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>security</mat-icon>

              <span>Security :</span>
              <strong> {{security | capitalizeFirstLetter}} </strong>
            </div>
            <div class="detail">
              <mat-icon>vpn_key</mat-icon>
              <span>Access To :</span>
              <strong>{{accessTo | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>warning</mat-icon>
              <span>Zoning Risks :</span>
              <strong>{{zoningRisks | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>check_circle</mat-icon>
              <span>Verification :</span>
              <strong>{{verification | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>star</mat-icon>
              <span>Modern Features :</span>
              <strong>{{modernFeatures | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>assignment</mat-icon>
              <span>No Objection Certificates :</span>
              <strong>{{noObjectionCertificates | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <mat-icon>bar_chart</mat-icon>
              <span>Market Research :</span>
              <strong>{{marketResearch | capitalizeFirstLetter}}</strong>
            </div>
          </div>
          <hr>
          <div class="detail12"  >
            <button class="contact-seller-btna" (click)="inventory($event)">
              <i class="fa fa-eye" aria-hidden="true"></i>
              View Inventory
          </button>
            <div class="description1"></div>
            <button class="contact-seller-btna" *ngIf="listingData" (click)="openContactDetailsDialog(listingData)">
              <i class="fa fa-phone" aria-hidden="true"></i>
              Contact Seller
            </button>
        </div>
        </mat-card-content>
      </mat-card>
    </div>




</div>
<div class="col-12 first-container">
  <mat-card class="project-overview">
    <mat-card-content>
      <div class="project-details">
        <div class="detail mt-2">
          <span class="posted" style="color: black;">Posted by Owner :</span>
          <strong style="color: gray;">{{postdate | timeAgo }}</strong>
        </div>
        <div class="detail10 mt-2 button-container">
          <button mat-button class="right-align-button" (click)="back()">Back</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

</div>
<br>
<app-footer></app-footer>
</div>





























<!--
<br><br>
  <app-footer></app-footer> -->
