import { Component, OnInit, ChangeDetectorRef, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../_services/token-storage.service';
import { environmentbasic } from '../../environments/environment-basic';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser } from "angularx-social-login";
import { EmailverifyComponent } from '../verifymodel/emailverify/emailverify.component';
import { MatDialog } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import { switchMap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ErrorMessageService } from '../services/error-message.service';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter } from '@angular/core';
import { UserProfileService } from '../_services/user-profile.service';
@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss']
})
export class LoginPopupComponent implements OnInit {
  currentForm: string = 'login';
  isSignIn: boolean = true;
  showPassword: boolean = false;
  showPasswordsignup: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    public dialog: MatDialog,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private socialauthService: SocialAuthService,
    private errorMessageService: ErrorMessageService,
    public dialogRef: MatDialogRef<LoginPopupComponent>,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private toastr:ToastrService,
    private userProfileService:UserProfileService
  ) { }

  @Output() userLoggedIn: EventEmitter<void> = new EventEmitter();
  toggle() {
    this.currentForm = this.currentForm === 'login' ? 'signup' : 'login';
  }
  forgotSwitch() {
    this.currentForm = 'forgot';
  }

  loginSwitch() {
    this.currentForm = 'login';
  }
  signIn(): void {
    console.log("Sign-in clicked");
  }

  signUp(): void {
    console.log("Sign-up clicked");
  }
  ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9_]*$/;
  swapclass = '';
  activereset = false;
  regform: FormGroup;
  otpform: FormGroup;
  loginform: FormGroup;
  forgotform: FormGroup; unique
  id!: string;
  loading = false;
  submitted = false;
  otpactive = false;
  registrationactive = true;
  uservalid = '';
  otpvalid = '';
  useremail = '';
  dropdownSettings = {};
  GoogleLoginProvider = GoogleLoginProvider;
  user: any;
  userinfo: any = [];
  countries = ['USA', 'Canada', 'UK', 'Australia', 'India']; // Add m
  // toppingList: string[] = ['1', '2', '3','4'];

  toppingList = [
    { id: 1, value: 1 },
    { id: 2, value: 2 }
  ];
  toppingList1 = [
    { id: 1, value: 1 },
    { id: 2, value: 2 }
  ];

  disableEmail: boolean = false;
  EmailId: string | null = null;

  onUserTypeChange(userType: string) {
    this.regform.patchValue({
      individual: userType === 'individual',
      builder: userType === 'builder',
      agent: userType === 'agent'
    });
  }

  ngOnInit(): void {

    console.log("in login page");

    this.regform = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      userType: [[], Validators.required],
      productUser: ['g3g_estate'],
    });

    this.otpform = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.loginform = this.formBuilder.group({
      emailusernam: ['', [Validators.required, Validators.minLength(8)]],
      loginpassword: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.forgotform = this.formBuilder.group({
      forgotemail: ['', [Validators.required, Validators.email]],
    });

    this.socialauthService.authState.subscribe((response) => {
      this.user = response;
      if (this.user) {
        let token = null;
        if (this.user.provider == 'FACEBOOK') {
          token = this.user.authToken;
        }
        if (this.user.provider == 'GOOGLE') {
          token = this.user.idToken;
        }
        let tokenInStorage = { 'access_token': token };
        this.tokenStorage.saveToken(JSON.stringify(tokenInStorage));
        this.tokenStorage.saveSocialOrigin(this.user.provider);
        this.tokenStorage.saveSocialUserEmail(this.user.email);
        Swal.fire({
          icon: 'success',
          title: 'User verifcation is completed.',
          showConfirmButton: false,
          timer: environmentbasic.timer
        });
        // window.location.href = '/';
        window.location.reload();

        // let currentUrl = this.router.url;
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate([currentUrl]);

        this.closeDialog()

      } else {
        Swal.fire({
          icon: 'error',
          title: response['message'],
          showConfirmButton: false,
          timer: environmentbasic.timer
        });
      }
    });
  }

  get f() { return this.regform.controls; }
  get g() { return this.otpform.controls; }
  get h() { return this.loginform.controls; }
  get k() { return this.forgotform.controls; }


  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-digit characters
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10); // Limit to 10 digits
    }
  }
  onSubmit() {
    this.submitted = true;
    if (this.regform.invalid) {
      return;
    }

    let formData = this.regform.value;

    const { individual, builder, agent } = formData.userType;
    formData = {
      ...formData,
      individual,
      builder,
      agent
    };

    delete formData.userType;

    console.log("regform", formData);

    this.uniqueUsername(formData);
    localStorage.setItem('profileData', JSON.stringify(formData));
  }


  submittedf = false;
  onSubmitOtp() {
    this.otpvalid = '';
    this.submittedf = true;

    if (this.otpform.invalid) {
      return;
    }

    this.authService.verifyOtp(this.useremail, this.otpform.value.otp).subscribe(

      response => {
        if (response['status'] == 'SUCCESS') {
          this.registrationactive = false;
          this.otpactive = true;
          Swal.fire({
            icon: 'success',
            title: 'User verifcation is completed.',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          // window.location.reload();
          // window.location.reload();

          // let currentUrl = this.router.url;
          // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          // this.router.onSameUrlNavigation = 'reload';
          // this.router.navigate([currentUrl]);

          this.closeDialog()

        } else if (response['message']) {
          let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          this.toastr.error(errorMessage, 'Error');
        }
      });
  }
  submittedl = false;
  onLoginSubmit() {
    this.loading = true;
    this.submittedl = true;

    if (this.loginform.invalid) {
      this.loading = false;
      return;
    }

    this.authService.loginUser(
      this.loginform.value.emailusernam,
      this.loginform.value.loginpassword,
      'g3g_estate'
    ).pipe(
      switchMap(response => {
        console.log(response);

        if (response && response.status === "SUCCESS") {
          const accessT = response.payload.access_token;
          const refreshToken = response.payload.refresh_token;
          const expiresIn = response.payload.expires_in;

          this.tokenStorage.saveToken(JSON.stringify(response.payload));
          localStorage.setItem("access_token", accessT);
          localStorage.setItem("refresh_token", refreshToken);
          localStorage.setItem("expires_in", expiresIn);

          this.tokenStorage.saveSocialOrigin(null);
          this.tokenStorage.saveSocialUserEmail(null);
          this.tokenStorage.saveFirstVisitToHomePageAfterLogin("firstVisit");

          // Fetch user profile after login
          return this.authService.getProfileData();
        } else if (response?.error_code === "USER_NOT_VERIFIED") {
          this.loading = false;
          throw new Error('User not verified');
        } else if (response?.message) {
          let errorMessage = this.errorMessageService.getErrorMessage(response.message);
          this.toastr.error(errorMessage, 'Error');
          throw new Error(errorMessage);
        }

        throw new Error('Unexpected error during login');
      })
    ).subscribe(
      profileData => {
        if (profileData) {
          // Save profile and update UI
          this.userProfileService.updateUserProfile(profileData);
        }
        window.location.reload();

        this.userLoggedIn.emit();

        Swal.fire({
          icon: 'success',
          title: 'You logged in successfully.',
          showConfirmButton: false,
          timer: environmentbasic.timer
        }).then(() => {
          this.closeDialog();
        });

        this.loading = false;
      },
      (error) => {
        const message = error?.error?.message || 'Failed to fetch user data';
        const errorMessage = this.errorMessageService.getErrorMessage(message);
        this.toastr.error(errorMessage, 'Error');
        this.loading = false;
      }
    );
  }






  signUpSwap() {
    this.swapclass = 'right-panel-active';
  }

  signInSwap() {
    this.swapclass = '';
  }
  uniqueUsername(formdata) {
    console.log(formdata);

    this.loading = true;


    this.authService.userRegister(formdata).subscribe(
      response => {

        console.log(formdata);

        if (response['status'] == 'SUCCESS') {
          // this.registrationactive = false;
          this.toggle()
          // this.otpactive = true;
          // this.useremail = response['payload']['email'];
          // this.tokenStorage.saveFirstVisitToHomePageAfterLogin("firstVisit");
          Swal.fire({
            icon: 'success',
            title: response['payload']['fullName'] + ' is registed successfully.',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          this.closeDialog()
        }
        else if (response['message']) {
          let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          this.toastr.error(errorMessage, 'Error');
        }
        this.loading = false;
      });

    this.loading = false;
  }

  onForgotSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.forgotform.invalid) {
      return;
    }
    this.authService.forgotPassword(this.forgotform.value.forgotemail).subscribe(
      response => {
        if (response['status'] == 'SUCCESS') {
          Swal.fire({
            icon: 'success',
            title: 'OTP shared over your registered email,please check your inbox.',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          this.closeDialog()
          this.router.navigate(['/setnewpassword/' + this.forgotform.value.forgotemail]);
        }
        else if (response['message']) {
          let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          this.toastr.error(errorMessage, 'Error');
        }


        this.loading = false;
      });
  }
  signInWithFB(): void {
    this.socialauthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.socialauthService.signOut();
  }

  signInWithGoogle(): void {
    this.socialauthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }




  verifyOtp(email: string, otp: string): void {
    this.authService.verifyOtp(email, otp).subscribe(response => {
      console.log(response);

      //   if (response.status === 'SUCCESS') {
      // 	// Handle success
      // 	console.log('OTP verified successfully');
      //   } else {
      // 	// Handle error
      // 	console.error('OTP verification failed');
      //   }
    });
  }
  // This method handles only input blur if you need to validate the input
  onEmailInputBlur(email: string) {
    if (!email) {
      console.error("Email ID is empty or undefined.");
    }
  }

  // This method will be called when the 'Verify' button is clicked
  onVerifyEmail(email_Id: string) {
    console.log("email_Id", email_Id);
    if (!email_Id) {
      console.error("Email ID is empty or undefined.");
      return;
    }

    this.authService.sendemailotp(email_Id).subscribe(
      (res: any) => {
        console.log(res);
        if (res && res.status === 'SUCCESS') {
          this.showModal(email_Id);
          console.log('res', res);
        }
        else if (res['message']) {
          let errorMessage = this.errorMessageService.getErrorMessage(res['message']);
          this.toastr.error(errorMessage, 'Error');
        }

        else {
          console.error("Unexpected response:", res);
        }
      },
      (error) => {
        // Handle error
        console.error("Error sending OTP", error);
      }
    );
  }

  showModal(email: string): void {
    const dialogRef = this.dialog.open(EmailverifyComponent, {
      width: '450px',
      data: { email: email },
      disableClose: false,
    });

    dialogRef.componentInstance.emailVerified.subscribe((status: boolean) => {
      if (status) {
        this.disableEmail = true;
        this.EmailId = email;
        this.regform.get('email')?.setValue(email);
      }
    });
  }



  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  togglePasswordsignup(): void {
    this.showPasswordsignup = !this.showPasswordsignup;
  }
  closeDialog() {
    this.dialogRef.close();
  }
  navigatehome() {
    this.router.navigate(['/']);

  }
}

