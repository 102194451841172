import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomeService } from '../_services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
import { ErrorMessageService } from '../services/error-message.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
})
export class AddProjectComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper;
  isFocused: boolean = false;
  selectedFiles: File[] = [];
  selectedFile: File[] = [];
  projectId: number | null = null;

  selectedUnit: number = 1;
  selectedWidth: string = '';
  selectedSize: string = '';

  calculatedWidth: string = '';
  calculatedSize: string = '';
  inputAsking: number = 0;
  inputWidth: string = '';
  inputSize: string = '';
  selectedFileNames: string[] = [];
  selectedFileName: string[] = [];

  selectedCityId1: number;
  // selectedCityId:any
  projectCityId: any;

  isLinear = false;
  countryId = 259;
  stateList: any[] = [];
  cityDataList: any[] = [];
  id: any;
  stateId: number;
  stateName: string;
  data: any;
  cityid: number;
  payload: any;
  paramsData: any;
  projectid: string;
  statusValue: any;
  cityName: any;
  pdfurl: any;
  media: any = [];
  length: number = 1;
  image: any = [];
  Urls: string[] = [];
  imageItems: any[] = [
    {
      constrolName: 'bannerImage',
      purpose: 'bannerImage',
      index: 0,
      url: '',
      type: 'image',
      controlArray: true,
      loading: false,
      linkadd: false,
    },
  ];

  imageItem: any[] = [
    {
      constrolName: 'imageUrls',
      purpose: 'propertiesImage',
      index: 0,
      url: '',
      type: 'image',
      controlArray: true,
      loading: false,
      linkadd: false,
    },
  ];

  uploadedImages: any[] = [];
  uploadedbanner: any[] = [];
  projectFeature: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  constructor(
    private _formBuilder: FormBuilder,
    private homeService: HomeService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private errorMessageService: ErrorMessageService
  ) {}
  get description() {
    return this.firstFormGroup.get('description');
  }
  // if (featuresData.askingUnit === 'Lac') {
  //   asking *= 100000;
  // } else if (featuresData.askingUnit === 'Cr') {
  //   asking *= 10000000;
  // }
  ngOnInit(): void {
    const emptysearch = '';
    this.getCitybyCountryId(emptysearch);
    this.route.queryParams.subscribe((params) => {
      this.projectId = +params['id']; // Make sure you use `this.projectId` here
      if (this.projectId) {
        // Corrected variable name
        this.projectById();
      } else {
        console.error('No projectid found in query params');
      }
    });

    this.firstFormGroup = this._formBuilder.group({
      // cityId: ['', Validators.required],
      cityName: ['', Validators.required],
      description: ['', Validators.required],
      fromPrice: ['', Validators.required],
      toPrice: ['', Validators.required],
      toPriceUnit: ['', Validators.required],
      fromPriceUnit: ['', Validators.required],
      reraApproved: ['', Validators.required],

      inventoryType: ['', Validators.required],
      loanAvailable: ['', Validators.required],
      location: ['', Validators.required],
      projectName: ['', Validators.required],
      stateId: ['', Validators.required],
      size: ['', Validators.required],
      sizeUnit: ['', Validators.required],
      status: ['', Validators.required],
      title: ['', Validators.required],
      uploadbanner: [],
      uploadPdf: [],
    });

    this.secondFormGroup = this._formBuilder.group({
      builder: ['', Validators.required],
      connectivity: ['', Validators.required],
      constructionApprovals: ['', Validators.required],
      flatPurchaseChecklist: ['', Validators.required],
      investmentPerspective: ['', Validators.required],
      security: ['', Validators.required],
      accessTo: ['', Validators.required],
      zoningRisks: ['', Validators.required],
      verification: ['', Validators.required],
      modernFeatures: ['', Validators.required],
      noObjectionCertificates: ['', Validators.required],
      marketResearch: ['', Validators.required],
    });
    // this.route.paramMap.subscribe(params => {
    //   this.projectId = +params.get('id')!;
    //   console.log("this.projectId",this.projectId)
    // });
  }

  onStepChange(event: any) {
    // Check which step is being selected
    const stepIndex = event.selectedIndex;
    // Validation for the first form group
    if (stepIndex === 0 && this.firstFormGroup.invalid) {
      event.preventDefault();
      this.firstFormGroup.markAllAsTouched();
      return;
    }
    // Validation for the second form group
    if (stepIndex === 1 && this.secondFormGroup.invalid) {
      event.preventDefault();
      this.secondFormGroup.markAllAsTouched();
      return;
    }
    // Validation for the third form group
  }

  handleCancel() {
    const id = this.route.snapshot.queryParamMap.get('id');
    if (id) {
      this.router.navigate(['/myprojects']);
    } else {
      this.router.navigate(['/myprojects']);
    }
  }
  // onInput(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-digit characters
  //   if (input.value.length > 5) {
  //     input.value = input.value.slice(0, 5); // Limit to 10 digits
  //   }
  // }
  // getCitybyCountryId() {
  //   this.homeService.getCitybyCountryId().subscribe(
  //     (response: any) => {
  //       if (response.status === 'SUCCESS') {
  //         this.cityDataList = response.payload.cityDataList;
  //       } else {
  //         console.error('Failed to fetch city data:', response);
  //       }
  //     },
  //     (error) => {
  //       console.error('Error fetching city data:', error);
  //     }
  //   );
  // }
  getCitybyCountryId(searchText: string) {
    this.homeService.searchCity(searchText).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.cityDataList = response.payload;

          console.log(this.cityDataList);
        } else {
          console.error('Failed to fetch city data:', response);
        }
      },
      (error) => {
        const message =
          error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }
      }
    );
  }
  // onCityChange(event: any) {
  //   const selectedCityId = event.value;
  //   this.getStateByCityId(selectedCityId);
  // }

  onCityChange(event: any) {
    console.log(event);

    console.log('onCityChange triggered');
    this.selectedCity = event.option.value;
    console.log('this.selectedCity', this.selectedCity);
    this.selectedCityId1 = this.selectedCity.id;
    this.projectCityId = this.selectedCity.name;
    // console.log('selectedCityId:>>>>>>>>>>>>>>>>>>', this.projectCityId,this.selectedCityId1);
    this.citySelected = true;
    this.getStateByCityId(this.selectedCityId1);
  }
  onCitySearch(event: any) {
    const searchValue = event.target.value;
    if (searchValue.length > -1) {
      this.getCitybyCountryId(searchValue);
    } else {
      this.getCitybyCountryId(null);
    }
  }
  displayCityName(city: any): string {
    console.log('gcd>>>>>>>>>>>>>>>>>>>>>>>>>>', city);
    if (!city) return '';
    return typeof city === 'object' && 'name' in city
      ? city.name
      : String(city);
  }
  // getStateByCityId(cityId: number) {
  //   this.homeService.getStateByCityId(cityId).subscribe(
  //     (response: any) => {
  //       if (response.status === 'SUCCESS') {
  //         this.stateId = response.payload.stateId;
  //         this.stateName = response.payload.stateName;
  //         this.cd.detectChanges();
  //         this.firstFormGroup.patchValue({
  //           stateId: this.stateId,
  //         });
  //         this.cd.detectChanges();
  //       } else {
  //         console.error('Failed to fetch state data:', response);
  //       }
  //     },
  //     (error) => {
  //       console.error('Error fetching state data:', error);
  //     }
  //   );
  // }
  getStateByCityId(cityId: number) {
    this.homeService.searchstate(cityId).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.stateId = response.payload.stateId;
          this.stateName = response.payload.stateName;
          console.log(this.stateId, this.stateName);
          this.cd.detectChanges();

          this.firstFormGroup.patchValue({
            stateId: this.stateId,
          });
          this.cd.detectChanges();

          console.log('stId', this.stateId);
        } else {
          console.error('Failed to fetch state data:', response);
          this.firstFormGroup.patchValue({
            stateId: null,
          });
        }
      },
      (error) => {
        const message =
          error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }
      }
    );
  }

  // onSubmit() {
  //   const projectid = this.route.snapshot.queryParamMap.get('id');
  //   const controls = this.firstFormGroup.controls;

  //   // Mark all controls as dirty to show validation errors
  //   for (const i in controls) {
  //     if (controls.hasOwnProperty(i)) {
  //       controls[i].markAsDirty();
  //       controls[i].updateValueAndValidity();
  //     }
  //   }

  //   if (this.firstFormGroup.valid) {
  //     let payload = { ...this.firstFormGroup.value };
  //     payload.toPrice = this.calculatePrice(payload.toPrice, payload.toPriceUnit);

  //     // Remove unnecessary fields
  //     const fieldsToRemove = ['uploadPdf', 'uploadbanner'];
  //     fieldsToRemove.forEach(field => {
  //       delete payload[field];
  //     });

  //     // Add additional data
  //     payload = {
  //       ...payload,
  //       bannerImage: this.uploadedbanner,
  //       imageUrls: this.uploadedImages
  //     };

  //     if (projectid) {
  //       this.UpdateBasicDetail(payload, projectid);
  //     } else {
  //       this.saveProject(payload);
  //     }

  //     this.stepper.next();
  //   } else {
  //     console.log('Form is invalid');
  //   }
  // }

  calculatePrice(price: string, unit: string): number {
    const numericPrice = parseFloat(price);
    if (isNaN(numericPrice)) {
      return 0;
    }
    if (unit === 'Lac') {
      return numericPrice * 100000;
    } else if (unit === 'Cr') {
      return numericPrice * 10000000;
    }
    return numericPrice;
  }

  onSubmit() {
    const projectid = this.route.snapshot.queryParamMap.get('id');
    console.log('projectid', projectid);
    const controls = this.firstFormGroup.controls;
    for (const i in controls) {
      if (controls.hasOwnProperty(i)) {
        controls[i].markAsDirty();
        controls[i].updateValueAndValidity();
      }
    }
    if (this.firstFormGroup.valid) {
      let payload = { ...this.firstFormGroup.value };
      payload.toPrice = this.calculatePrice(
        payload.toPrice,
        payload.toPriceUnit
      );
      payload.fromPrice = this.calculatePrice(
        payload.fromPrice,
        payload.fromPriceUnit
      );
      payload.cityName = this.projectCityId;
      const fieldsToRemove = ['uploadPdf', 'uploadbanner'];
      fieldsToRemove.forEach((field) => {
        delete payload[field];
      });
      payload = {
        ...payload,
        // toPrice: payload.toPrice,
        // fromPrice: payload.fromPrice,
        bannerImage: this.uploadedbanner,
        imageUrls: this.uploadedImages,
        cityName: this.projectCityId,
      };
      if (projectid) {
        this.UpdateBasicDetail(payload, projectid);
        this.stepper.next();
      } else {
        // this.saveProject(payload);
        // this.stepper.next()

        this.saveProject(payload).subscribe({
          next: (response: any) => {
            if (response.status === 'SUCCESS') {
              this.id = response.payload.id;
              this.paramsData = response.payload;
              this.toastr.success('Project Added Successfully', 'Success');
              this.stepper.next();
              // if (this.id) {
              //   this.router.navigate(['/add-project'], {
              //     queryParams: { id: this.id },
              //   });
              // } else {
              //   console.error('No id returned in response');
              // }
            }
          },
          error: (error) => {
            const message = error?.error?.message || 'An error occurred';
            let errorMessage =
              this.errorMessageService.getErrorMessage(message);
            this.toastr.error(errorMessage, 'Error');
          },
        });
      }
    } else {
      console.log('Form is invalid');
    }
  }

  saveProject(payload: any): Observable<any> {
    return this.homeService.saveProject(payload);
  }
  // saveProject(payload: any) {
  //   this.homeService.saveProject(payload).subscribe(
  //     (response: any) => {
  //       if (response.status === 'SUCCESS') {
  //         this.id = response.payload.id;
  //         this.paramsData = response.payload;
  //         this.toastr.success('Project Added Successfully', 'Success');
  //         if (this.id) {
  //           this.router.navigate(['/add-project'], {
  //             queryParams: { id: this.id },
  //           });
  //         } else {
  //           console.error('No id returned in response');
  //         }
  //       }
  //       else if (response && response.status === 'EXCEPTION') {
  //         if (response['message']) {
  //           let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
  //           this.toastr.error(errorMessage, 'Error');
  //         }
  //       }
  //     },
  //     (error) => {
  //       const message = error?.error?.message || 'You are not authorized to access this page';
  //       if (message) {
  //         let errorMessage = this.errorMessageService.getErrorMessage(message);
  //         this.toastr.error(errorMessage, 'Error');
  //       }

  //     }
  //   );
  // }
  UpdateBasicDetail(payload: any, id: any) {
    if (this.firstFormGroup.valid) {
      let payload = { ...this.firstFormGroup.value };
      payload = {
        ...payload,
        bannerImage: this.uploadedbanner,
        imageUrls: this.uploadedImages,
        cityName: this.projectCityId,
      };
    }
    this.homeService.UpdateProject(payload, id).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.id = response.payload.id;
          this.paramsData = response.payload;
          // this.toastr.success('Project Updated Successfully', 'Success');
          const urlId = this.route.snapshot.queryParams['id'];
          if (urlId) {
            this.toastr.success('Project Updated Successfully', 'Success');
          } else {
            this.toastr.success('Project Added Successfully', 'Success');
          }
          // if (this.id) {
          //   this.router.navigate(['/add-project'], {
          //     queryParams: { id: this.id },
          //   });
          // } else {
          //   console.error('No id returned in response');
          // }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        const message =
          error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }
      }
    );
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-digit characters
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10); // Limit to 10 digits
    }
  }

  updateprojectfeatures() {
    // const projectid =
    if (!this.secondFormGroup.valid) {
      console.error('Form is invalid');
      return;
    }
    const firstFormGroupValues = {
      ...this.firstFormGroup.value,
    };
    delete firstFormGroupValues.uploadPdf;
    delete firstFormGroupValues.uploadbanner;
    const projectFeatures = {
      projectFeatures: this.secondFormGroup.value,
    };
    firstFormGroupValues.toPrice = this.calculatePrice(
      firstFormGroupValues.toPrice,
      firstFormGroupValues.toPriceUnit
    );
    firstFormGroupValues.fromPrice = this.calculatePrice(
      firstFormGroupValues.fromPrice,
      firstFormGroupValues.fromPriceUnit
    );
    // firstFormGroupValues.trendingProject = this.data.trendingProject;
    // firstFormGroupValues.topProject = this.data.topProject;
    // firstFormGroupValues.cityName =this.selectedCityId.cityName
    if (this.projectCityId) {
      firstFormGroupValues.cityName = this.projectCityId.cityName;
    }

    const payload = {
      ...firstFormGroupValues,
      ...projectFeatures,
      bannerImage: this.uploadedbanner,
      imageUrls: this.uploadedImages,
    };
    this.homeService.UpdateProject(payload,this.id).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.router.navigate(['/myprojects']);
          // Swal.fire({
          //   // position: "top-end",
          //   icon: "success",
          //   title: "Project Added Successfully",
          //   showConfirmButton: false,
          //   timer: 1500
          // });
          // this.toastr.success('Project Updated Successfully', 'Success');
          const urlId = this.route.snapshot.queryParams['id'];
          if (urlId) {
            this.toastr.success('Project Updated Successfully', 'Success');
          } else {
            this.toastr.success('Project Added Successfully', 'Success');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        const message =
          error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }
      }
    );
  }
  citySelected = false;
  selectedCity: any = null;
  // onCityBlur() {
  //   if (!this.citySelected) {
  //     this.selectedCity = null;
  //     this.firstFormGroup.controls['cityName'].setValue('');
  //     const searchtext1 =''
  //     this.getCitybyCountryId(searchtext1)
  //   }
  //   this.citySelected = false;
  // }

  projectById() {
    this.homeService.getProjectById(this.projectId).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.data = response?.payload;
          let cityId = response?.payload?.cityId;
          this.getStateByCityId(cityId);
          this.stateId = response?.payload?.stateId;
          this.projectFeature = response?.payload?.projectfeatures;
          this.statusValue = response?.payload?.status;
          let fromPricevalue = response?.payload?.fromPrice;
          let toPricevalue = response?.payload?.toPrice;

          if (this.data) {
            if (this.data.fromPriceUnit === 'Lac') {
              fromPricevalue /= 100000;
            } else if (this.data.fromPriceUnit === 'Cr') {
              fromPricevalue /= 10000000;
            }
            if (this.data.toPriceUnit === 'Lac') {
              toPricevalue /= 100000;
            } else if (this.data.toPriceUnit === 'Cr') {
              toPricevalue /= 10000000;
            }
          } else {
            console.warn('Feature data is not available');
          }

          this.uploadedImages = response?.payload?.projectPlanImage.map(
            (media) => media.url
          );
          this.uploadedbanner = response?.payload?.bannerImage.map(
            (media) => media.url
          );
          this.firstFormGroup.patchValue({
            // reraApproved: this.data.reraApproved,
            title: this.data.title,
            description: this.data.description,
            status: this.statusValue,
            cityName: this.data.cityName,
            // cityId: this.data.cityId,
            stateId: this.stateId,
            reraApproved: this.data.reraApproved,
            inventoryType: this.data.inventoryType,
            loanAvailable: this.data.loanAvailable,
            projectName: this.data.projectName,
            toPrice: toPricevalue,
            fromPrice: fromPricevalue,
            toPriceUnit: this.data.toPriceUnit,
            fromPriceUnit: this.data.fromPriceUnit,
            size: this.data.size,
            sizeUnit: this.data.sizeUnit,

            location: this.data.location,
          });
          // this.firstFormGroup.setValue({
          //   reraApproved: this.data.reraApproved,
          // });
          this.cd.detectChanges();
          this.secondFormGroup.patchValue({
            builder: this.projectFeature.builder || '',
            connectivity: this.projectFeature.connectivity,
            constructionApprovals: this.projectFeature.constructionApprovals,
            flatPurchaseChecklist: this.projectFeature.flatPurchaseChecklist,
            investmentPerspective: this.projectFeature.investmentPerspective,
            security: this.projectFeature.security,
            accessTo: this.projectFeature.accessTo,
            zoningRisks: this.projectFeature.zoningRisks,
            verification: this.projectFeature.verification,
            modernFeatures: this.projectFeature.modernFeatures,
            noObjectionCertificates:
              this.projectFeature.noObjectionCertificates,
            marketResearch: this.projectFeature.marketResearch,
            size: this.projectFeature.size,
            sizeUnit: this.projectFeature.sizeUnit,
          });
          this.cd.detectChanges();
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        const message =
          error?.error?.message || 'You are not authorized to access this page';
        if (message) {
          let errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }
      }
    );
  }
  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }
  triggerFileInput1(): void {
    const fileInput = document.getElementById('fileInput1') as HTMLInputElement;
    fileInput.click();
  }
  uploadFiles(): void {
    if (this.selectedFile.length) {
      for (let i = 0; i < this.selectedFile.length; i++) {
        const selectedFile = this.selectedFile[i];
        const type = this.imageItem[i];
        const mimeType = selectedFile.type;
        const purpose = type.purpose;

        this.homeService
          .getMediaData(purpose, mimeType, selectedFile)
          .pipe(
            finalize(() => {
              this.imageItem[i].loading = false;
            })
          )
          .subscribe((res: any) => {
            this.uploadedbanner.push(res.url);
            this.cd.detectChanges();
          });
      }

      this.clearFileInput1();
    }
  }

  handleChanges(event: any): void {
    const files = event.target.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFile.push(files[i]);
        this.selectedFileName.push(files[i].name);
        this.imageItem.push({ loading: true, purpose: 'bannerImage' });
      }
      this.cd.detectChanges();
    }
  }

  handleChange(event: any): void {
    const file = event.target.files;
    if (file && file.length) {
      for (let i = 0; i < file.length; i++) {
        this.selectedFiles.push(file[i]);
        this.selectedFileNames.push(file[i].name);
        this.imageItems.push({ loading: true, purpose: 'propertiesImage' });
      }
      this.cd.detectChanges();
    }
  }

  uploadFile(): void {
    if (this.selectedFiles.length) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const selectedFile = this.selectedFiles[i];
        const type = this.imageItems[i];
        const mimeType = selectedFile.type;
        const purpose = type.purpose;
        this.homeService
          .getMediaData(purpose, mimeType, selectedFile)
          .pipe(
            finalize(() => {
              this.imageItems[i].loading = false;
            })
          )
          .subscribe((res: any) => {
            this.uploadedImages.push(res.url);
            this.cd.detectChanges();
          });
      }

      // Clear the file input and reset selected files and names
      this.clearFileInput();
    }
  }

  clearFileInput(): void {
    this.selectedFiles = [];
    this.selectedFileNames = [];
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
    this.cd.detectChanges();
  }
  clearFileInput1() {
    this.selectedFile = [];
    this.selectedFileName = [];
    const fileInput = document.getElementById('fileInput1') as HTMLInputElement;
    fileInput.value = '';
    this.cd.detectChanges();
  }
  removeImage(index: number): void {
    this.uploadedImages.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.selectedFileNames.splice(index, 1);
    this.imageItems.splice(index, 1);
    this.cd.detectChanges();
  }
  removeBanner(index: number): void {
    this.uploadedbanner.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.selectedFileNames.splice(index, 1);
    this.imageItems.splice(index, 1);
    this.cd.detectChanges();
  }
}
