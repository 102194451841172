import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { HomeService } from '../_services/home.service';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import { EmbedVideoService } from 'ngx-embed-video';
import { filter } from 'rxjs/operators';
import { ProfileService } from '../_services/profile.service';
import { AuthService } from '../_services/auth.service';
import { environment } from 'src/environments/environment';
import { HeaderSearchService } from '../_services/header-search.service';
import { LoadingService } from '../_services/loading.service';
import { AddPropertyComponent } from '../add-property/add-property.component';
import { Subscription ,Observable} from 'rxjs';
import { ErrorMessageService } from '../services/error-message.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home-property',
  templateUrl: './home-property.component.html',
  styleUrls: ['./home-property.component.scss'],
})
export class HomePropertyComponent implements OnInit {
  @ViewChild('searchkey') searchInput: ElementRef;
  private subscription: Subscription = new Subscription();
  loading$: Observable<boolean>;
  public loading = true;
  listings: any[] = [];
  listingsData: any[] = [];
  page: number = 1;
  limit: number = 10;
  sortactivetab = 'trending';
  mediums: any = [];
  userinfo: any = [];
  userPortfolio: any = [];
  customizeBackgroundContents: any = [];
  categories: any = [];
  searchkey: any = [];
  checkedcat: any = [];
  checkedmedium: any = [];
  selectQueryType: any;
  count = 0;
  tableSize = 28;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingsmedium: IDropdownSettings = {};
  selectedCategories = [] as any;
  selectedCategoryIds = [] as any;
  selectedMediums = [] as any;
  selectedMediumIds = [] as any;
  artParama: any = {
    artWorkCategories: [],
    limit: 28,
    mediumIds: [],
    offset: 0,
    searchString: '',
    sortCriteria: 'trending',
  };
  btnStyle = 'tablinks ';
  isLoggedIn = false;
  defaultThumbnail = '../assets/images/video.png';
  defaultThumbnailAudio = '../assets/images/audio.jpeg';
  maxCategoryMessage: boolean = false;
  searchResultMessage: boolean = false;
  maxMediumMessage: boolean = false;
  usertoken: any = [];
  searchValue: any = '';
  socialOrigin: any;
  useremail: any;
  payload: any;
  searchData: string = '';
  dropdownOpen = false;
  currentIndex: number = 0;
  queryDataValue:any;
  queryTypeValue:any;
  appliedFilter:any
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: '',
    prevArrow: '',
    dots: false,
    infinite: true,
  };
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  selectOption(option: string) {
    console.log(`Selected option: ${option}`);
    this.dropdownOpen = false;
  }
  currentPage: number = 0; // Current page number (zero-based)
  pageSize: number = 10;   // Number of records per page
  totalPages: number = 0;  // Total number of pages
  totalElements: number = 0; // Total number of items

  showTopIcon = false; // Property to control icon visibility
  constructor(
    private homeService: HomeService,
    private router: Router,
    private searchService: HeaderSearchService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private errorMessageService: ErrorMessageService,
    private toastr:ToastrService

  ) { }


  ngOnInit(): void {
    this.loading$ = this.loadingService.loading$;
    console.log("HomePropertyComponent initialized");

    let allcityId = localStorage.getItem('allcityId');
    let allstateId = localStorage.getItem('allstateId');
    let selectedCityName = localStorage.getItem('selectedCityName');
    let selectedCityId = localStorage.getItem('selectedCityId');
    if (!allcityId || !allstateId || !selectedCityName || !selectedCityId) {
      if (!allcityId) {
        localStorage.setItem('allcityId', '132032');
      }
      if (!allstateId) {
        localStorage.setItem('allstateId', '4007');
      }
      if (!selectedCityName) {
        localStorage.setItem('selectedCityName', 'Gurgaon');
      }
      if (!selectedCityId) {
        localStorage.setItem('selectedCityId', '132032');
      }

      // Page reload logic
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    }








    const queryData = localStorage.getItem("search_query");
    const queryType = localStorage.getItem('search_type');
    this.queryDataValue = queryData;
    this.queryTypeValue = queryType;
    this.selectQueryType = queryType;


    this.appliedFilter = JSON.parse(localStorage.getItem('Filter') || '{}');
    this.onPayloadReceived(this.appliedFilter);

    this.searchService.searchData$.subscribe(data => {
      console.log('Data received from searchService:', data);
      this.searchData = data;
      localStorage.setItem("search_query", data);
      if (data || queryData) {
        this.onPayloadReceived(this.appliedFilter);
        console.log('API triggered after search update');
      }
    });
  }




  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  // onPayloadReceived(payload: any) {
  //   this.loadingService.show(); // Show loading indicator
  //   let formattedPayload: any[] = [];

  //   if (payload) {
  //     this.payload = payload;
  //     console.log(payload);
  //     this.loadingService.show(); // Again show to ensure loading starts

  //     formattedPayload = this.formatFilters(this.payload?.filterConditions || []);
  //     console.log(formattedPayload);

  //     const askingFilterIndex = formattedPayload.findIndex(
  //       (item) => item.filterName === 'asking'
  //     );

  //     if (askingFilterIndex !== -1) {
  //       const askingFilter = formattedPayload[askingFilterIndex];
  //       formattedPayload.splice(askingFilterIndex, 1);
  //       const [minValue, maxValue] = askingFilter.filterValue
  //         .split(' - ')
  //         .map((value) => value.trim());

  //       formattedPayload.push(
  //         { filterName: 'fromAsking', filterValue: minValue },
  //         { filterName: 'toAsking', filterValue: maxValue }
  //       );
  //     }
  //   }

  //   console.log('formattedPayload1==>', formattedPayload);

  //   console.log('queryDataValue:', this.queryDataValue, typeof this.queryDataValue);
  //   if (this.queryDataValue) {
  //     const typeIndex = formattedPayload.findIndex((item) => item.filterName === 'type');

  //     if (typeIndex !== -1) {
  //       formattedPayload[typeIndex].filterValue = [
  //         ...new Set([
  //           ...formattedPayload[typeIndex].filterValue.split(',').map((v) => v.trim()),
  //           ...this.queryTypeValue.split(',').map((v) => v.trim())
  //         ])
  //       ].join(', ');
  //     } else {
  //       formattedPayload.push({
  //         filterName: 'type',
  //         filterValue: this.queryTypeValue,
  //       });
  //     }
  //   }

  //   console.log('formattedPayload2==>', formattedPayload);

  //   let selectedCityId = localStorage.getItem("selectedCityId");
  //   if (selectedCityId) {
  //     formattedPayload.push({
  //       filterName: 'cityId',
  //       filterValue: selectedCityId,
  //     });
  //   }

  //   let filters: any = {
  //     filterConditions: formattedPayload,
  //     sortConditions: [],
  //     searchValue: this.searchData ? this.searchData : this.queryDataValue,
  //     page: this.searchData && this.searchData.trim() !== '' ? 0 : this.currentPage,
  //     size: this.pageSize,
  //   };

  //   console.log(filters);
  //   this.homeService.property(filters).subscribe(
  //     (response: any) => {
  //       this.loadingService.hide(); // Hide loading indicator
  //       if (response?.payload?.items) {
  //         this.listings = response.payload.items;
  //         console.log('this.listings', this.listings);
  //         this.totalPages = response.payload.totalPages;
  //         this.totalElements = response.payload.totalElements;
  //         console.log('Listings:', this.listings);
  //         this.cdr.detectChanges();
  //       } else if (response['message']) {
  //         let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
  //         this.toastr.error(errorMessage, 'Error');
  //       }
  //     },

  //     (error) => {
  //       const message = error?.error?.message || 'Failed to fetch city data';
  //       const errorMessage = this.errorMessageService.getErrorMessage(message);
  //       this.toastr.error(errorMessage, 'Error');
  //     }
  //   );
  // }


  onPayloadReceived(payload: any) {
    this.loadingService.show(); // Show loading indicator
    let formattedPayload: any[] = [];

    if (payload) {
        this.payload = payload;
        console.log(payload);
        this.loadingService.show(); // Ensure loading starts

        formattedPayload = this.formatFilters(this.payload?.filterConditions || []);
        console.log(formattedPayload);

        const askingFilterIndex = formattedPayload.findIndex(
            (item) => item.filterName === 'asking'
        );

        if (askingFilterIndex !== -1) {
            const askingFilter = formattedPayload[askingFilterIndex];
            formattedPayload.splice(askingFilterIndex, 1);
            const [minValue, maxValue] = askingFilter.filterValue
                .split(' - ')
                .map((value) => value.trim());

            formattedPayload.push(
                { filterName: 'fromAsking', filterValue: minValue },
                { filterName: 'toAsking', filterValue: maxValue }
            );
        }
    }

    console.log('formattedPayload1==>', formattedPayload);

    console.log('queryDataValue:', this.queryDataValue, typeof this.queryDataValue);
    if (this.queryDataValue) {
        const typeIndex = formattedPayload.findIndex((item) => item.filterName === 'type');

        if (typeIndex !== -1) {
            formattedPayload[typeIndex].filterValue = [
                ...new Set([
                    ...formattedPayload[typeIndex].filterValue.split(',').map((v) => v.trim()),
                    ...this.queryTypeValue.split(',').map((v) => v.trim())
                ])
            ].join(', ');
        } else {
            formattedPayload.push({
                filterName: 'type',
                filterValue: this.queryTypeValue,
            });
        }
    }

    // Check if 'search_type' is stored in localStorage
    let searchType = localStorage.getItem("search_type");
    if (searchType) {
        formattedPayload.push({
            filterName: 'type',
            filterValue: searchType,
        });
    }

    console.log('formattedPayload2==>', formattedPayload);

    let selectedCityId = localStorage.getItem("selectedCityId");
    if (selectedCityId) {
        formattedPayload.push({
            filterName: 'cityId',
            filterValue: selectedCityId,
        });
    }

    let filters: any = {
        filterConditions: formattedPayload,
        sortConditions: [],
        searchValue: this.searchData ? this.searchData : this.queryDataValue,
        page: this.searchData && this.searchData.trim() !== '' ? 0 : this.currentPage,
        size: this.pageSize,
    };

    console.log(filters);
    this.homeService.property(filters).subscribe(
        (response: any) => {
            this.loadingService.hide(); // Hide loading indicator
            if (response?.payload?.items) {
                this.listings = response.payload.items;
                console.log('this.listings', this.listings);
                this.totalPages = response.payload.totalPages;
                this.totalElements = response.payload.totalElements;
                console.log('Listings:', this.listings);
                this.cdr.detectChanges();
            } else if (response['message']) {
                let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
                this.toastr.error(errorMessage, 'Error');
            }
        },
        (error) => {
            const message = error?.error?.message || 'Failed to fetch city data';
            const errorMessage = this.errorMessageService.getErrorMessage(message);
            this.toastr.error(errorMessage, 'Error');
        }
    );
}



  formatFilters(filterConditions: any[]) {
    if (!Array.isArray(filterConditions)) {
      console.error('filterConditions is not an array or is undefined:', filterConditions);
      return [];
    }

    const resultMap = new Map<string, Set<string>>();

    filterConditions.forEach((condition) => {
      const name = condition.filterName;
      const values = condition.filterValue.split(',').map(value => value.trim()); // Ensure multiple values are handled correctly

      if (!resultMap.has(name)) {
        resultMap.set(name, new Set(values));
      } else {
        values.forEach(value => resultMap.get(name)?.add(value));
      }
    });

    // Ensure "type" remains as a single entry and combines all values
    if (resultMap.has('type')) {
      resultMap.set('type', new Set([Array.from(resultMap.get('type')!).join(', ')]));
    }

    // Convert Map to array
    const resultArray = Array.from(resultMap, ([key, valueSet]) => ({
      filterName: key,
      filterValue: Array.from(valueSet).join(', '), // Convert Set back to a comma-separated string
    }));

    console.log('Formatted Filters:', resultArray);
    return resultArray;
  }



  changePage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.onPayloadReceived(this.payload)
    }
  }
  getPages(): number[] {
    const pages: number[] = [];
    const start = Math.max(this.currentPage - 4, 0); // Show up to 4 pages before
    const end = Math.min(start + 9, this.totalPages - 1); // Show up to 9 pages total
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTopIcon = window.scrollY > 100; // Show icon if scrolled down more than 200px
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  refresh() {
    window.history.back();
  }
}
