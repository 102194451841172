<!-- <div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>
    <div class="col-12 justify-content-center mt-3">
      <app-project-common-filter (searchTermChange)="onSearchTermChange($event)"></app-project-common-filter>
    </div>
    <div style="cursor: pointer;" *ngIf="topList.length > 0;" class="card-container master12 col-md-12">
      <ng-container *ngIf="loading$ | async; else showListings">
        <app-loading></app-loading>
      </ng-container>
      <ng-template #showListings>
        <ng-container *ngFor="let card of topList">
          <app-top-property-card
        [cardData]="card"
        [showEditDetailsButton]="false"
        [showViewDetailsButton]="false"
        [showInventoryButton]="false"
        [showdetails]="false"
        [showEditInventoryButton]="false"
        [showdsize]="false"
        >
      </app-top-property-card>
        </ng-container>
      </ng-template>

    </div>
    <div *ngIf="!(loading$ | async) && topList.length === 0">
      <app-not-found></app-not-found>
    </div>
  </div>
  <app-footer></app-footer>
</div> -->


<div class="wrapper">
  <app-header></app-header>
  <app-menu></app-menu>
  <div class="col-md-12 content">
    <div class="row justify-content-center align-items-center mt-4">
      <div class="col-12 col-md-10 ">
        <div class="textstyle">Top Project</div>
        <div class="col-12 justify-content-center mt-3">
          <app-project-common-filter (searchTermChange)="onSearchTermChange($event)"></app-project-common-filter>
        </div>


      </div>
      <div class="col-12 col-md-10 ">
        <ng-container *ngIf="topList?.length > 0;">
          <div class="listings-container">
            <ng-container *ngIf="loading$ | async; else showListings">
              <app-loading></app-loading>
            </ng-container>
            <ng-template #showListings>
              <ng-container *ngFor="let card of topList">
                <app-property-card
                [card]="card"
                [showEditDetailsButton]="false"
                [showViewDetailsButton]="false"
                [showInventoryButton]="false"
                [showdetails]="false"
                [showEditInventoryButton]="false"
                [showdsize]="false"
                ></app-property-card>
              </ng-container>
            </ng-template>

          </div>

        </ng-container>
        <div *ngIf="!(loading$ | async) && topList.length === 0">
          <app-not-found></app-not-found>
        </div>


      </div>
    </div>



  </div>
  <br />


  <app-footer></app-footer>
</div>
