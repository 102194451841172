import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { filter } from 'rxjs/operators';
import { HomeService } from '../_services/home.service';
import { HeaderSearchService } from '../_services/header-search.service';
import { ChangeDetectorRef } from '@angular/core';



import { ErrorMessageService } from '../services/error-message.service';
import { ToastrService } from 'ngx-toastr';






@Component({
	selector: 'app-header-property',
	templateUrl: './header-property.component.html',
	styleUrls: ['./header-property.component.scss']
})
export class HeaderPropertyComponent implements OnInit {
	searchPlaceholder: string = 'Search Property.';
	searchQuery: string = '';

	@ViewChild('searchkey') searchInput: ElementRef;
	constructor(private route: ActivatedRoute,
		private authService: AuthService,
		private tokenStorage: TokenStorageService,
		private homeService: HomeService,
		private router: Router,
    private errorMessageService: ErrorMessageService,
    private toastr:ToastrService,
		private searchService: HeaderSearchService,
		private cdr: ChangeDetectorRef
	) {
	}
	searchTerm = 'artwork';
	dropdownOpen = false;
	usertoken: any = [];
	token = '';
	userinfo: any = [];
	socialOrigin = '';
	itsMe = false;
	userid = '';
	unReadCount = 0;
	collapsed: boolean = false
	builder: boolean | string = '';
	// collapsed3:boolean = false
	collapsed1: boolean = false;
	isKhubiiAdmin = false;
	isMenushow: boolean = false
	userfullname: string = '';
	useremail = '';
	@ViewChild('navbarToggler') navbarToggler: ElementRef;
	navBarTogglerIsVisible() {
		return this.navbarToggler?.nativeElement.getAttribute('aria-expanded')
	}
	@ViewChild('navbarToggler1') navbarToggler1: ElementRef;
	navBarTogglerIsVisible1() {
		return this.navbarToggler1?.nativeElement.getAttribute('aria-expanded')
	}
	navigateToUserProprty() {

		this.router.navigate(['/UserProperty'])
	}
	navigateToUserProjects() {

		this.router.navigate(['/myprojects'])
	}

	toggleDropdown() {
		this.dropdownOpen = !this.dropdownOpen;
	}

	selectOption(option: string) {
		console.log(`Selected option: ${option}`);
		this.dropdownOpen = false;
	}

	populateForm() {
		const profileData = localStorage.getItem('auth-user');
		const parsedProfileData1 = JSON.parse(profileData);
		const parsedProfileData = JSON.parse(parsedProfileData1);
		this.userfullname = parsedProfileData.fullName;
		this.builder = parsedProfileData.builder;
		this.useremail = parsedProfileData.email;
		let useremail = this.useremail
		console.log("useremail", useremail);
		console.log("userfullname", this.userfullname);
		let userfullname = this.userfullname
	}
	ngOnInit(): void {
    this.searchCity()
	let userData = localStorage.getItem("UserProfile");
		console.log('userData',userData);
	if (userData) {
        let parsedUserData = JSON.parse(userData);
        this.builder = parsedUserData?.payload?.builder;
        this.userfullname = parsedUserData?.payload?.fullName;
        this.useremail = parsedUserData?.payload?.email;
    }
    this.cdr.detectChanges();  // Force UI update
    const storedCityName = localStorage.getItem('selectedCityName');
    if (storedCityName) {
      this.searchCityQuery = storedCityName;
    }

		const searchquerydata = localStorage.getItem("search_query");
		if (searchquerydata) {
			this.searchPlaceholder = searchquerydata;
			this.searchQuery = searchquerydata;
		}


		try { this.userinfo = JSON.parse(this.tokenStorage.getUser()); } catch (e) { }
		if (this.userinfo.id) {
			this.itsMe = true;
		} else {
			this.itsMe = false;
		}

		this.router.events.pipe(
			filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
			//this.router.events.pipe(filter(evt => evt instanceof NavigationEnd))
			//.subscribe((evt: NavigationEnd) => {
			// this.collapseNav()
			// this.populateForm();

		});
		try {
			this.usertoken = JSON.parse(this.tokenStorage.getToken());
			this.socialOrigin = this.tokenStorage.getSocialOrigin();
			this.useremail = this.tokenStorage.getSocialUserEmail();
			if (this.userinfo.email == 'admin@khubii.com') {
				this.isKhubiiAdmin = true;
			}
			if (this.usertoken && this.usertoken.access_token) {
				this.token = this.usertoken.access_token;
				this.authService.getUser(this.token, this.socialOrigin, this.useremail).subscribe(
					response => {
						if (response['status'] == 'SUCCESS') {
							this.userinfo = response['payload'];
							console.log("in header:-" + this.userinfo)
							this.tokenStorage.saveUser(JSON.stringify(this.userinfo));
							this.populateForm();
						}
						else if(response['status'] == 'EXCEPTION'){
							this.tokenStorage.signOut();
							this.router.navigate(['/login']).then(() => {
								window.location.reload();
							});
						}
					});
			}
		} catch (err) { }

	}

	searchArt() {
		const searchData = this.searchInput.nativeElement.value;
		console.log(searchData);
		this.searchService.setSearchData(searchData);
	}

  hideCitySuggestions() {
    setTimeout(() => {
      this.showCitySuggestions = false;
    }, 200); // Small delay to allow click event to register
  }
  onCityInputClick() {
    this.clearSearch()
    if (this.cityDataList.length > 0) {
      this.showCitySuggestions = true;
    }
  }
	userLogout() {
		this.tokenStorage.signOut();
		this.router.navigate(['/login']).then(() => {
			window.location.reload();
		});
	}
	newsnavigate() {
		this.router.navigate(['/news']).then(() => {
			window.location.reload();
		});
	}

	onInputChange() {
		if (this.searchQuery === '') {
			localStorage.removeItem("search_query");
			localStorage.removeItem("search_type");
			this.searchPlaceholder = 'Search Property';
			// this.cdr.detectChanges();
			// let currentUrl = this.router.url;
			// this.router.routeReuseStrategy.shouldReuseRoute = () => false;
			// this.router.onSameUrlNavigation = 'reload';
			// this.router.navigate([currentUrl]);
		  }

	}

  showDropdown(inputElement: HTMLInputElement) {
    this.onCityInputClick()
    this.showCitySuggestions = true;
    inputElement.focus();  // Ensure cursor stays in the input field
  }

  hideDropdown() {
    this.showCitySuggestions = false;
  }








  selectedCityId: string | null = null;
  searchCityQuery: string = 'Gurgaon';
  cityDataList: any[] = [];
  allstateId:any
  showCitySuggestions:boolean=false

  selectedCityName: string | null = localStorage.getItem('selectedCityName');
  searchCity(searchTerm: string = '') {
    if (this.selectedCityId) {
      this.selectedCityId = null;
      this.searchCityQuery = searchTerm;
    }

    this.homeService.searchCity(searchTerm).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.cityDataList = response.payload;
        } else {
          this.cityDataList = [];
          const errorMessage = this.errorMessageService.getErrorMessage('Failed to fetch city data');
          this.toastr.error(errorMessage, 'Error');
        }
      },
      (error) => {
        this.cityDataList = [];
        const message = error?.error?.message || 'Failed to fetch city data';
        const errorMessage = this.errorMessageService.getErrorMessage(message);
        this.toastr.error(errorMessage, 'Error');
      }
    );
  }


  selectCity(city: any) {
    this.searchQuery = '';
    this.selectedCityId = city.id;
    this.allstateId = city.stateId;

    console.log('this.selectedCityId', this.selectedCityId);

    // Store city details in localStorage
    localStorage.setItem('allcityId', this.selectedCityId);
    localStorage.setItem('allstateId', this.allstateId);
    localStorage.setItem('selectedCityName', city.name);
    localStorage.setItem('selectedCityId',this.selectedCityId);
    // window.location.reload();
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);

    this.searchCityQuery = city.name; // Set input value
    this.showCitySuggestions = false;
    // this.AreayByCityId(city.id); // Fetch areas based on selected city

    // Refresh the page while keeping selected city

  }


  clearSearch() {
    this.searchCityQuery = '';
    this.showCitySuggestions = false;
    this.searchCity()

  }

	clearSearchquery() {
		this.searchQuery = '';
			localStorage.removeItem("search_query");
			// localStorage.removeItem("search_type");
			this.searchPlaceholder = 'Search Property';
			let currentUrl = this.router.url;
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
			this.router.onSameUrlNavigation = 'reload';
			this.router.navigate([currentUrl]);
	}




}
