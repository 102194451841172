import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import {Observable} from 'rxjs';
import { ErrorMessageService } from '../services/error-message.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-project-inventory',
  templateUrl: './project-inventory.component.html',
  styleUrls: ['./project-inventory.component.scss']
})
export class ProjectInventoryComponent implements OnInit {
  projectId: string | null = null;
  showTopIcon = false;
  searchValue: string = '';
  IsLoading: boolean = false;
  loading$: Observable<boolean>;
  topList: any[] = [];  // Full dataset
  searchResults: any[] = []; // Stores filtered search results
  filteredListings: any[] = []; // Sliced dataset for display

  showButton: boolean;
  currentPage: number = 0;
  pageSize: number = 10;
  totalPages: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private loadingService:LoadingService,
    private errorMessageService: ErrorMessageService,
    private toastr:ToastrService

  ) {}

  ngOnInit(): void {
    this.loading$ = this.loadingService.loading$;
    this.route.queryParamMap.subscribe(params => {
      this.projectId = params.get('projectId');
      if (this.projectId) {
        localStorage.setItem('projectId', this.projectId);
      }
      this.projectId = localStorage.getItem("projectId");

      this.route.queryParams.subscribe(params => {
        this.showButton = params['showButton'] === 'false' ? false : true;
      });

      this.apiService(); // Load data
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTopIcon = window.scrollY > 100;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  navigateToAddInventory() {
      this.router.navigate(['/add-invetory'], { queryParams: { projectId: this.projectId } });
  }

  apiService() {
    this.IsLoading = true;  // Set loading to true
    this.loadingService.show();

    this.homeService.getInventoryByProjectId(this.projectId).subscribe(
      (res: any) => {
        this.IsLoading = false;  // Set loading to false
        this.loadingService.hide();

        if (res.status === 'SUCCESS' && res.payload) {
          this.topList = res.payload;
          this.searchResults = [...this.topList];
          this.totalPages = Math.ceil(this.topList.length / this.pageSize);
          this.updatePagination();
        } else {
          this.topList = [];
          this.searchResults = [];
          this.filteredListings = [];

          const errorMessage = this.errorMessageService.getErrorMessage('No inventory data found');
          this.toastr.error(errorMessage, 'Error');
        }
      },
      (error) => {
        this.IsLoading = false; // Hide loading on error
        this.loadingService.hide();
        this.topList = [];
        this.searchResults = [];
        this.filteredListings = [];

        const message = error?.error?.message || 'Failed to fetch inventory data';
        const errorMessage = this.errorMessageService.getErrorMessage(message);
        this.toastr.error(errorMessage, 'Error');
      }
    );
  }



  searchListings(): void {
    const searchTerm = this.searchValue.toLowerCase().trim();

    // Search across all records
    this.searchResults = this.topList.filter((listing) =>
      listing.title.toLowerCase().includes(searchTerm)
    );

    // Reset to first page
    this.currentPage = 0;
    this.totalPages = Math.ceil(this.searchResults.length / this.pageSize);

    // Paginate search results
    this.updatePagination();
  }

  clearSearch(): void {
    this.searchValue = '';
    this.searchResults = [...this.topList];
    this.totalPages = Math.ceil(this.topList.length / this.pageSize);
    this.currentPage = 0;
    this.updatePagination();
  }

  updatePagination(): void {
    const startIndex = this.currentPage * this.pageSize;

    // Use searchResults if searching, otherwise use full dataset
    this.filteredListings = this.searchResults.slice(startIndex, startIndex + this.pageSize);
  }

  changePage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.updatePagination();
    }
  }

  getPages(): number[] {
    const pages: number[] = [];
    const start = Math.max(this.currentPage - 2, 0);
    const end = Math.min(start + 4, this.totalPages - 1);
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  }
}
