<div class="wrapper">
  <app-header></app-header>
  <app-menu></app-menu>
  <div class="col-md-12 content">
    <div class="row justify-content-center align-items-center mt-4">
      <div class="col-12 col-md-10 ">
        <!-- <div class="col-md-9 d-flex justify-content-between align-items-center classset"> -->
          <div class="textstyle">My Property</div>
      <!-- </div> -->
        <div class="search-container ">
          <!-- City Search Dropdown (Left Side) -->
          <div class="styled-select-container ">
            <input type="text" class="form-controls search-city-input"  style="cursor: pointer;" [(ngModel)]="searchCityQuery"
              (keyup)="searchCity(searchCityQuery)" (click)="onCityInputClick()"
              (focus)="showCitySuggestions = true" (blur)="hideCitySuggestions();"
              [placeholder]="selectedCityName ? selectedCityName : 'Search City...'" #cityInput  />
              <span class="dropdown-icon" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);
              cursor: pointer; transition: color 0.3s ease;">
                               <i class="fas fa-chevron-down"
                                  *ngIf="!showCitySuggestions"
                                  (click)="showDropdown(cityInput)"  style="font-size: 16px; color: gray !important;">
                               </i>

                               <!-- Icon to HIDE suggestions -->
                               <i class="fas fa-chevron-up"
                                  *ngIf="showCitySuggestions"
                                  (click)="hideDropdown()"  style="font-size: 16px; color: gray !important;">
                               </i>
                             </span>


            <!-- <span *ngIf="searchCityQuery" class="clear-icon" (click)="clearSearchdrop()">✖</span> -->

            <ul *ngIf="showCitySuggestions && cityDataList.length > 0" class="suggestion-dropdown">
              <li *ngFor="let city of cityDataList" (mousedown)="selectCity(city)">
                {{ city.name }}
              </li>
            </ul>
          </div>

          <!-- Search Input & Button (Right Side) -->
          <div class="search-row ">
            <mat-icon class="search-icon">search</mat-icon>
            <input type="text" class="search-input" placeholder="Search your Property" [(ngModel)]="searchValue"
              (keyup)="searchListings()" />
            <button class="search-button" (click)="searchListings()">Search</button>
          </div>
        </div>


      </div>

      <div class="col-12 col-md-10 ">
        <ng-container *ngIf="filteredListings?.length > 0;">
          <div class="listings-container">
            <ng-container *ngIf="loading$ | async; else showListings">
              <app-loading></app-loading>
            </ng-container>
            <ng-template #showListings>
              <ng-container *ngFor="let listing of filteredListings">
                <app-property-card
                  [listing]="listing"
                  [editButton]="true"
                ></app-property-card>
              </ng-container>
            </ng-template>

          </div>
          <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
            ⬆
          </div>
        </ng-container>
        <div *ngIf="!(loading$ | async) && filteredListings.length === 0">
          <app-not-found></app-not-found>
        </div>


      </div>
    </div>



    <!-- <ng-template #noListings>
      <app-not-found></app-not-found>
    </ng-template> -->
    <div class="pagination-container" *ngIf="!(loading$ | async) && filteredListings.length > 0">
      <span class="page-info">Page {{ currentPage + 1 }} of {{ totalPages }}</span>

      <div class="pagination">
        <button class="page-link" (click)="changePage(currentPage - 1)" [disabled]="currentPage === 0">
          &lt; Previous Page
        </button>

        <ng-container *ngFor="let page of getPages()">
          <button class="page-number" [class.active]="currentPage === page" (click)="changePage(page)">
            {{ page + 1 }}
          </button>
        </ng-container>

        <button class="page-link" (click)="changePage(currentPage + 1)" [disabled]="currentPage + 1 >= totalPages">
          Next Page &gt;
        </button>
      </div>
    </div>
  </div>
  <br />


  <app-footer></app-footer>
</div>
