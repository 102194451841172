import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service'; // Ensure correct path
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private userProfileSubject = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject.asObservable();

  constructor(private authService: AuthService) {
    this.loadUserProfile();
    window.addEventListener('storage', this.syncProfileFromStorage.bind(this));
  }

  private loadUserProfile() {
    const profileData = localStorage.getItem('UserProfile');
    if (profileData) {
      try {
        const parsedData = JSON.parse(profileData);
        this.userProfileSubject.next(parsedData?.payload || parsedData);
      } catch (error) {
        console.error("Error parsing UserProfile from localStorage:", error);
      }
    }
  }

  updateUserProfile(profile: any) {
    if (profile) {
      localStorage.setItem('UserProfile', JSON.stringify({ payload: profile }));
      this.userProfileSubject.next(profile);
    }
  }

  getUserProfile(): Observable<any> {
    return this.authService.getProfileData().pipe(
      tap(profile => {
        this.updateUserProfile(profile);
      })
    );
  }

  private syncProfileFromStorage(event: StorageEvent) {
    if (event.key === 'UserProfile') {
      this.loadUserProfile();
    }
  }
}
