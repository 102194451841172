import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-searchfilter',
  templateUrl: './searchfilter.component.html',
  styleUrls: ['./searchfilter.component.scss']
})
export class SearchfilterComponent implements OnInit {

   @Output() searchTermChange: EventEmitter<string> = new EventEmitter<string>();
    searchTerm: string = '';


    applySearchFilter() {
      // Emit the search term to the parent component
      this.searchTermChange.emit(this.searchTerm);
    }

    ngOnInit(): void {
    }

    clearSearch() {
      this.searchTerm = '';
      this.applySearchFilter(); // Optionally apply the filter to clear results
    }
  }
