<app-header></app-header>
<app-menu></app-menu><br>
<div class="p-3" style="margin-top: 3pc;" class="borderaroud">
  <mat-stepper [linear]="isLinear" (selectionChange)="onStepChange($event)" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup" (ngSubmit)="onSubmit()">
        <ng-template matStepLabel>Basic Details</ng-template>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
          <mat-form-field >
            <mat-label>Project Title</mat-label>
            <input matInput placeholder="" formControlName="title" required>
            <mat-error *ngIf="firstFormGroup.controls['title'].hasError('required')">Project Title is required</mat-error>
          </mat-form-field>
            </div></div><br>
            <div class="row col-md-12">

              <!-- <div class="custom-form-field4">
                <label for="middleName" class="post-headings" style="color: rgb(136, 110, 110);">Description *</label>
                <textarea formControlName="description" class="description-textarea"></textarea>
                <div *ngIf="description.invalid && (description.dirty || description.touched)" class="text-danger">
                  This field is required.
                </div>
              </div> -->
              <!-- <div class="custom-form-field4">
                <label for="middleName" class="post-headings" style="color: rgb(136, 110, 110);">Description *</label>
                <textarea
                  formControlName="description"
                  class="description-textarea"
                  [ngClass]="{ 'border-error': description.invalid && (description.dirty || description.touched) }"
                ></textarea>
                <div *ngIf="description.invalid && (description.dirty || description.touched)" class="text-danger">
                  This field is required.
                </div>
              </div> -->


              <div class="row col-md-12">
                <div class="custom-form-field4">
                  <label
                    for="middleName"
                    class="post-headings"
                    [ngStyle]="{
                      color:
                        description.invalid && (description.dirty || description.touched)
                          ? '#f44336'
                          : isFocused
                          ? '#004aad'
                          : 'rgb(136, 110, 110)'
                    }"
                    style="font-family: 'Roboto', sans-serif;"
                  >
                    Description *
                  </label>
                  <textarea
                    formControlName="description"
                    class="description-textarea"
                    (focus)="isFocused = true"
                    (blur)="isFocused = false"
                    [ngClass]="{ 'border-error': description.invalid && (description.dirty || description.touched) }"
                  ></textarea>
                  <div *ngIf="description.invalid && (description.dirty || description.touched)" class="text-dangers">
                    Description is required.
                  </div>
                </div>
              </div>






            </div>
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <!-- <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Title</mat-label>
                <input matInput placeholder="" formControlName="title" required>
                <mat-error *ngIf="firstFormGroup.controls['title'].hasError('required')">This field is required</mat-error>
              </mat-form-field> -->
              <!-- <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>City</mat-label>
                <mat-select formControlName="cityId" (selectionChange)="onCityChange($event)" required>
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let city of cityDataList" [value]="city.id">
                    {{ city.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="firstFormGroup.controls['cityId'].hasError('required')">This field is required</mat-error>

              </mat-form-field>
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>State</mat-label>
                <mat-select formControlName="stateId" required>
                  <mat-option *ngIf="stateId" [value]="stateId">
                    {{ stateName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="firstFormGroup.controls['stateId'].hasError('required')">This field is required</mat-error>

              </mat-form-field> -->
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>City</mat-label>
                <input
                  type="text"
                  placeholder="Search City"
                  (keyup)="onCitySearch($event)"
                  aria-label="text"
                  matInput
                  formControlName="cityName"
                  [matAutocomplete]="auto"
                  required

                  >
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCityChange($event)" [displayWith]="displayCityName">
                    <mat-option *ngFor="let city of cityDataList" [value]="city">
                      {{ city.name }}
                    </mat-option>
                  </mat-autocomplete>



                <mat-error *ngIf="firstFormGroup.controls['cityName'].hasError('required')">
                  City is required
                </mat-error>
              </mat-form-field>
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>State</mat-label>
                <mat-select formControlName="stateId" required>
                  <mat-option *ngIf="stateId" [value]="stateId">
                    {{ stateName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="firstFormGroup.controls['stateId'].hasError('required')">State is required</mat-error>

              </mat-form-field>

            <mat-form-field class="custom-form-field3">
              <mat-label>Loan Available</mat-label>
              <mat-select disableRipple formControlName="loanAvailable" required>
                <mat-option>None</mat-option>
                <mat-option value="Bank">Bank</mat-option>
                <mat-option value="Private">Private</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['loanAvailable'].hasError('required')">Loan Available is required</mat-error>
            </mat-form-field>
            </div>
          </div>
        </div>
        <br>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Project Name</mat-label>
                <input matInput placeholder="" formControlName="projectName" required>
                <mat-error *ngIf="firstFormGroup.controls['projectName'].hasError('required')">Project Name is required</mat-error>
              </mat-form-field>


              <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
                <mat-label>Rera Approved</mat-label>
                <mat-select disableRipple formControlName="reraApproved" required>
                  <mat-option>None</mat-option>
                  <mat-option value="Approved">Approved</mat-option>
                  <mat-option value="Pending">Pending</mat-option>
                  <mat-option value="Rejected">Rejected</mat-option>
                  <mat-option value="Not Applicable">Not Applicable</mat-option>

                </mat-select>
                <mat-error *ngIf="firstFormGroup.controls['reraApproved'].hasError('required')">Rera Approved is required</mat-error>
              </mat-form-field>


              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Inventory Type</mat-label>
                <mat-select disableRipple formControlName="inventoryType" required>
                  <mat-option>None</mat-option>
                  <mat-option value="plots">Plots</mat-option>
                  <mat-option value="flats">Flats</mat-option>
                  <mat-option value="villas">Villas</mat-option>
                  <mat-option value="commercials">Commercials</mat-option>
                  <mat-option value="offices">Offices</mat-option>
                  <mat-option value="mansions">Mansions</mat-option>
                  <mat-option value="farmHouse">Farmhouse</mat-option>
                </mat-select>
                <mat-error *ngIf="firstFormGroup.controls['inventoryType'].hasError('required')">Inventory Type is required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <br>
        <div class="row col-md-12">
          <div class="custom-form-field41">
            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-md-8 mobile">
                  <mat-form-field>
                    <mat-label>From Price</mat-label>
                    <input matInput placeholder="" formControlName="fromPrice"  (input)="onInput($event)" maxlength="2"
                     type="text">
                    <mat-error *ngIf="firstFormGroup.controls['fromPrice'].hasError('required')">From Price is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-4 mobile">
                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="fromPriceUnit" required>
                      <mat-option value="Lac">Lac</mat-option>
                      <mat-option value="Cr">Crore</mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls['fromPriceUnit'].hasError('required')">Unit is required</mat-error>
                  </mat-form-field>
                </div>

              </div>
            </div>

            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-md-8 mobile">
                  <mat-form-field>
                    <mat-label>To Price</mat-label>
                    <input matInput placeholder="" formControlName="toPrice"  (input)="onInput($event)" maxlength="2"
                     type="text">
                    <mat-error *ngIf="firstFormGroup.controls['toPrice'].hasError('required')">To Price is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-4 mobile">
                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="toPriceUnit" required>
                      <mat-option value="Lac">Lac</mat-option>
                      <mat-option value="Cr">Crore</mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls['toPriceUnit'].hasError('required')">Unit is required</mat-error>
                  </mat-form-field>
                </div>

              </div>
            </div>
            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-md-8 mobile" >
                  <mat-form-field>
                    <mat-label>Size</mat-label>
                    <input matInput placeholder="" formControlName="size"  (input)="onInput($event)"
                    type="text"  >
                    <mat-error *ngIf="firstFormGroup.controls['size'].hasError('required')">Size is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-4 mobile">


                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="sizeUnit" required>
                      <mat-option value="Sqm">Square Meter</mat-option>
                      <mat-option value="Sqft">Square Foot</mat-option>
                      <mat-option value="Ac">Acre</mat-option>
                      <mat-option value="Sqyd">Square Yard</mat-option>
                      <mat-option value="Killa">Killa</mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls['sizeUnit'].hasError('required')">Unit is required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Status</mat-label>
              <mat-select disableRipple formControlName="status">
                <mat-option>None</mat-option>
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Inactive</mat-option>

              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['status'].hasError('required')">This field is required</mat-error>
            </mat-form-field>  -->


          </div>
        </div>
        <br>
        <div class="row col-md-12">
          <div class="custom-form-field4">
            <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Location</mat-label>
              <input matInput placeholder="" formControlName="location" required>
              <mat-error *ngIf="firstFormGroup.controls['location'].hasError('required')">Location is required</mat-error>
            </mat-form-field>
              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Status</mat-label>
              <mat-select disableRipple formControlName="status" required>
                <mat-option>None</mat-option>
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Inactive</mat-option>

              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['status'].hasError('required')">Status is required</mat-error>
            </mat-form-field>


            <!-- File upload fields -->
            <mat-form-field class="custom-form-field1">
              <div>
                <mat-toolbar>
                  <input matInput [value]="selectedFileName.length ? selectedFileName.join(', ') : 'Upload Banner Image'" readonly />
                  <button mat-flat-button color="primary" type="button" [disabled]="!selectedFile.length" (click)="uploadFiles()">Upload</button>
                </mat-toolbar>
                <input type="file" id="fileInput1" formControlName="uploadbanner" (change)="handleChanges($event)"
                  name="fileInput" accept="image/*" multiple hidden />
                <button mat-stroked-button type="button" class="font1" color="primary" (click)="triggerFileInput1()">Browse</button>
              </div>
              <div class="uploaded-images">
                <div *ngFor="let image of uploadedbanner; let i = index" class="uploaded-image-container">
                  <img [src]="image" alt="Uploaded Image" class="uploaded-image" />
                  <button mat-icon-button type="button" style="color:#004aad;" (click)="removeBanner(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-form-field>


            <mat-form-field class="custom-form-field1">
              <div>
                <mat-toolbar>
                  <input matInput [value]="selectedFileNames.length ? selectedFileNames.join(', ') : 'Upload Image'" readonly />
                  <button mat-flat-button color="primary" type="button" [disabled]="!selectedFiles.length" (click)="uploadFile()">Upload</button>
                </mat-toolbar>
                <input type="file" id="fileInput" formControlName="uploadPdf" (change)="handleChange($event)"
                  name="fileInput" accept="image/*" multiple hidden />
                <button mat-stroked-button type="button" color="primary" (click)="triggerFileInput()">Browse</button>
              </div>
              <div class="uploaded-images">
                <div *ngFor="let image of uploadedImages; let i = index" class="uploaded-image-container">
                  <img [src]="image" alt="Uploaded Image" class="uploaded-image" />
                  <button mat-icon-button style="color:#004aad;" (click)="removeImage(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-form-field>


          </div>

        </div>
        <br>

        <br>
        <div class="button-container">
          <button mat-button class="custom-button font1 onlyhover" type="button" (click)="handleCancel()">Cancel</button>
          <button mat-button class="custom-button font1 onlyhover" type="submit" style="color: whitesmoke">Next</button>
        </div>
      </form>

    </mat-step>
    <mat-step [stepControl]="secondFormGroup" label="Features">
      <form [formGroup]="secondFormGroup">
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Builder</mat-label>
                <input matInput placeholder="" formControlName="builder" required>
                <mat-error *ngIf="secondFormGroup.controls['builder'].hasError('required')">Builder is required</mat-error>
              </mat-form-field>
              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Connectivity</mat-label>
                <mat-select formControlName="connectivity" required>
                  <mat-option>None</mat-option>
                  <mat-option value="Wi-Fi">Wi-Fi</mat-option>
                  <mat-option value="Ethernet">Ethernet</mat-option>
                  <mat-option value="Mobile Data">Mobile Data</mat-option>
                  <mat-option value="Bluetooth">Bluetooth</mat-option>
                  <mat-option value="VPN">VPN</mat-option>
                  <mat-option value="Satellite">Satellite</mat-option>
                  <mat-option value="Other">Other</mat-option>

                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['connectivity'].hasError('required')">Connectivity is required</mat-error>
              </mat-form-field>

              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Construction Approvals</mat-label>
                <mat-select formControlName="constructionApprovals" required>
                  <mat-option value="Approved">Approved</mat-option>
                  <mat-option value="Pending">Pending</mat-option>
                  <mat-option value="Not Applicable">Not Applicable</mat-option>
                  <mat-option value="Denied">Denied</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['constructionApprovals'].hasError('required')">
                  Construction Approvals is required
                </mat-error>
              </mat-form-field>

            </div>
          </div>
        </div>
        <br>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Flat Purchase Checklist</mat-label>
                <mat-select formControlName="flatPurchaseChecklist" required>
                  <mat-option value="Property Inspection">Property Inspection</mat-option>
                  <mat-option value="Loan Approval">Loan Approval</mat-option>
                  <mat-option value="Legal Documentation">Legal Documentation</mat-option>
                  <mat-option value="Down Payment">Down Payment</mat-option>
                  <mat-option value="Home Insurance">Home Insurance</mat-option>
                  <mat-option value="Final Walkthrough">Final Walkthrough</mat-option>
                  <mat-option value="Closing Costs">Closing Costs</mat-option>
                  <mat-option value="Title Search">Title Search</mat-option>
                  <mat-option value="Utility Setup">Utility Setup</mat-option>
                  <mat-option value="Move-In Date">Move-In Date</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['flatPurchaseChecklist'].hasError('required')">Flat Purchase Checklist is required</mat-error>
              </mat-form-field>
              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Investment Perspective</mat-label>
                <mat-select formControlName="investmentPerspective" required>
                  <mat-option value="Risk Assessment">Risk Assessment</mat-option>
                  <mat-option value="Return On Investment (ROI)">Return On Investment (ROI)</mat-option>
                  <mat-option value="Market Trends">Market Trends</mat-option>
                  <mat-option value="Investment Horizon">Investment Horizon</mat-option>
                  <mat-option value="Diversification Strategy">Diversification Strategy</mat-option>
                  <mat-option value="Asset Allocation">Asset Allocation</mat-option>
                  <mat-option value="Liquidity Needs">Liquidity Needs</mat-option>
                  <mat-option value="Tax Implications">Tax Implications</mat-option>
                  <mat-option value="Economic Indicators">Economic Indicators</mat-option>
                  <mat-option value="Investment Goals">Investment Goals</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['investmentPerspective'].hasError('required')">Investment Perspective is required</mat-error>
              </mat-form-field>


              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Security</mat-label>
                <mat-select formControlName="security" required>
                  <mat-option value="High">High</mat-option>
                  <mat-option value="Medium">Medium</mat-option>
                  <mat-option value="High">Low</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['security'].hasError('required')">
                  Security is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <br>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Access To</mat-label>
                <mat-select formControlName="accessTo" required>
                  <mat-option value="Public Access">Public Access</mat-option>
                  <mat-option value="Restricted Access">Restricted Access</mat-option>
                  <mat-option value="Admin Access">Admin Access</mat-option>
                  <mat-option value="User Access">User Access</mat-option>
                  <mat-option value="Guest Access">Guest Access</mat-option>
                  <mat-option value="Read-Only Access">Read-Only Access</mat-option>
                  <mat-option value="Full Access">Full Access</mat-option>
                  <mat-option value="Limited Access">Limited Access</mat-option>
                  <mat-option value="Temporary Access">Temporary Access</mat-option>
                  <mat-option value="No Access">No Access</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['accessTo'].hasError('required')">Access To is required</mat-error>
              </mat-form-field>

              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Zoning Risks</mat-label>
                <mat-select formControlName="zoningRisks" required>
                  <mat-option value="Regulatory Changes">Regulatory Changes</mat-option>
                  <mat-option value="Environmental Restrictions">Environmental Restrictions</mat-option>
                  <mat-option value="Land Use Conflicts">Land Use Conflicts</mat-option>
                  <mat-option value="Zoning Violations">Zoning Violations</mat-option>
                  <mat-option value="Market Demand Fluctuations">Market Demand Fluctuations</mat-option>
                  <mat-option value="Infrastructure Limitations">Infrastructure Limitations</mat-option>
                  <mat-option value="Community Opposition">Community Opposition</mat-option>
                  <mat-option value="Property Value Impact">Property Value Impact</mat-option>
                  <mat-option value="Development Delays">Development Delays</mat-option>
                  <mat-option value="Compliance Costs">Compliance Costs</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['zoningRisks'].hasError('required')">Zoning Risks is required</mat-error>
              </mat-form-field>

              <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
                <mat-label>Verification</mat-label>
                <mat-select formControlName="verification" required>
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['verification'].hasError('required')">
                  Verification is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <br>
        <div class="row col-md-12">
          <div class="custom-form-field4">
            <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Modern Features</mat-label>
              <mat-select formControlName="modernFeatures" required>
                <mat-option value="Smart Home Technology">Smart Home Technology</mat-option>
                <mat-option value="Energy-Efficient Appliances">Energy-Efficient Appliances</mat-option>
                <mat-option value="Open Floor Plan">Open Floor Plan</mat-option>
                <mat-option value="Sustainable Materials">Sustainable Materials</mat-option>
                <mat-option value="Home Automation Systems">Home Automation Systems</mat-option>
                <mat-option value="High-Speed Internet Connectivity">High-Speed Internet Connectivity</mat-option>
                <mat-option value="Smart Security Systems">Smart Security Systems</mat-option>
                <mat-option value="Green Roofs">Green Roofs</mat-option>
                <mat-option value="Solar Panels">Solar Panels</mat-option>
                <mat-option value="Integrated Smart Lighting">Integrated Smart Lighting</mat-option>
              </mat-select>
              <mat-error *ngIf="secondFormGroup.controls['modernFeatures'].hasError('required')">Modern Features is required</mat-error>
            </mat-form-field>

            <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>No Objection Certificates</mat-label>
              <mat-select formControlName="noObjectionCertificates" required>
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
              <mat-error *ngIf="secondFormGroup.controls['noObjectionCertificates'].hasError('required')">
                No Objection Certificates is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Market Research</mat-label>
              <mat-select formControlName="marketResearch" required>
                <mat-option value="Surveys">Surveys</mat-option>
                <mat-option value="Focus Groups">Focus Groups</mat-option>
                <mat-option value="Observational Research">Observational Research</mat-option>
                <mat-option value="Market Analysis">Market Analysis</mat-option>
                <mat-option value="Competitor Analysis">Competitor Analysis</mat-option>
                <mat-option value="Consumer Behavior Studies">Consumer Behavior Studies</mat-option>
                <mat-option value="Product Testing">Product Testing</mat-option>
                <mat-option value="Trend Analysis">Trend Analysis</mat-option>
                <mat-option value="Data Analytics">Data Analytics</mat-option>
              </mat-select>
              <mat-error *ngIf="secondFormGroup.controls['marketResearch'].hasError('required')">Market Research is required</mat-error>
            </mat-form-field>



          </div>
        </div>
        <br>
        <div class="button-container">
          <button mat-button class="custom-button font1  onlyhover" type="button" (click)="stepper.previous()">Back</button>
          <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
        </div>
      </form>
    </mat-step>

  </mat-stepper>
</div>
<app-footer></app-footer>

<div class="page-container">


</div>
