import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription,Observable } from 'rxjs';
import { HomeService } from 'src/app/_services/home.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { ErrorMessageService } from 'src/app/services/error-message.service';

@Component({
  selector: 'app-top-project-listing',
  templateUrl: './top-project-listing.component.html',
  styleUrls: ['./top-project-listing.component.scss']
})
export class TopProjectListingComponent implements OnInit {
  searchValue: string = '';  // Initialize searchValue as an empty string
  topList: any[] = [];
  IsLoading: boolean = false;
 loading$: Observable<boolean>;
  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
     private errorMessageService: ErrorMessageService,
        private toastr:ToastrService
  ) {}

  ngOnInit(): void {
    this.getProjectList();
    this.loading$ = this.loadingService.loading$;
  }

  onSearchTermChange(searchTerm: string) {
    this.searchValue = searchTerm;
    this.getProjectList();
  }

  getProjectList(): void {
    this.loadingService.show();

    // Ensure that the searchValue is passed as an empty string if it's undefined
    this.homeService.getProjectList('top', this.searchValue || '').subscribe((response: any) => {
      this.IsLoading = false;
      if (response.status === 'SUCCESS') {
        this.loadingService.hide();
        this.topList = response.payload.items;
      } else if (response['message']) {
        let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
        this.toastr.error(errorMessage, 'Error');
      }
    },  (error) => {
      const message = error?.error?.message || 'Failed to fetch city data';
      const errorMessage = this.errorMessageService.getErrorMessage(message);
      this.toastr.error(errorMessage, 'Error');
    }
  );
  }
}
