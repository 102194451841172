import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyOwnerComponent } from '../property-owner/property-owner.component';
import { MatDialog } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { LoginPopupComponent } from '../login-popup/login-popup.component';
import { auto } from '@popperjs/core';
import { ErrorMessageService } from '../services/error-message.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit {
  listing: any;
  listingData: any;
  currentIndex: number = 0;
  data: any;
  id: number;
  UserDetails: any;
  Images: string[] = [];
  asking: number;
  size: number;
  total: any;
  total1: string;
  sizeUnit: string = '';
  address:any
  postdate:any
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private zone: NgZone,
    private errorMessageService: ErrorMessageService,
    private toastr:ToastrService

  ) { }
  showowner = localStorage.getItem('UserProfile');
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log('params', params);

      this.id = params['id'];
      if (this.id) {
        this.propertById();
      } else {
        console.error('No Property ID found in query params');
      }
    });
  }

  propertById() {
    let fullName = null;
    this.loadingService.show();

    const loginuser = localStorage.getItem('UserProfile');
    console.log(loginuser);


    if (!loginuser && fullName === null && fullName !== undefined) {
      this.homeService.getuserById(this.id).subscribe(
        (response: any) => {
          this.loadingService.hide();

          if (response.status === 'SUCCESS') {
            console.log('response', response);
            this.listingData = response?.payload;
            this.postdate = response.payload.createdAt || '';
            this.UserDetails = response?.payload?.user;
            this.Images = response.payload.mediaGroup.map((item) => item.url);
            console.log('listingData', this.listingData);
            this.propertyper(this.listingData);
          } else {
            this.listingData = null;
            const errorMessage = this.errorMessageService.getErrorMessage('Failed to fetch user property data');
            this.toastr.error(errorMessage, 'Error');
          }
        },
        (error) => {
          this.loadingService.hide();
          this.listingData = null;

          const message = error?.error?.message || 'Failed to fetch user property data';
          const errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }
      );
    } else {
      this.homeService.getPropertyById(this.id).subscribe(
        (response: any) => {
          this.loadingService.hide();

          if (response.status === 'SUCCESS') {
            console.log('response', response);
            this.listingData = response?.payload;
            this.postdate = response.payload.createdAt || '';
            this.UserDetails = response?.payload?.user;
            this.Images = response.payload.mediaGroup.map((item) => item.url);
            console.log('listingData', this.listingData);
            this.propertyper(this.listingData);
          } else {
            this.listingData = null;
            const errorMessage = this.errorMessageService.getErrorMessage('Failed to fetch property details');
            this.toastr.error(errorMessage, 'Error');
          }
        },
        (error) => {
          this.loadingService.hide();
          this.listingData = null;

          const message = error?.error?.message || 'Failed to fetch property details';
          const errorMessage = this.errorMessageService.getErrorMessage(message);
          this.toastr.error(errorMessage, 'Error');
        }
      );
    }
  }


  propertyper(listingData: any) {
    if(listingData.askingUnit==='Lac'){
     this.total=listingData.asking
    }else if(listingData.askingUnit==='Cr'){
      this.total=listingData.asking
    }
    // this.asking = listingData.asking;
    // const sizeString = listingData?.feature?.size || '0';
    // const sizeMatch = sizeString.match(/[\d.]+/);
    // this.size = sizeMatch ? parseFloat(sizeMatch[0]) : 0;
    // this.sizeUnit = sizeString.replace(/[\d.]+/, '').trim();

    // if (this.size > 0) {
    //   const totalValue = Math.floor(this.asking / this.size);
    //   this.total = `${totalValue.toLocaleString()} ${this.sizeUnit}`;
    //   this.total1 = `${this.sizeUnit}`;
    // } else {
    //   console.error('Size cannot be zero');
    //   this.total = '0';
    // }
  }


  // openContactDetailsDialog(listingData): void {
  //   const lastdate = localStorage.getItem('auth-user');
  //   if (lastdate) {
  //     try {
  //       const lastdatedata = JSON.parse(lastdate);
  //       const lastdatedata1 = JSON.parse(lastdatedata);
  //       const lastLoginString = lastdatedata1.lastLogin;
  //       console.log("lastLogin", lastLoginString);
  //       const lastLogin = this.parseDate(lastLoginString);
  //       const currentTime = new Date();
  //       if (lastLogin) {
  //         const adjustedTime = new Date(currentTime.getTime() - (5 * 60 * 60 * 1000) - (30 * 60 * 1000));
  //         const timeDifferenceInSeconds = Math.floor((adjustedTime.getTime() - lastLogin.getTime()) / 1000);
  //         console.log("count 0 to 1800", timeDifferenceInSeconds);
  //         if (timeDifferenceInSeconds <= 1800) {
  //           this.dialog.open(PropertyOwnerComponent, {
  //             data: listingData,
  //             width: '830px',
  //             height:'500px'
  //           });

  //         }
  //        else {
  //         console.error('Invalid date parsed:', lastLogin);
  //         // this.router.navigate(['/login']);
  //         this.loginPopup()
  //       }
  //     }
  //     } catch (error) {
  //       console.error('Error parsing lastdate:', error);
  //       // this.router.navigate(['/login']);
  //       this.loginPopup()

  //     }
  //   } else {
  //     console.log('User is not logged in.');
  //     this.loginPopup()

  //     // this.router.navigate(['/login']);
  //   }
  // }

  // loginPopup(){
  //   this.dialog.open(LoginPopupComponent, {
  //     width: '380px',
  //     height:auto
  //   });
  // }
  // openContactDetailsDialog(listingData): void {
  //   const lastdate = localStorage.getItem('auth-user');
  //   if (lastdate) {
  //     try {
  //       const lastdatedata = JSON.parse(lastdate);
  //       const lastdatedata1 = JSON.parse(lastdatedata);
  //       const lastLoginString = lastdatedata1.lastLogin;
  //       console.log("lastLogin", lastLoginString);
  //       const lastLogin = this.parseDate(lastLoginString);
  //       const currentTime = new Date();
  //       if (lastLogin) {
  //         // const adjustedTime = new Date(currentTime.getTime() - (5 * 60 * 60 * 1000) - (30 * 60 * 1000));
  //         // const timeDifferenceInSeconds = Math.floor((adjustedTime.getTime() - lastLogin.getTime()) / 1000);
  //         // console.log("count 0 to 1800", timeDifferenceInSeconds);
  //         if (lastLogin) {
  //           this.dialog.open(PropertyOwnerComponent, {

  //             data: listingData,
  //             width: '830px',
  //             height:'500px'
  //           });
  //         }
  //        else {
  //         console.error('Invalid date parsed:', lastLogin);
  //         // this.router.navigate(['/login']);
  //         this.loginPopup()
  //       }
  //     }
  //     } catch (error) {
  //       console.error('Error parsing lastdate:', error);
  //       // this.router.navigate(['/login']);
  //       this.loginPopup()

  //     }
  //   } else {
  //     console.log('User is not logged in.');
  //     // this.router.navigate(['/login']);
  //     this.loginPopup()

  //   }
  // }
  openContactDetailsDialog(listingData): void {
    const lastdate = localStorage.getItem('UserProfile');
    if (lastdate) {
                this.dialog.open(PropertyOwnerComponent, {
                  data: listingData,
                  width: '830px',
                  height:'500px'
                });
              } else {
      console.log('User is not logged in.');
      this.loginPopup()

    }
  }
  loginPopup(){
    this.dialog.open(LoginPopupComponent, {
      width: '380px',
      height:auto
    });
  }
  parseDate(dateString: string): Date {
    const parts = dateString.split(' ');
    const dateParts = parts[0].split('-');
    const timeParts = parts[1].split(':');
    return new Date(
      parseInt(dateParts[2], 10),
      parseInt(dateParts[1], 10) - 1,
      parseInt(dateParts[0], 10),
      parseInt(timeParts[0], 10),
      parseInt(timeParts[1], 10),
      parseInt(timeParts[2], 10)
    );

  }

  ngAfterContentChecked(): void {
  }
  back(){
    window.history.back();
  }
}
