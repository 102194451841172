<div class="col-12 col-md-12 mb-2 mb-md-0">
  <div class="search-container">
    <div class="styled-select-container position-relative">
      <input type="text" class="form-controls search-city-input" [(ngModel)]="searchCityQuery"
        (keyup)="searchCity(searchCityQuery)" (click)="onCityInputClick()" style="cursor: pointer;"
        (focus)="showCitySuggestions = true" (blur)="hideCitySuggestions();"
        [placeholder]="selectedCityName ? selectedCityName : 'Search City...'" #cityInput />

      <!-- Clear Icon -->
      <!-- <span *ngIf="searchCityQuery" class="clear-icon" (click)="clearSearchdrop()">
        ✖
      </span> -->

      <span class="dropdown-icon" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);
      cursor: pointer; transition: color 0.3s ease;">
                       <i class="fas fa-chevron-down"
                          *ngIf="!showCitySuggestions"
                          (click)="showDropdown(cityInput)"  style="font-size: 16px; color: gray !important;">
                       </i>

                       <!-- Icon to HIDE suggestions -->
                       <i class="fas fa-chevron-up"
                          *ngIf="showCitySuggestions"
                          (click)="hideDropdown()"  style="font-size: 16px; color: gray !important;">
                       </i>
                     </span>

      <!-- Dropdown Suggestions -->
      <ul *ngIf="showCitySuggestions && cityDataList.length > 0" class="suggestion-dropdown">
        <li *ngFor="let city of cityDataList" (mousedown)="selectCity(city)">
          {{ city.name }}
        </li>
      </ul>
    </div>

    <mat-icon class="search-icon">search</mat-icon>

    <input type="text" class="search-input" placeholder="Search Project Location or Builder"
      [(ngModel)]="searchTerm" (ngModelChange)="applySearchFilter()" />

    <button class="search-button" (click)="applySearchFilter()">Search</button>
  </div>
</div>

