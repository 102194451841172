import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { filter } from 'rxjs/operators';
import { HomeService } from '../_services/home.service';
import { HeaderSearchService } from '../_services/header-search.service';
import { LoginPopupComponent } from '../login-popup/login-popup.component';
import { auto } from '@popperjs/core';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileService } from '../_services/user-profile.service';
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush // Ensures c
})

export class HeaderComponent implements OnInit {
	@ViewChild('searchkey') searchInput: ElementRef;
	constructor(private route: ActivatedRoute,
		private authService: AuthService,
		private tokenStorage: TokenStorageService,
		private homeService: HomeService,
		private router: Router,
		private dialog: MatDialog,
		private cdr: ChangeDetectorRef,
		private userProfileService:UserProfileService,
		private searchService: HeaderSearchService) {
	}
	searchTerm = 'artwork';
	dropdownOpen = false;
	usertoken: any = [];
	token = '';
	userinfo: any = [];
	socialOrigin = '';
	itsMe = true;
	userid = '';
	unReadCount = 0;
	isDropdownOpen: boolean = false
	collapsed: boolean = false
	builder: boolean | string = '';
	auths: boolean | string = '';

	// collapsed3:boolean = false
	collapsed1: boolean = false;
	isKhubiiAdmin = false;
	isMenushow: boolean = false
	userfullname: string = '';
	useremail = '';
	@ViewChild('navbarToggler') navbarToggler: ElementRef;


	populateForm() {
		const profileData = localStorage.getItem('UserProfile');
		console.log('profileData',profileData);

		if (profileData) {
			this.userfullname = JSON.parse(profileData)?.payload?.fullName;
			this.builder = JSON.parse(profileData)?.payload?.builder;
			this.useremail = JSON.parse(profileData)?.payload?.email;
			this.cdr.detectChanges();
		}
	}
	loginPopup() {
		this.dialog.open(LoginPopupComponent, {
			width: '380px',
			height: auto
		});
	}
	navigateTologin() {
		this.loginPopup()
	}
	ngOnInit(): void {
		this.userProfileService.userProfile$.subscribe(profile => {
		  if (profile) {
			this.userfullname = profile.payload?.fullName||profile?.fullName;
			this.useremail = profile.payload?.email||profile?.email;
			this.builder = profile.payload?.builder||profile?.builder;
			this.cdr.detectChanges(); // Ensures UI updates properly
		  }
		});
	  }



	navBarTogglerIsVisible() {
		return this.navbarToggler?.nativeElement.getAttribute('aria-expanded')
	}
	@ViewChild('navbarToggler1') navbarToggler1: ElementRef;
	navBarTogglerIsVisible1() {
		return this.navbarToggler1?.nativeElement.getAttribute('aria-expanded')
	}
	navigateToUserProprty() {

		this.router.navigate(['/UserProperty'])
	}
	navigateToUserProjects() {

		this.router.navigate(['/myprojects'])
	}

	toggleDropdown() {
		this.dropdownOpen = !this.dropdownOpen;
	}
	toggleDropdowns() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}
	selectOption(option: string) {
		this.dropdownOpen = false;
	}


	searchArt() {
		const searchData = this.searchInput.nativeElement.value;
		this.searchService.setSearchData(searchData);
	}



	userLogout() {
		this.tokenStorage.signOut();
		this.router.navigate(['/login']).then(() => {
			window.location.reload();
		});
	}
	newsnavigate() {
		this.router.navigate(['/news']).then(() => {
			//   window.location.reload();

			let currentUrl = this.router.url;
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
			this.router.onSameUrlNavigation = 'reload';
			this.router.navigate([currentUrl]);
		});
	}

}
