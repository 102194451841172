    <div class="close-buttons" (click)="closeDialog()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>

<div class="form-container">
    <form [formGroup]="loginform" (ngSubmit)="onLoginSubmit()" *ngIf="currentForm === 'login'">

        <div class="navbar-brand form" >
          <img src="../../assets/images/logo45.png" alt=""  class="onlylogo" >
        </div>

        <h2 class="welcome-back">Welcome Back</h2>

        <input type="text" placeholder="Enter Email" formControlName="emailusernam" />
        <div class="errorClass" *ngIf="submittedl && h.emailusernam.errors">
          <div *ngIf="h.emailusernam.errors.required">Email is required</div>
        </div>
        <!-- <input type="password" placeholder="Password" formControlName="loginpassword" /> -->
        <div class="password-container">
          <input
            [type]="showPassword ? 'text' : 'password'"
            placeholder="Password"
            formControlName="loginpassword"
            class="password-input"
          />
          <img
            [src]="showPassword ? '../../assets/images/eye.png' : '../../assets/images/view (1).png'"
            alt="Toggle password visibility"
            class="toggle-icon"
            (click)="togglePasswordVisibility()"
          />
        </div>

        <div class="errorClass" *ngIf="submittedl && h.loginpassword.errors">
          <div *ngIf="h.loginpassword.errors.required">Password is required</div>
          <div *ngIf="h.loginpassword.errors.minlength">Password must be at least 6 characters</div>
        </div>
        <button type="submit" class="signinn">Sign In</button>
        <br>
        <div  class="signinhere"><span (click)="forgotSwitch()" style="cursor: pointer;">Forgot your password?</span></div>
            <div class="signinhere" ><span (click)="toggle()" style="cursor: pointer;">Sign up</span></div>

      </form>


    <form [formGroup]="forgotform" (ngSubmit)="onForgotSubmit()"  *ngIf="currentForm === 'forgot'">
        <a class="navbar-brand form" >
          <img src="../../assets/images/logo45.png" alt="" class="onlylogo" >
        </a>
        <h3 class="welcome-back">Forgot Password</h3>
        <input type="email" placeholder="Email" formControlName="forgotemail" />
        <div class="errorClass" *ngIf="submittedf && k.forgotemail.errors">
          <div *ngIf="k.forgotemail.errors.required">Email is required</div>
          <div *ngIf="k.forgotemail.errors.email">Email must be a valid email address</div>
        </div>
        <button type="submit" class="signinn">Confirm Email</button>
        <div  class="signinhere"><span (click)="loginSwitch()" style="cursor: pointer;">Sign In</span></div>
      </form>

    <form [formGroup]="regform" (ngSubmit)="onSubmit()" *ngIf="currentForm === 'signup'" >
        <a class="navbar-brand form" >
          <img src="../../assets/images/logo45.png" alt="" class="onlylogo " >
        </a>
        <h2 class="welcome-back">Create Account</h2>
        <input type="text" placeholder="Enter Full Name" formControlName="fullName" />
        <div class="errorClass" *ngIf="submitted && f.fullName.errors">
          <div *ngIf="f.fullName.errors.required">Full Name is required</div>
        </div>
        <div class="position-relative">
          <input
            type="email"
            placeholder="Enter Email"
            formControlName="email"
            #emailInput
            [attr.disabled]="disableEmail ? true : null"
          />
          <button
            type="button"
            class="btn input-btn"
            [ngClass]="{ 'error-active': submitted && f.email.errors }"
            (click)="onVerifyEmail(emailInput.value)"[attr.disabled]="disableEmail ? true : null"
          >
            Verify
          </button>

          <div class="errorClass" *ngIf="submitted && f.email.errors">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
        </div>




        <input type="text" placeholder="Enter Mobile Number" formControlName="mobileNumber" (input)="onInput($event)"/>
        <div class="errorClass" *ngIf="submitted && f.mobileNumber.errors">
          <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
        </div>
        <!-- <input type="password" placeholder="Password" formControlName="password" /> -->
        <div class="password-container">
          <input
            [type]="showPasswordsignup ? 'text' : 'password'"
            placeholder="Password"
            formControlName="password"
            class="password-input"
          />
          <img
            [src]="showPasswordsignup ? '../../assets/images/eye.png' : '../../assets/images/view (1).png'"
            alt="Toggle password visibility"
            class="toggle-icon"
            (click)="togglePasswordsignup()"
          />
        </div>
        <div class="errorClass" *ngIf="submitted && f.password.errors">
          <div *ngIf="f.password.errors.required">Password is required</div>
          <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
        </div>
        <div class="col-md-12 col-12 user-type-container">
          <label class="main-label mt-2">Are You</label>
          <div class="radio-group">
            <label>
              <input type="radio" formControlName="userType" [value]="{ individual: true, builder: false, agent: false }" /> Individual
            </label>
            <label>
              <input type="radio" formControlName="userType" [value]="{ individual: false, builder: true, agent: false }" /> Builder
            </label>
            <label>
              <input type="radio" formControlName="userType" [value]="{ individual: false, builder: false, agent: true }" /> Dealer
            </label>
          </div>
          <div class="errorClass errorClass12" *ngIf="submitted && f.userType.errors">
            <div *ngIf="f.userType.errors.required">User Type is required</div>
          </div>
        </div>
        <button type="submit" class="signinn">Sign Up</button>
        <div class="signinhere">
            <span (click)="toggle()" style="cursor: pointer;">Sign in</span>
        </div>
      </form>

</div>
