<!-- Add a container wrapper -->
<div class="page-container">
  <app-header></app-header>
  <div class="content">
    <img src="../../assets/images/aboutuppro.jpg" alt="About Us" class="aboutus">
    <div *ngIf="ContacUsContent" [innerHTML]="ContacUsContent" class="aboutus-container"></div>
  </div>

  <app-footer></app-footer>
</div>
