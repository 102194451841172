import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {
  private errorMessages: { [key: string]: string } = {
    'in.co.ksquaretech.backend.user.exist.with.same.email': 'User with this email already exists.',
    'in.co.ksquaretech.backend.user.exist.with.same.mobile': 'User with this mobile already exist',
    'in.co.ksquaretech.backend.missing.parameteres.email.mobile': 'Mandatory parameters email or mobile missing',
    'in.co.ksquaretech.backend.record.not.found': 'Record Not Found',
    'in.co.ksquaretech.backend.current.password.not.match': 'Password not match',
    'in.co.ksquaretech.backend.invalid.otp': 'Invalid OTP',
    'in.co.ksquaretech.backend.otp.not.found': 'Incorrect Otp, please enter valid OTP.',
    'in.co.ksquaretech.backend.expire.otp': 'Expire OTP',
    'in.co.ksquaretech.backend.cannot.update.profile.of.another.user': 'Cannot update profile of another user',
    'in.co.ksquaretech.backend.invalid.user': 'User with this email/phone number can not be found',
    'in.co.ksquaretech.backend.required.field.missing': 'Required Field Missing',
    'in.co.ksquaretech.backend.user.email.not.verified': 'User has not verified his/her email',
    'in.co.ksquaretech.backend.user.mobile.not.verified': 'User has not verified his/her mobile',
    'in.co.ksquaretech.backend.user.profile.not.exist': 'User Profile not setup with details',
    'in.co.ksquaretech.backend.secret.question.not.setup.for.profile': 'Security Question not setup in user profile',
    'in.co.ksquaretech.backend.airport.exist.with.same.information': 'Airport with provided information already exist',
    'in.co.ksquaretech.backend.airport.with.id.not.exist': 'Airport with provided information not exist',
    'in.co.ksquaretech.backend.airport.terminal.with.id.not.exist': 'Airport terminal with ID does not exist.',
    'in.co.ksquaretech.backend.city.with.id.not.exist': 'City with ID does not exist',
    'in.co.ksquaretech.backend.city.with.code.not.exist': 'City with code does not exist',
    'in.co.ksquaretech.backend.city.already.exist': 'City already exist',
    'in.co.ksquaretech.backend.state.information.incorrect': 'State Information Incorrect',
    'in.co.ksquaretech.backend.country.information.incorrect': 'Country information incorrect',
    'in.co.ksquaretech.backend.state.with.id.not.exist': 'State with ID does not exist',
    'in.co.ksquaretech.backend.state.already.exist': 'State already exist',
    'in.co.ksquaretech.backend.country.with.id.not.exist': 'Country with ID does not exist',
    'in.co.ksquaretech.backend.country.already.exist': 'Country already exist',
    'in.co.ksquaretech.backend.shop.with.id.not.exist': 'Shop ID Information Incorrect',
    'in.co.ksquaretech.backend.invalid.user.device.data': 'User Device Data Not Correct',
    'in.co.ksquaretech.backend.invalid.data': 'Requested Data Is Invalid',
    'in.co.ksquaretech.backend.account.id.invalid.data': 'Account ID Is Invalid',
    'in.co.ksquaretech.backend.no.data.found': 'No Data Found',
    'in.co.ksquaretech.backend.something.went.wrong': 'Something Went Wrong',
    'in.co.ksquaretech.backend.invalid.token': 'Invalid Token',
    'in.co.ksquaretech.backend.account.deactivates': 'Account has been deactivated.',
    'in.co.ksquaretech.backend.account.email.not.exist': 'Email is not registered',
    'in.co.ksquaretech.backend.account.phone.not.exist': 'Phone Number is not registered',
    'in.co.ksquaretech.backend.account.deleted.byuser': 'Account has been deleted',
    'in.co.ksquaretech.backend.account.invalid.credential.email': 'Incorrect Email or Password',
    'in.co.ksquaretech.backend.account.invalid.credential.mobile': 'Incorrect Mobile Number or Password',
    'in.co.ksquaretech.backend.email.not.registered': 'Your email is not registered.',
    'in.co.ksquaretech.backend.phone.not.registered': 'Phone number not on file. Please register.',
    'in.co.ksquaretech.backend.dont.forget.to.verify.phone': 'Don’t forget to verify your phone number.',
    'in.co.ksquaretech.backend.dont.forget.to.verify.email': 'Don’t forget to verify your email.',
    'in.co.ksquaretech.backend.no.rating.review.available': 'No rating review available.',
    'in.co.ksquaretech.backend.you.can.not.access': 'You can not access.',
    'in.co.ksquaretech.backend.mobile.signupwithemail.error.invalidEmail' :' Please enter valid email',
    'in.co.ksquaretech.backend.city.and.area.with.properties.not.exist' : 'No properties available in this city',
    'in.co.ksquaretech.backend.properties.with.id.not.exist' : 'Properties with id not exist',
    'in.co.ksquaretech.backend.project.with.id.not.exist ':' Project with id not exist',
    'in.co.ksquaretech.backend.user.with.id.not.exist ':' User with id not exist',
    'in.co.ksquaretech.backend.Advertisement.with.id.not.exist ':' Advertisement with id not exist',
    'in.co.ksquaretech.backend.user.query.with.id.not.exist ':' User query with id not exist',
    'in.co.ksquaretech.backend.category.with.id.not.exist ':' Category with id not exist',
    'in.co.ksquaretech.backend.package.with.id.not.exist ':' Package with id not exist',
    'in.co.ksquaretech.backend.customer.with.id.vendor.code.not.exist ':' customer with vendor code not exist',
    'in.co.ksquaretech.backend.wallet.point.with.id.not.exist ':' wallet point with id not exist',
   ' in.co.ksquaretech.backend.city.and.area.with.properties.not.exist' :' No properties available in this city',
    'in.co.ksquaretech.backend.please.enter.your.otp' :' please enter your otp.',
    'in.co.ksquaretech.backend.current.password.or.otp.not.match' :' Current password or OTP does not match. Please try again.'
  };

  getErrorMessage(errorCode: string): string {
    return this.errorMessages[errorCode] || errorCode;
  }
}
