<div class="col-12 col-md-12 mb-2 mb-md-0">
  <div class="search-container">
    <mat-icon class="search-icon" style="color: gray;">search</mat-icon>
    <input
      type="text"
      class="search-input"
      placeholder="Search Project Location or Builder"
      [(ngModel)]="searchTerm"
      (ngModelChange)="applySearchFilter()"
    />
    <button class="search-button" (click)="applySearchFilter()">
      Search
    </button>
  </div>
</div>

