import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
import { ActivatedRoute } from '@angular/router';
;
@Component({
  selector: 'app-property-owner',
  templateUrl: './property-owner.component.html',
  styleUrls: ['./property-owner.component.scss']
})
export class PropertyOwnerComponent implements OnInit {
  listingData: any;
  formattedDate: Date;
  contactForm: FormGroup;
  userProfileData: any = {};
  name: string = '';
  email: string = '';
  mobile: string = '';
  UserData:any
  owner:any
  createdAt:any
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private homeservice:HomeService,
    private route:ActivatedRoute,
    public dialogRef: MatDialogRef<PropertyOwnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);



    this.contactForm = this.fb.group({
      reasonToBuy: [''],
      propertyDealer: [''],
      name: [{ value: '', disabled: true }, [ Validators.minLength(2)]],
      mobile: [{ value: '', disabled: true }, [ Validators.pattern(/^[0-9]{10}$/)]],
      email: [{ value: '', disabled: true }, [ Validators.email]],
      planningToBuy: [''],
      homeLoan: [false],
      siteVisits: [false],
      terms: [false, Validators.requiredTrue]
    });
  }

  loginUser() {
    const profileData = localStorage.getItem('auth-user');
    this.UserData=JSON.parse(profileData);
    this.userProfileData = this.UserData;


    if (profileData) {
      try {
        let userProfileData1 = JSON.parse(profileData);
        this.userProfileData = JSON.parse(userProfileData1);
        this.contactForm.patchValue({
          name: this.userProfileData.fullName || 'N/A',
          email: this.userProfileData.email || 'N/A',
          mobile: this.userProfileData.mobileNumber || 'N/A'
        });
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
      }
    } else {
      console.log("No profile data found in localStorage");
    }
  }





  ngOnInit(): void {
    this.listingData = {
      ...this.data,
      ...this.data.user,
    };
    console.log('this.listingData', this.listingData);

    if (this.data?.user) {
      const user = this.data.user;
      this.contactForm.patchValue({
        name: user.fullName,
        email: user.email,
        mobile: user.mobileNumber
      });
    }

    console.log("this.listingData", this.listingData);

    // Assign createdAt, fallback to listingData.createdAt if data.createdAt is null or undefined
    this.createdAt = this.data.createdAt ?? this.listingData.createdAt;

    if (this.createdAt) {
      const [datePart] = this.createdAt.split(' ');
      const [day, month, year] = datePart.split('-').map(Number);
      this.formattedDate = new Date(year, month - 1, day); // Convert to Date object
    }
  }


  // formatDate(dateString: string): string {
  //   const date = new Date(dateString);
  //   if (isNaN(date.getTime())) {
  //     return "Invalid Date";
  //   }

  //   const day = date.getDate().toString().padStart(2, '0');
  //   const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  //   const month = monthNames[date.getMonth()];
  //   const year = date.getFullYear();

  //   return `${day}TH ${month}, ${year}`;
  // }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Function to get the appropriate suffix for the day
    function getDaySuffix(day: number): string {
      if (day > 3 && day < 21) return 'th'; // Special case for days 11–13
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    }

    const daySuffix = getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }


  closeDialog() {
    this.dialogRef.close();
  }





  onSubmit() {
    if (this.contactForm.valid) {
      // const inventoryId = 0;
      const id = this.route.snapshot.queryParamMap.get('id'); // Get the id parameter
      const payload = {
        ...this.contactForm.value,
        name: this.contactForm.get('name').value,
        email: this.contactForm.get('email').value,
        mobile: this.contactForm.get('mobile').value,
        inventoryId:'',
        propertyId:'' ,
        projectId:'',
      };
      const currentPath = this.route.snapshot.firstChild?.routeConfig?.path;
      if (currentPath === 'property-details') {
        payload.propertyId = id;
        console.log("Property ID assigned:", payload.propertyId);
      } else if (currentPath === 'topProject') {
        payload.projectId = id;
        console.log("Project ID assigned:", payload.projectId);
      } else {
        console.log("No valid route found for ID assignment");
      }

      console.log("Final payload before submission:", payload);
      this.homeservice.ownwerdetail(payload).subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            Swal.fire({
              icon: 'success',
              title: 'Thanks For Submitting',
              showConfirmButton: false,
              timer: environmentbasic.timer
            });
            this.dialogRef.close();
          } else {
            Swal.fire({
              icon: 'error',
              title: response['message'],
              showConfirmButton: false,
              timer: environmentbasic.timer
            });
          }
        },
        (error) => {
          console.error("Error occurred while submitting data:", error); // Log any error
          Swal.fire({
            icon: 'error',
            title: 'Submission Failed',
            text: error.message || 'An error occurred while submitting your data.',
            showConfirmButton: true
          });
        }
      );

    }
    else {
      console.log("Form is not valid");
      Swal.fire({
        icon: 'warning',
        title: 'Form Validation Failed',
        text: 'Please fill out all required fields.',
        showConfirmButton: true
      });
    }
  }






}
