<div class="wrappers1">
  <app-header></app-header>

<div class="content">

    <app-menu></app-menu>
    <div class="row col-md-12 p-2 justify-content-center">
      <div class="col-md-7">
        <div class="mt-5">
          <!-- <div class="firstshift">Welcome Back! Let’s Continue Your Search</div> -->
          <div class="firstshift">Properties to buy in {{showcityname}}</div><br>
          <div class="containerthiss">
            <div><strong> 1K+ </strong> <span style="color:gray ;">listings added daily and all verified.</span></div>
        </div>

        </div>
        <div class="mt-3">
          <mat-tab-group class="custom-tabs" (selectedTabChange)="onTabChange($event)">
            <mat-tab *ngFor="let tab of tabs" [label]="tab.name">
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="mt-3">
          <div class="container row col-12">
            <div class="custom-search">
              <div class="row align-items-center">

                <div class="col-md-2 d-flex justify-content-start position-relative">
                  <div class="styled-select-container position-relative">
                    <input type="text" class="form-control city-input"  style="cursor: pointer;" [(ngModel)]="searchCityQuery"
                      (keyup)="searchCity(searchCityQuery)" (click)="onCityInputClick()"
                      (focus)="showCitySuggestions = true"  (blur)="hideCitySuggestions();"
                      [placeholder]="selectedCityName ? selectedCityName : 'Search City...'" #cityInput/>
                      <!-- <span class="dropdown-icon" (click)="toggleDropdown()">
                        <i class="fas" [ngClass]="{'fa-chevron-down': !showCitySuggestions, 'fa-chevron-up': showCitySuggestions}" (keyup)="searchCity(searchCityQuery)" (click)="onCityInputClick()"
                        (focus)="showCitySuggestions = true"  (blur)="hideCitySuggestions();" (click)="onCityInputClick()"></i>
                      </span> -->

                      <span class="dropdown-icon">
                        <i class="fas fa-chevron-down"
                           *ngIf="!showCitySuggestions"
                           (click)="showDropdown(cityInput)"
                           >

                        </i>

                        <!-- Icon to HIDE suggestions -->
                        <i class="fas fa-chevron-up"
                           *ngIf="showCitySuggestions"
                           (click)="hideDropdown()">
                        </i>
                      </span>



                    <ul *ngIf="showCitySuggestions && cityDataList.length > 0" class="suggestion-dropdownss">
                      <li
                        *ngFor="let city of cityDataList"
                        (mousedown)="selectCity(city)"
                        [class.selected]="city.id === selectedCityId">
                        {{ city.name }}
                      </li>
                    </ul>
                  </div>
                </div>





                <div class="col-md-8 d-flex justify-content-start position-relative">
                  <input type="text" #searchkey class="form-control search-input" [placeholder]="placeholderText"
                    [(ngModel)]="searchQuery" (keyup)="onclickSearch()" (click)="onclickSearch()"
                    (blur)="hideSuggestions()" />
                  <div class="suggestion-container">
                    <ul *ngIf="showSuggestions && areaList?.length > 0" class="suggestion-dropdown">
                      <li *ngFor="let area of areaList" (mousedown)="selectArea(area)" (input)="isAreaSelected = false">
                        {{ area.area | titlecase }}, {{ area.cityName | titlecase }}
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <div class="col-2 d-flex justify-content-end">
                  <button class="btn btn-primary11 onlyhover" #searchkey (click)="searchArt() "  [disabled]="!isAreaSelected">Search</button>
                </div> -->

                <div class="col-2 d-flex justify-content-end">
                  <!-- Show button on larger screens -->
                  <button class="btn btn-primary11 onlyhover d-none d-md-block" #searchkey (click)="searchArt()"
                  [disabled]="!isAreaSelected">
                    Search
                  </button>

                  <!-- Show search icon on mobile screens -->
                  <span class="d-md-none" (click)="searchArt()" >
                    <i class="fas fa-search search-icon"></i>
                  </span>
                </div>

                <div *ngIf="isRequiredMessageVisible" class="error-message">
                  Please Try Again With The Location Included!
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>




  </div>
  <div class="col-md-12 " style="margin-top: 6pc; z-index: 0;">

    <div class="col-md-10 mx-auto">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span class="topproject">Top Property</span>
        <button (click)="navigateTOhome()" class="btn btn-primary viewall">View All</button>
      </div>
      <!-- <div class="animation">
        <span class="toppro">Fresh Property Listings Available Here {{showcityname}}</span>
        <span class="toppro">New Homes for Sale</span>
        <span class="toppro">Exclusive Property Deals</span>
    </div> -->
    <div class="animation">
      <span class="toppro">Fresh Property Listings Available Here in <strong>{{showcityname}}</strong></span>
      <span class="toppro">New Homes for Sale in <strong>{{showcityname}}</strong></span>
      <span class="toppro">Exclusive Property Deals in <strong>{{showcityname}}</strong></span>
  </div>


    </div>

    <div class="swiper-container" style="display: flex; width: auto !important; cursor: pointer;">
      <swiper #swiperRef (slideChange)="onSlideChange($event)" [slidesPerView]="1" [spaceBetween]="15" [navigation]="{ nextEl: '.custom-next-button1',
        prevEl: '.custom-prev-button1' }" [breakpoints]="{
            '768': { 'slidesPerView': 3 }
          }" style="width: 82%;">
        <ng-template swiperSlide *ngFor="let cards of tophome">
          <app-home-top-property (click)="navigateTofullDetail(cards.id)" [cardData]="cards">
          </app-home-top-property>
        </ng-template>
      </swiper>

      <div class="custom-prev-button1" (click)="onPrevClick(0)" [ngClass]="{'hide': !showPrevButton}">
        <mat-icon [ngClass]="{'active': activeButtons[0], 'inactive': !activeButtons[0]}">
          arrow_back
        </mat-icon>
      </div>
      <div class="custom-next-button1" (click)="onNextClick(0)"  [ngClass]="{'hide': tophome.length <= 3}">
        <mat-icon [ngClass]="{'active': activeButtons[0], 'inactive': !activeButtons[0]}">
          arrow_forward
        </mat-icon>
      </div>

    </div>

  </div>

  <div class="col-md-12 " style="margin-top: 3pc; z-index: 0;">



    <div class="col-md-10 mx-auto">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span class="topproject">Top Projects</span>

        <button (click)="navigateTOListing()" class="btn btn-primary viewall">View All</button>
      </div>
      <div class="animation">

        <span class="toppro">Best Developers In <strong> {{showcityname}}</strong>  To Explore</span>
        <span class="toppro"> Top Programmers in <strong> {{showcityname}}</strong>  to Watch Out For</span>
        <span class="toppro"> <strong>{{showcityname}}</strong>  Best Tech Developers to Explore</span>
      </div>
      <!-- </div> -->


    </div>
    <div class="swiper-container" style="display: flex; width: auto !important; cursor: pointer;">
      <swiper #swiperRef (slideChange)="onSlideChange($event)" [slidesPerView]="1" [spaceBetween]="15" [navigation]="{ nextEl: '.custom-next-button1',
        prevEl: '.custom-prev-button1' }" [breakpoints]="{
            '768': { 'slidesPerView': 3 }
          }" style="width: 82%;">
        <ng-template swiperSlide *ngFor="let card of topList">
          <!-- <app-top-property-card (click)="navigateTofullDetail()"
      [cardData]="card"
      [showEditDetailsButton]="false"
      [showViewDetailsButton]="false"
      [showInventoryButton]="false"
      [showEditInventoryButton]="false">
    </app-top-property-card>  -->
          <app-top-property-card (click)="navigateTofullDetail(card.id)" [cardData]="card"
            [showEditDetailsButton]="false" [showViewDetailsButton]="false" [showInventoryButton]="false"
            [showEditInventoryButton]="false" [showdetails]="false" [showdsize]='false'>
          </app-top-property-card>

        </ng-template>
      </swiper>

      <div class="custom-prev-button1" (click)="onPrevClick(0)" [ngClass]="{'hide': !showPrevButton}">
        <mat-icon [ngClass]="{'active': activeButtons[0], 'inactive': !activeButtons[0]}">
          arrow_back
        </mat-icon>
      </div>
      <div class="custom-next-button1" (click)="onNextClick(0)" [ngClass]="{'hide': topList.length <= 3}">
        <mat-icon [ngClass]="{'active': activeButtons[0], 'inactive': !activeButtons[0]}">
          arrow_forward
        </mat-icon>
      </div>

    </div>

  </div>

  <div class="col-md-12" style="margin-top: 3pc;">



    <div class="col-md-10 mx-auto">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span class="topproject">Recently Added</span>
        <button (click)="navigateTOListing1()" class="btn btn-primary viewall">View All</button>
      </div>
      <div class="animation">
        <span class="toppro">Based On Preferences Of Users Like You in <strong>{{showcityname}}</strong></span>
        <span class="toppro">Chosen By Users With Similar Interests in <strong>{{showcityname}}</strong></span>
        <span class="toppro">Tailored To Match Your Preferences in <strong>{{showcityname}}</strong></span>
      </div>

    </div>

    <div class="swiper-container" style="display: flex; width: auto !important; cursor: pointer; position: relative;">
      <swiper #swiperRef2 [slidesPerView]="1" [spaceBetween]="10"
        [navigation]="{ nextEl: '.custom-next-button2', prevEl: '.custom-prev-button2' }"
        (slideChange)="onSlideChange2($event)" [breakpoints]="{
        '768': { 'slidesPerView': 4 }
      }" style="width: 82%;">
        <ng-template swiperSlide *ngFor="let card of recentList.slice(0,4)">
          <app-top-property-card-small (click)="navigateTofullDetail(card.id)" [cardData]="card" [showsizeonly]="false">
          </app-top-property-card-small>

        </ng-template>
      </swiper>


    </div><br>
    <div class="swiper-container" style="display: flex; width: auto !important; cursor: pointer; position: relative;">
      <swiper #swiperRef2 [slidesPerView]="1" [spaceBetween]="10"
        [navigation]="{ nextEl: '.custom-next-button2', prevEl: '.custom-prev-button2' }"
        (slideChange)="onSlideChange2($event)" [breakpoints]="{
        '768': { 'slidesPerView': 4 }
      }" style="width: 82%;">
        <ng-template swiperSlide *ngFor="let card of recentList.slice(4)">
          <app-top-property-card-small (click)="navigateTofullDetail(card.id)" [cardData]="card" [showsizeonly]="false">
          </app-top-property-card-small>

        </ng-template>
      </swiper>

      <div class="custom-prev-button2" (click)="onPrevClick(1)" [ngClass]="{'hide': !showPrevButton2}">
        <mat-icon class="nav-icon">
          arrow_back
        </mat-icon>
      </div>
      <div class="custom-next-button2" (click)="onNextClick(1)" [ngClass]="{'hide': recentList.length <= 8}">
        <mat-icon class="nav-icon">
          arrow_forward
        </mat-icon>
      </div>
    </div>

  </div>

  <div class="col-md-12" style="margin-top: 3pc;">

    <div class="col-md-10 mx-auto">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span class="topproject">Trending Projects</span>
        <button (click)="navigateTOListing2()" class="btn btn-primary viewall">View All</button>
      </div>
      <div class="animation">
        <span class="toppro">Most Sought-after Projects In <strong>{{showcityname}}</strong></span>
        <span class="toppro">Top Trending Projects Across <strong>{{showcityname}}</strong></span>
        <span class="toppro">Highly Acclaimed Projects In <strong>{{showcityname}}</strong></span>

      </div>


    </div>



    <!-- <div class="col-md-11">
      <div class="d-flex justify-content-between justify-content-center">
        <span class="topproject mb-2">Trending Projects</span>
        <button (click)="navigateTOListing2()" class="btn btn-primary viewall" >View All</button>
      </div>

      <span class="toppro">Most Sought-after Projects In India</span>
    </div> -->

    <div class="swiper-container" style="display: flex; width: auto !important; cursor: pointer;">
      <swiper #swiperRef3 [slidesPerView]="1" [spaceBetween]="10"
        [navigation]="{ nextEl: '.custom-next-button3', prevEl: '.custom-prev-button3' }"
        (slideChange)="onSlideChange3($event)" [breakpoints]="{
                '768': { 'slidesPerView': 3 }
              }" style="width: 82%;">
        <ng-template swiperSlide *ngFor="let card of trendingList">
          <app-top-property-card-semi-small (click)="navigateTofullDetail(card.id)"
            [cardData]="card"></app-top-property-card-semi-small>
        </ng-template>
      </swiper>

      <div class="custom-prev-button3" (click)="onPrevClick(2)" [ngClass]="{'hide': !showPrevButton3}">
        <mat-icon [ngClass]="{'active': activeButtons[2], 'inactive': !activeButtons[2]}">
          arrow_back
        </mat-icon>
      </div>
      <div class="custom-next-button3" (click)="onNextClick(2)" [ngClass]="{'hide': trendingList.length <= 3}">
        <mat-icon [ngClass]="{'active': activeButtons[2], 'inactive': !activeButtons[2]}">
          arrow_forward
        </mat-icon>
      </div>
    </div>
  </div>


  <div *ngIf="userinfo else notlogin1" class="mt-3 mb-1 mr-2" style="cursor: pointer;">
    <div class="col-md-11 d-flex justify-content-end justify-content-center-mobile" >
      <mat-card class="custom-card onlyhover" appearance="outlined">
        <mat-card-content>
          <div class="lastseen" (click)="postproperty()">
            <i class="fa fa-home" aria-hidden="true"></i>
            Post Your Property For Free
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <ng-template #notlogin1>
    <div class="col-md-11 d-flex justify-content-end justify-content-center-mobile"
      style="cursor: pointer;">
      <mat-card class="custom-card onlyhover" appearance="outlined">
        <mat-card-content>
          <div class="lastseen" (click)="postpropertynotlogin()">
            <i class="fa fa-home" aria-hidden="true"></i>
            Post Your Property For Free
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-template>
  <!-- <div class="content mt-4 mb-4">
    <img src="../../assets/images/Frame 23.png" alt="footer" class="Homefooter">
  </div> -->
  <!-- <div class="row justify-content-center mt-4 paddingthis">
    <div class="col-md-10 custom-container">
        <div class="row">
            <div class="col-md-8 col-12">
                <p>Column 8 Content</p>
            </div>
            <div class="col-md-4 col-12">
                <p>Column 4 Content</p>
            </div>
        </div>
    </div>
</div> -->

<div class="row justify-content-center mt-4 custom-spacing">
  <div class="col-md-10 main-box">
      <div class="row">
          <div class="col-md-8 col-12">
              <div class="box-card">
                  <div class="Downset">Download G3G Mobile App</div><br>
                  <div class="Downset1">and never miss out any update</div><br><br>
                  <div class="Downset1"><i class="fa fa-bell icon-bell"></i> Get notified instantly when new properties are listed.</div><br>
                  <div class="Downset1"><i class="fa fa-home icon-home"></i> Easily manage your properties and receive real-time alerts responses.</div><br><br>
                  <div class="nav-list d-flex">
                    <span>
                      <a href="#" target="_blank">
                        <img src="../../assets/images/google-play-6647242_1280.webp" class="playlogo">
                      </a>
                      <!-- <a href="https://play.google.com/store/apps/details?id=in.co.ksquaretech.propease&pcampaignid=web_share" target="_blank">
                        <img src="../../assets/images/google-play-6647242_1280.webp" class="playlogo">
                      </a> -->

                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>
                      <a href="#" target="_blank">
                        <img src="../../assets/images/apple.png" class="applelogo">
                      </a>
                      <!-- <a href="https://apps.apple.com/us/app/propease-app/id6740812565" target="_blank">
                        <img src="../../assets/images/apple.png" class="applelogo">
                      </a> -->
                    </span>
                  </div>
              </div>
          </div>
          <div class="col-md-4 col-12 d-flex align-items-end">
            <img src="../../assets/images/Frame 31 1.png" alt="footer" class="Homefooter img-fluid">
        </div>

      </div>
  </div>
</div>







  <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
    ⬆
  </div>

  <br>
  <app-footer></app-footer>
</div>
