<div *ngIf="listing; else showCard">
  <div class="card">
    <div class="carousel-container" (click)="navigateToDetail(listing.id)">
      <ng-container *ngIf="Images.length > 0; else noImage">
        <app-image-slide [slides]="Images"></app-image-slide>
      </ng-container>
      <ng-template #noImage>
        <app-image-slide [slides]="['../../assets/images/defaultproperty.png']"></app-image-slide>
      </ng-template>
    </div>

    <div class="card-body">
      <div class="listing-header">
        <h5 (click)="navigateToDetail(listing.id)">
          {{ listing.title | capitalize }}
        </h5>
      </div>

      <div class="listing-subheader">
        <h3>
          <span class="font1">{{ listing.noOfBadroom }} Bedroom House in&nbsp;</span>
          <span class="font1" style="color: rgb(103, 89, 89);">
            {{ listing.area }},{{ listing.cityName }}
          </span>
        </h3>
      </div>

      <div class="listing-details">
        <div class="price">
          <span class="unit-value">₹{{ listing?.asking | formatNumber: listing?.feature?.askingUnit }}</span>
          <p class="unit-per-unit">
            ₹{{ (askingValue / listing.feature?.size) | number:'1.0-0' }}/{{ listing?.feature?.sizeUnit }}
          </p>
        </div>

        <div class="area">
          <span class="unit-value">{{ listing?.feature?.size }} {{ listing?.feature?.sizeUnit }}</span>
          <p class="unit-per-unit">Plot Area</p>
        </div>

        <div class="bhk-status">
          <span class="unit-value">{{ listing.noOfBadroom }} BHK ({{ listing?.feature?.noOfBathroom }} Baths)</span>
          <p>Ready to Move</p>
        </div>
      </div>

      <div class="listing-description">
        <span class="description-text" [innerHTML]="listing.description | safeHtml"></span>
      </div>

      <div class="listing-footer">
        <div class="footer-info">
          <span>{{ listing.createdAt | timeAgo }}</span><br>
          <span class="owner">Owner</span>
        </div>

        <div class="footer-info">
          <span>{{ listing.type }}</span><br>
        </div>

        <div *ngIf="editButton" class="footer-btn">
          <button class="btn btn-primary" (click)="editDetail(listing.id)">
            <i class="fa fa-edit" aria-hidden="true"></i>&nbsp; Edit Details
          </button>
        </div>

        <div class="footer-btn">
          <button class="btn btn-primary" (click)="navigateToDetail(listing.id)">
            <i class="fa fa-star" aria-hidden="true"></i>&nbsp; View Details
          </button>
        </div>

        <div class="footer-btn">
          <button class="btn btn-primary" (click)="openShareModal(listing)">
            <i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp; Share
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- ELSE: Show Card Data -->
<ng-template #showCard>
  <div  *ngIf="card; else showCards"class="card">
    <div class="carousel-container" (click)="viewDetails($event)">
      <ng-container *ngIf="cardImages.length > 0; else noCardImage">
        <app-image-slide [slides]="cardImages"></app-image-slide>
      </ng-container>
      <ng-template #noCardImage>
        <app-image-slide [slides]="['../../assets/images/defaultproperty.png']"></app-image-slide>
      </ng-template>
    </div>

    <div class="card-body">
      <!-- <div class="listing-header">
        <h5 (click)="navigateToDetail(card.id)">
          {{ card.title | capitalize }}

        </h5>
      </div> -->
      <div class="listing-details">
        <div class="price" (click)="viewDetails($event)">
          <span class="unit-value"> {{ card.title | capitalize }}</span>
        </div>

        <button class="inventory-btn" (click)="handleInventoryClick($event)">
          <span class="arrow"></span> Inventory ({{ card.totalInventory }})
        </button>


      </div>
      <div class="listing-subheader">
        <h3>
          <span class="font1" style="color: rgb(103, 89, 89);">
            {{ card.location }},{{ card.cityName }}
          </span>
        </h3>
      </div>

      <div class="listing-details">
        <div class="price">
          <span class="unit-value">₹{{ card?.fromPrice | formatNumber: card?.fromPriceUnit }}-{{ card?.toPrice | formatNumber: card?.toPriceUnit }}</span>
          <p class="unit-per-unit">FromPrice - ToPrice</p>
        </div>

        <div class="area">
          <span class="unit-value">{{ card?.size }} {{ card?.sizeUnit }}</span>
          <p class="unit-per-unit">Project Size</p>
        </div>

        <div class="bhk-status">
          <span class="unit-value">{{ card?.inventoryType | capitalizeFirstLetter }}</span>
          <p class="unit-per-unit">Project Type</p>
        </div>
      </div>

      <div class="listing-description">
        <span class="description-text" [innerHTML]="card.description | safeHtml"></span>
      </div>

      <div class="listing-footer">
        <div class="footer-info">
          <span>{{ card.createdAt | timeAgo }}</span><br>
          <span class="owner">Owner</span>
        </div>

        <div class="footer-info">
          <span>{{ card.type }}</span><br>
        </div>

        <div *ngIf="editButton" class="footer-btn">
          <button class="btn btn-primary" (click)="editDetails($event)">
            <i class="fa fa-edit" aria-hidden="true"></i>&nbsp; Edit Details
          </button>
        </div>

        <div class="footer-btn">
          <button class="btn btn-primary" (click)="viewDetails($event)">
            <i class="fa fa-star" aria-hidden="true"></i>&nbsp; View Details
          </button>
        </div>

        <div class="footer-btn">
          <button *ngIf="isMyProjectsPage(); else showInventory" class="btn btn-primary" (click)="myinventory($event)">
            <i class="fa fa-home-alt" aria-hidden="true"></i>&nbsp;My Inventory
          </button>

          <ng-template #showInventory>
            <button class="btn btn-primary" (click)="inventory($event)">
              <i class="fa fa-home-alt" aria-hidden="true"></i>&nbsp;Inventory
            </button>
          </ng-template>
        </div>


      </div>
    </div>
  </div>
  <ng-template #showCards>
    <div *ngIf="cardinventory;" class="card">
      <div class="carousel-container" (click)="inventorydetails($event)">
        <ng-container *ngIf="cardImagesinventory.length > 0; else noCardImage">
          <app-image-slide [slides]="cardImagesinventory"></app-image-slide>
        </ng-container>
        <ng-template #noCardImage>
          <app-image-slide [slides]="['../../assets/images/defaultproperty.png']"></app-image-slide>
        </ng-template>
      </div>

      <div class="card-body">
        <!-- <div class="listing-details">
          <div class="price" (click)="viewDetails($event)">
            <span class="unit-value"> {{ cardData.title | capitalize }}</span>
          </div>
        </div> -->

        <div class="listing-details">
          <div class="price" (click)="inventorydetails($event)">
            <span class="unit-value"> {{ cardinventory.title | capitalize }}</span>
          </div>




        </div>
        <div class="listing-subheader">
          <h3>
            <span class="font1" style="color: rgb(103, 89, 89);">
              {{ cardinventory?.projectFeature?.location }}
            </span>
          </h3>
        </div>

        <div class="listing-details">
          <div class="price">
            <span class="unit-value">₹{{ cardinventory?.aaskingPrice | formatNumber: cardinventory?.aaskingPriceUnit }}</span>
            <p class="unit-per-unit">Inventorty Price</p>
          </div>

          <div class="area">
            <span class="unit-value">{{ cardinventory?.totalSize }} {{ cardinventory?.sizeUnit }}</span>
            <p class="unit-per-unit">Inventory Size</p>
          </div>

          <div class="bhk-status">
            <span class="unit-value">{{ cardinventory?.inventoryType | capitalizeFirstLetter }}</span>
            <p class="unit-per-unit">Inventorty Type</p>
          </div>
        </div>

        <div class="listing-description">
          <span class="description-text" [innerHTML]="cardinventory?.description | safeHtml"></span>
        </div>

      <div class="listing-footer">
        <div class="footer-info">
          <span>{{ cardinventory?.createdAt | timeAgo }}</span><br>
          <span class="owner">Owner</span>
        </div>

        <div class="footer-info">
          <span>{{ cardinventory?.type }}</span><br>
        </div>
        <div class="footer-btn" *ngIf="showButton">
          <button class="btn btn-primary" (click)="editInventorydetaisl($event)">
            <i class="fa fa-edit" aria-hidden="true"></i>&nbsp; Edit Inventory
          </button>
        </div>

        <div class="footer-btn">
          <button class="btn btn-primary"(click)="inventorydetails($event)">
            <i class="fa fa-star" aria-hidden="true"></i>&nbsp; View Details
          </button>
        </div>
      </div>
    </div></div>
  </ng-template>


</ng-template>


