
<div class="page-container">
  <app-header></app-header>
  <app-menu></app-menu><br>
  <div class="p-3" style="margin-top: 3pc;" class="borderaroud">
    <mat-stepper  [linear]="isLinear" (selectionChange)="onStepChange($event)" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup" (ngSubmit)="onSubmit()">
          <ng-template matStepLabel>Property Details</ng-template>
          <div fxLayout="row wrap" fxLayoutGap="16px">
            <div class="row col-md-12">
              <div class="custom-form-field4">
            <mat-form-field >
              <mat-label>Property Title</mat-label>
              <input matInput placeholder="" formControlName="title" required>
              <mat-error *ngIf="firstFormGroup.controls['title'].hasError('required')">Property Title is required</mat-error>
            </mat-form-field>
              </div></div><br>
              <!-- <div class="row col-md-12">
                <mat-form-field class="custom-form-field4" appearance="outline">
                  <mat-label>Description </mat-label>
                  <textarea
                    matInput
                    formControlName="description"
                    (focus)="isFocused = true"
                    (blur)="isFocused = false"
                  ></textarea>
                  <mat-error *ngIf="firstFormGroup.controls['description'].hasError('required')">This field is required</mat-error>
                </mat-form-field>
              </div> -->



              <!-- <div class="row col-md-12">
                <div class="custom-form-field4">
                  <mat-form-field appearance="outline" class="full-width custom-textarea">
                    <mat-label>Description </mat-label>
                    <textarea
                      matInput
                      formControlName="description"
                      (focus)="isFocused = true"
                      (blur)="isFocused = false"
                    ></textarea>
                    <mat-error *ngIf="firstFormGroup.controls['description'].hasError('required')">This field is required</mat-error>
                  </mat-form-field>
                </div>
              </div> -->



              <div class="row col-md-12">
                <div class="custom-form-field4">
                  <label
                    for="middleName"
                    class="post-headings"
                    [ngStyle]="{
                      color:
                        description.invalid && (description.dirty || description.touched)
                          ? '#f44336'
                          : isFocused
                          ? '#004aad'
                          : 'rgb(136, 110, 110)'
                    }"
                    style="font-family: 'Roboto', sans-serif;"
                  >
                    Description *
                  </label>
                  <textarea
                    formControlName="description"
                    class="description-textarea"
                    (focus)="isFocused = true"
                    (blur)="isFocused = false"
                    [ngClass]="{ 'border-error': description.invalid && (description.dirty || description.touched) }"
                  ></textarea>
                  <div *ngIf="description.invalid && (description.dirty || description.touched)" class="text-dangers">
                    Description is required.
                  </div>
                </div>
              </div>






            <br>
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Type</mat-label>
              <mat-select formControlName="type" required>
                <mat-option>None</mat-option>
                <mat-option value="Residential">Residential</mat-option>
                <mat-option value="Commercial">Commercial</mat-option>
                <mat-option value="Flat/Apartment">Flat/Apartment</mat-option>
                <mat-option value="Independent House/Villa">Independent House/Villa</mat-option>
                <mat-option value="Service Apartment">Service Apartment</mat-option>
                <mat-option value="Farm House">Farm House</mat-option>
                <mat-option value="Agricultural Land">Agricultural Land</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['type'].hasError('required')">Type is required</mat-error>

            </mat-form-field>
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)" *ngIf="Propertyid">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option value="fresh">Fresh</mat-option>
                <mat-option value="sold">Sold</mat-option>
                <mat-option value="active">Active</mat-option>
                <mat-option value="inactive">Inactive</mat-option>
                <mat-option value="draft">Draft</mat-option>

              </mat-select>
            </mat-form-field>
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Address</mat-label>
              <input matInput placeholder="" formControlName="address" required>
              <mat-error *ngIf="firstFormGroup.controls['address'].hasError('required')">Address is required</mat-error>

            </mat-form-field>
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Pin Code</mat-label>
              <input matInput placeholder="" formControlName="pinCode"  pattern="[0-9]*"
                (keypress)="validateNumberInput($event)">
            </mat-form-field>
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Area</mat-label>
              <input matInput placeholder="" formControlName="area" required>
              <mat-error *ngIf="firstFormGroup.controls['area'].hasError('required')">Area is required</mat-error>

            </mat-form-field>
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>City</mat-label>
              <input
                type="text"
                placeholder="Search City"
                (keyup)="onCitySearch($event)"
                aria-label="text"
                matInput
                formControlName="cityName"
                [matAutocomplete]="auto"
                required

                >
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCityChange($event)" [displayWith]="displayCityName">
                  <mat-option *ngFor="let city of cityDataList" [value]="city">
                    {{ city.name }}
                  </mat-option>
                </mat-autocomplete>



              <mat-error *ngIf="firstFormGroup.controls['cityName'].hasError('required')">
                City is required
              </mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>State</mat-label>
              <mat-select formControlName="stateId" required>
                <mat-option *ngIf="stateId" [value]="stateId">
                  {{ stateName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['stateId'].hasError('required')">State is required</mat-error>

            </mat-form-field>
          </div>
          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="handleCancel()">Cancel</button>
            <button mat-button class="custom-button font1 onlyhover" type="submit" style="color:white">Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" label="Property Features">
        <form [formGroup]="secondFormGroup">
          <mat-form-field class="custom-form-field">
            <mat-label>Number of Bedrooms</mat-label>
            <mat-select disableRipple formControlName="noOfBadroom" required>
              <mat-option>None</mat-option>
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">5+</mat-option>
            </mat-select>
            <mat-error *ngIf="secondFormGroup.controls['noOfBadroom'].hasError('required')">Number of Bedrooms is required</mat-error>

          </mat-form-field>
          <mat-form-field class="custom-form-field">
            <mat-label>Number of Bathrooms</mat-label>
            <mat-select disableRipple formControlName="noOfBathroom" required>
              <mat-option>None</mat-option>
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">5+</mat-option>
            </mat-select>
            <mat-error *ngIf="secondFormGroup.controls['noOfBathroom'].hasError('required')">Number of Bathrooms is required</mat-error>

          </mat-form-field>
          <mat-form-field class="custom-form-field">
            <mat-label>Number of Balconies</mat-label>
            <mat-select disableRipple formControlName="noOfBalconies" required>
              <mat-option>None</mat-option>
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">5+</mat-option>
            </mat-select>
            <mat-error *ngIf="secondFormGroup.controls['noOfBalconies'].hasError('required')">Number of Balconies is required</mat-error>
          </mat-form-field>
          <mat-form-field class="custom-form-field">
            <mat-label>Total Floors</mat-label>
            <mat-select disableRipple formControlName="totalFloors" required>
              <mat-option>None</mat-option>
              <mat-option value="Basement">Basement</mat-option>
              <mat-option value="Ground">Ground</mat-option>
              <mat-option value="Lower Ground">Lower Ground</mat-option>
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
              <mat-option value="7">7</mat-option>
              <mat-option value="8">8</mat-option>
              <mat-option value="9">9</mat-option>
              <mat-option value="10">10</mat-option>
              <mat-option value="11">11</mat-option>
              <mat-option value="12">12</mat-option>
              <mat-option value="13">13</mat-option>
              <mat-option value="14">14</mat-option>
              <mat-option value="15">15</mat-option>
              <mat-option value="16">16</mat-option>
              <mat-option value="17">17</mat-option>
              <mat-option value="18">18</mat-option>
              <mat-option value="19">19</mat-option>
              <mat-option value="20">20</mat-option>
              <mat-option value="20+">20+</mat-option>
            </mat-select>
            <mat-error *ngIf="secondFormGroup.controls['totalFloors'].hasError('required')">Total Floors is required</mat-error>
          </mat-form-field>
          <mat-form-field class="custom-form-field">
            <mat-label>Over Looking</mat-label>
            <mat-select disableRipple formControlName="overLooking" required>
              <mat-option>None</mat-option>
              <mat-option value="Main Road">Main Road</mat-option>
              <mat-option value="Park/Garden">Park/Garden</mat-option>
              <mat-option value="Hospital">Hospital</mat-option>
              <mat-option value="Mall">Mall</mat-option>
              <mat-option value="School">School</mat-option>
              <mat-option value="Railway Station">Railway Station</mat-option>
            </mat-select>
            <mat-error *ngIf="secondFormGroup.controls['overLooking'].hasError('required')">Over Looking is required</mat-error>
          </mat-form-field>
          <mat-form-field class="custom-form-field">
            <mat-label>Property Age</mat-label>
            <mat-select disableRipple formControlName="propertyAge" required>
              <mat-option>None</mat-option>
              <mat-option value="0-1 years">0-1 years</mat-option>
              <mat-option value="1-5 years">1-5 years</mat-option>
              <mat-option value="5-10 years">5-10 years</mat-option>
              <mat-option value="10+ years">10+ years</mat-option>
            </mat-select>
            <mat-error *ngIf="secondFormGroup.controls['propertyAge'].hasError('required')">Property Age is required</mat-error>
          </mat-form-field>
          <mat-form-field class="custom-form-field3">
            <mat-label>Place Nearby</mat-label>
            <input matInput placeholder="" formControlName="placesNearby" required>
            <mat-error *ngIf="secondFormGroup.controls['placesNearby'].hasError('required')">Place Nearby is required</mat-error>

          </mat-form-field>
          <mat-form-field class="custom-form-field3">
            <mat-label>Property Ownership</mat-label>
            <mat-select disableRipple formControlName="propertyOwnership" required>
              <mat-option>None</mat-option>
              <mat-option value="Freehold">Freehold</mat-option>
              <mat-option value="Leashold">Leashold</mat-option>
              <mat-option value="Co-operative society">Co-operative society</mat-option>
              <mat-option value="Power of Attorney">Power of Attorney</mat-option>

            </mat-select>
            <mat-error *ngIf="secondFormGroup.controls['propertyOwnership'].hasError('required')">Property Ownership is required</mat-error>

          </mat-form-field>



            <!-- <div class="row p-2" > -->
              <mat-form-field class="custom-form-field3">
                <mat-label>Furnishing</mat-label>
                <mat-select disableRipple formControlName="furnishing" required>
                  <mat-option>None</mat-option>
                  <mat-option value="Furnished">Furnished</mat-option>
                  <mat-option value="Semi-Furnished">Semi-Furnished</mat-option>
                  <mat-option value="Un-Furnished">Un-Furnished</mat-option>
                </mat-select>
            <mat-error *ngIf="secondFormGroup.controls['furnishing'].hasError('required')">Furnishing is required</mat-error>


              </mat-form-field>
              <!-- <div class="col-sm-4" style="margin-top: 8px;">
                <div class="row">
                  <div class="col-sm-8 mobile" >
                    <mat-form-field >
                      <mat-label>Width Of Facing Road</mat-label>
                      <input matInput placeholder="" formControlName="widthOfFacingRoad"  (input)="onInput($event)"
                        type="text"  >
                     <mat-error *ngIf="secondFormGroup.controls['widthOfFacingRoad'].hasError('required')">This field is required</mat-error>

                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 mobile" >
                    <mat-form-field >
                      <mat-label>Unit</mat-label>
                      <mat-select  formControlName="widthOfFacingRoadUnit" required>
                        <mat-option value="Sqm">Square Meter</mat-option>
                        <mat-option value="Sqft">Square Foot</mat-option>
                        <mat-option value="Ac">Acre</mat-option>
                        <mat-option value="Sqyd">Square Yard</mat-option>
                        <mat-option value="Killa">Killa</mat-option>
                      </mat-select>
                     <mat-error *ngIf="secondFormGroup.controls['widthOfFacingRoadUnit'].hasError('required')">This field is required</mat-error>

                    </mat-form-field>
                  </div>
                </div>
              </div> -->
              <!-- <mat-form-field class="custom-form-field3">
                <mat-label>Water Source</mat-label>
                <mat-select disableRipple formControlName="waterSource" required>
                  <mat-option>None</mat-option>
                  <mat-option value="Municipal corporation,24*7 Water">Municipal corporation,24*7 Water</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['waterSource'].hasError('required')">This field is required</mat-error>

              </mat-form-field> -->
              <!-- </div> -->

          <mat-form-field class="custom-form-field3">
            <mat-label>Power Backup</mat-label>
            <mat-select disableRipple formControlName="powerBackup">
              <mat-option>None</mat-option>
              <mat-option value="Generator">Generator</mat-option>
              <mat-option value="Solar">Solar</mat-option>
              <mat-option value="Inverter">Inverter</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="secondFormGroup.controls['powerBackup'].hasError('required')">This field is required</mat-error> -->

          </mat-form-field>
          <!-- <mat-form-field class="custom-form-field">
            <mat-label>Property Code</mat-label>
            <input matInput placeholder="" formControlName="propertyCode">
          </mat-form-field> -->
          <mat-form-field class="custom-form-field3">
            <mat-label>Direction</mat-label>
            <mat-select disableRipple formControlName="direction">
              <mat-option>None</mat-option>
              <mat-option value="East-Facing">East-Facing</mat-option>
              <mat-option value="West-Facing">West-Facing</mat-option>
              <mat-option value="North-Facing">North-Facing</mat-option>
              <mat-option value="South-Facing">South-Facing</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="secondFormGroup.controls['direction'].hasError('required')">This field is required</mat-error> -->

          </mat-form-field>
          <mat-form-field class="custom-form-field3">
            <mat-label>Loan Availble</mat-label>
            <mat-select disableRipple formControlName="loanAvailable" >
              <mat-option>None</mat-option>
              <mat-option value="Bank">Bank</mat-option>
              <mat-option value="Private">Private</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="secondFormGroup.controls['loanAvailable'].hasError('required')">This field is required</mat-error> -->
          </mat-form-field>
          <div class="row p-2" >
            <mat-form-field class="custom-form-field3">
              <mat-label>Buildup Area</mat-label>
              <mat-select disableRipple formControlName="buildupArea" required>
                <mat-option>None</mat-option>
                <mat-option value="Carpet Area">Carpet Area</mat-option>
                <mat-option value="Corridors">Corridors</mat-option>
                <mat-option value="Lobbies">Lobbies</mat-option>
              </mat-select>
              <mat-error *ngIf="secondFormGroup.controls['buildupArea'].hasError('required')">Buildup Area is required</mat-error>

            </mat-form-field>
            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-sm-8 mobile" >
                  <mat-form-field>
                    <mat-label>Size</mat-label>
                    <input matInput placeholder="" formControlName="size"  (input)="onInput($event)"
                    type="text"  >
                    <mat-error *ngIf="secondFormGroup.controls['size'].hasError('required')">Size is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-4 mobile">


                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="sizeUnit" required>
                      <mat-option value="Sqm">Square Meter</mat-option>
                      <mat-option value="Sqft">Square Foot</mat-option>
                      <mat-option value="Ac">Acre</mat-option>
                      <mat-option value="Sqyd">Square Yard</mat-option>
                      <mat-option value="Killa">Killa</mat-option>
                    </mat-select>
                    <mat-error *ngIf="secondFormGroup.controls['sizeUnit'].hasError('required')">Unit is required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <mat-form-field class="custom-form-field3">
              <mat-label>Availibilty Status</mat-label>
              <mat-select disableRipple formControlName="construction" required>
                <mat-option>None</mat-option>
                <mat-option value="Ready To Move">Ready To Move </mat-option>
                <mat-option value="Under Construction">Under Construction</mat-option>
              </mat-select>
              <mat-error *ngIf="secondFormGroup.controls['construction'].hasError('required')">Availibilty Status is required</mat-error>

            </mat-form-field>
            </div>


          <div class="row p-2" >
            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-sm-8 mobile">
                  <mat-form-field>
                    <mat-label>Property Price</mat-label>
                    <input matInput placeholder="" formControlName="asking"  (input)="onInput($event)" maxlength="2"
                     type="text">
                    <mat-error *ngIf="secondFormGroup.controls['asking'].hasError('required')">Property Price is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-4 mobile">
                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="askingUnit" required>
                      <mat-option value="Lac">Lac</mat-option>
                      <mat-option value="Cr">Crore</mat-option>
                    </mat-select>
                    <mat-error *ngIf="secondFormGroup.controls['askingUnit'].hasError('required')">Unit is required</mat-error>
                  </mat-form-field>
                </div>

              </div>
            </div>
<!--
              <mat-form-field class="custom-form-field3">
                <mat-label>Negotiable</mat-label>
                <mat-select disableRipple formControlName="negotiable" required>
                  <mat-option>None</mat-option>
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
                <mat-error *ngIf="secondFormGroup.controls['negotiable'].hasError('required')">This field is required</mat-error>

              </mat-form-field> -->
              <!-- <mat-form-field class="custom-form-field">
                <mat-label>Loan Availble</mat-label>
                <mat-select disableRipple formControlName="loanAvailable">
                  <mat-option>None</mat-option>
                  <mat-option value="Bank">Bank</mat-option>
                  <mat-option value="Private">Private</mat-option>
                </mat-select>

              </mat-form-field> -->
            </div>
            <!-- <div class="row p-2">
              <div class="col-md-4">
              </div>
              <div class="col-md-4">
                <mat-form-field class="custom-form-field1">
                  <mat-label>Meeting Location</mat-label>
                  <input matInput placeholder="Meeting Location" formControlName="meetingPoint" required>
                <app-map-selector (locationSelected)="onLocationSelected($event)"></app-map-selector>
                <mat-error *ngIf="secondFormGroup.controls['meetingPoint'].hasError('required')">This field is required</mat-error>

                </mat-form-field>

              </div>
              <div class="col-md-4">
              </div>
            </div>       -->
          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="UpdateFeature()">Next</button>        </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup" label="Images">
        <form [formGroup]="thirdFormGroup">
          <mat-form-field class="custom-form-field1">
            <div>
              <mat-toolbar>
                <input matInput [value]="selectedFileNames.length ? selectedFileNames.join(', ') : 'Select Images'" readonly />
                <button mat-flat-button color="primary" [disabled]="!selectedFiles.length" (click)="uploadFiles()">Upload</button>
              </mat-toolbar>
              <input type="file" id="fileInput" formControlName="uploadPdf" (change)="handleChange($event)"
                name="fileInput" accept="image/*" multiple hidden />
              <button mat-stroked-button color="primary" (click)="triggerFileInput()">Select Image</button>
            </div>
          </mat-form-field>
           <div class="uploaded-images">
          <div *ngFor="let image of uploadedImages; let i = index" class="uploaded-image-container">
            <img [src]="image" alt="Uploaded Image" class="uploaded-image" />
            <button mat-icon-button  style="color:#004aad ;" (click)="removeImage(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
          <!-- <div class="button-container">
            <button mat-button matStepperPrevious class="custom-button font1 onlyhover">Back</button>
            <button mat-button matStepperNext class="custom-button font1 onlyhover" [disabled]="!uploadedImages.length"
              style="color:white;" (click)="updateImages()">Save</button>
          </div> -->
          <div class="button-container">
            <button mat-button matStepperPrevious class="custom-button font1 onlyhover">Back</button>
            <button mat-button matStepperNext class="custom-button font1 onlyhover"
              [disabled]="!uploadedImages.length"
              [ngClass]="{'disabled-button': !uploadedImages.length}"
              (click)="updateImages()">Save
            </button></div>
        </form>


        <!-- Display uploaded images -->


      </mat-step>

    </mat-stepper>
  </div>
  <app-footer></app-footer>
</div>
