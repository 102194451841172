import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageService } from '../services/error-message.service';
@Component({
  selector: 'app-myprojects',
  templateUrl: './myprojects.component.html',
  styleUrls: ['./myprojects.component.scss'],
})
export class MyprojectsComponent implements OnInit {
  IsLoading: boolean = false;
  topList: any[] = [];
  loading$: Observable<boolean>;
  filteredListings: any[] = []; // Stores all search results
  paginatedListings: any[] = []; // Stores records for current page
  searchValue: string = '';
  showTopIcon = false;
  currentPage: number = 0;
  pageSize: number = 10;
  totalPages: number = 0;
  selectedCityId: string | null = null;
  searchCityQuery: string = 'Gurgaon';
  cityDataList: any[] = [];
  allstateId: any;
  showCitySuggestions: boolean = false;
  searchQuery: any;

  constructor(private homeService: HomeService, private router: Router, private loadingService: LoadingService,   private errorMessageService: ErrorMessageService,
          private toastr:ToastrService


  ) { }


  selectedCityName: string | null = localStorage.getItem('selectedCityName');
  showDropdown(inputElement: HTMLInputElement) {
    this.onCityInputClick()
    this.showCitySuggestions = true;
    inputElement.focus();  // Ensure cursor stays in the input field
  }

  hideDropdown() {
    this.showCitySuggestions = false;
  }



  ngOnInit(): void {
    this.loading$ = this.loadingService.loading$;
    this.getProject();
    this.searchCity();
    const storedCityName = localStorage.getItem('selectedCityName');
    if (storedCityName) {
      this.searchCityQuery = storedCityName;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTopIcon = window.scrollY > 100;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  navigateToAddProject() {
    this.router.navigate(['/add-project']);
  }

  getProject(): void {
    this.loadingService.show();

    this.homeService.getMyProjects().subscribe(
      (res: any) => {
        this.loadingService.hide();

        if (res.status === 'SUCCESS' && res.payload) {
          this.topList = res.payload;
          this.searchListings(); // Apply search and pagination
        } else {
          this.topList = [];
          this.filteredListings = [];
          this.paginatedListings = [];

          const errorMessage = this.errorMessageService.getErrorMessage('No projects found');
          this.toastr.error(errorMessage, 'Error');
        }
      },
      (error) => {
        this.loadingService.hide();
        this.topList = [];
        this.filteredListings = [];
        this.paginatedListings = [];

        const message = error?.error?.message || 'Failed to fetch projects';
        const errorMessage = this.errorMessageService.getErrorMessage(message);
        this.toastr.error(errorMessage, 'Error');
      }
    );
  }


  searchListings(): void {
    const searchTerm = this.searchValue.toLowerCase().trim();

    this.filteredListings = this.topList.filter((listing) => {
      const normalizedTitle = listing.title.replace(/\s+/g, ' ').toLowerCase().trim();
      return normalizedTitle.includes(searchTerm);
    });

    this.currentPage = 0; // Reset to first page
    this.totalPages = Math.ceil(this.filteredListings.length / this.pageSize);
    this.updatePagination();
  }

  clearSearch(): void {
    this.searchValue = '';
    this.searchListings(); // Reapply filtering & pagination
  }

  onCityInputClick() {
    this.clearSearchdrop()
    if (this.cityDataList.length > 0) {
      this.showCitySuggestions = true;
    }
  }

  hideCitySuggestions() {
    setTimeout(() => {
      this.showCitySuggestions = false;
    }, 200);
  }

  searchCity(searchTerm: string = '') {
    if (this.selectedCityId) {
      this.selectedCityId = null;
      this.searchCityQuery = searchTerm;
    }

    this.homeService.searchCity(searchTerm).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.cityDataList = response.payload;
        } else {
          console.error('Failed to fetch city data:', response);
        }
      },
      (error) => {
        console.error('Error fetching city data:', error);
      }
    );
  }

  selectCity(city: any) {
    this.searchQuery = '';
    this.selectedCityId = city.id;
    this.allstateId = city.stateId;
    localStorage.setItem('allcityId', this.selectedCityId);
    localStorage.setItem('allstateId', this.allstateId);
    localStorage.setItem('selectedCityName', city.name);
    localStorage.setItem('selectedCityId', this.selectedCityId);
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    this.searchCityQuery = city.name;
    this.showCitySuggestions = false;
    // window.location.reload();
  }

  clearSearchdrop() {
    this.searchCityQuery = '';
    this.showCitySuggestions = false;
    this.searchCity();
  }

  updatePagination(): void {
    // Ensure only 9 records are shown per page while keeping `filteredListings` intact
    const startIndex = this.currentPage * this.pageSize;
    this.paginatedListings = this.filteredListings.slice(startIndex, startIndex + this.pageSize);
  }

  changePage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.updatePagination();
    }
  }

  getPages(): number[] {
    const pages: number[] = [];
    const start = Math.max(this.currentPage - 2, 0);
    const end = Math.min(start + 4, this.totalPages - 1);
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  }
}
