<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>

<div class="p-3" style="margin-top: 3pc;" class="borderaroud">
  <mat-stepper [linear]="isLinear" (selectionChange)="onStepChange($event)" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup" (ngSubmit)="onSubmit()">
        <ng-template matStepLabel>Basic Details</ng-template>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="row col-md-12">
            <div class="custom-form-field4">
              <mat-form-field>
                <mat-label>Inventory Title</mat-label>
                <input matInput placeholder="" formControlName="title" required>
                <mat-error *ngIf="firstFormGroup.controls['title'].hasError('required')">Inventory Title is
                  required</mat-error>
              </mat-form-field>
            </div>
          </div><br>
          <div class="row col-md-12">
            <!-- <div class="custom-form-field4">
              <label for="description" class="post-headings" style="color: rgb(136, 110, 110); font-family: 'Roboto', sans-serif;">
                Description
              </label>
              <textarea formControlName="description" class="description-textarea"></textarea>
              <span *ngIf="firstFormGroup.get('description').hasError('required') && firstFormGroup.get('description').touched"
                    class="error-message1">
                Description is required
              </span>
            </div> -->

            <!-- <div class="custom-form-field4">
              <label
              for="middleName"
              class="post-headings"
              [ngStyle]="{
                color:
                  firstFormGroup.get('description').hasError('required') && firstFormGroup.get('description').touched
                    ? '#dc3545'
                    : isFocused
                    ? '#004aad'
                    : 'rgb(136, 110, 110)'
              }"
              style="font-family: 'Roboto', sans-serif;"
            >
              Description *
            </label>
              <textarea
                formControlName="description"
                class="description-textarea"
                [class.error-border]="firstFormGroup.get('description').hasError('required') && firstFormGroup.get('description').touched">
              </textarea>
              <span *ngIf="firstFormGroup.get('description').hasError('required') && firstFormGroup.get('description').touched"
                    class="error-message1">
                Description is required
              </span>
            </div> -->

            <!-- <div class="row col-md-12">
              <div class="custom-form-field4">
                <label
                  for="middleName"
                  class="post-headings"
                  [ngStyle]="{
                    color:
                    firstFormGroup.get('description').hasError('required') && firstFormGroup.get('description').touched
                        ? '#dc3545'
                        : isFocused
                        ? '#004aad'
                        : 'rgb(136, 110, 110)'
                  }"
                  style="font-family: 'Roboto', sans-serif;"
                >
                  Description *
                </label>
                <textarea
                  formControlName="description"
                  class="description-textarea"
                  (focus)="isFocused = true"
                  (blur)="isFocused = false"
                  [ngClass]="{ 'border-error': firstFormGroup.get('description').hasError('required') && firstFormGroup.get('description').touched }"
                ></textarea>
                <div *ngIf="firstFormGroup.get('description').hasError('required') && firstFormGroup.get('description').touched" class="text-danger">
                  This field is required.
                </div>
              </div>
            </div> -->



            <div class="row col-md-12">
              <div class="custom-form-field4">
                <label
                  for="middleName"
                  class="post-headings"
                  [ngStyle]="{
                    color:
                      description.invalid && (description.dirty || description.touched)
                        ? '#f44336'
                        : isFocused
                        ? '#004aad'
                        : 'rgb(136, 110, 110)'
                  }"
                  style="font-family: 'Roboto', sans-serif;"
                >
                  Description *
                </label>
                <textarea
                  formControlName="description"
                  class="description-textarea"
                  (focus)="isFocused = true"
                  (blur)="isFocused = false"
                  [ngClass]="{ 'border-error': description.invalid && (description.dirty || description.touched) }"
                ></textarea>
                <div *ngIf="description.invalid && (description.dirty || description.touched)" class="text-dangers">
                  Description is required.
                </div>
              </div>
            </div>


          </div>

        </div>

        <div class="row col-md-12">
          <div class="custom-form-field4">
            <!-- <mat-form-field class="custom-form-field">
                                <mat-label>Loan Available</mat-label>
                                <mat-select disableRipple formControlName="loanAvailable">
                                    <mat-option>None</mat-option>
                                    <mat-option value="Bank">Bank</mat-option>
                                    <mat-option value="Private">Private</mat-option>
                                </mat-select>
                                <mat-error *ngIf="firstFormGroup.controls['loanAvailable'].hasError('required')">This
                                    field is required</mat-error>
                            </mat-form-field> -->
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Inventory Type</mat-label>
              <mat-select disableRipple formControlName="inventoryType"
                (selectionChange)="onInventoryTypeChange($event)" required>
                <mat-option>None</mat-option>
                <mat-option value="plots">Plots</mat-option>
                <mat-option value="flats">Flats</mat-option>
                <mat-option value="villas">Villas</mat-option>
                <mat-option value="commercials">Commercials</mat-option>
                <mat-option value="offices">Offices</mat-option>
                <mat-option value="mansions">Mansions</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['inventoryType'].hasError('required')">Inventory Type is required</mat-error>
            </mat-form-field>

            <mat-form-field class="custom-form-field">
              <mat-label>Negotiable</mat-label>
              <mat-select disableRipple formControlName="isNegotiable" required>
                <mat-option>None</mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">NO</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['isNegotiable'].hasError('required')">Negotiable is required</mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-field3" fxFlex="calc(33.33% - 16px)">
              <mat-label>Status</mat-label>
              <mat-select disableRipple formControlName="status" required>
                <mat-option>None</mat-option>
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Inactive</mat-option>

              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['status'].hasError('required')">Status is required</mat-error>
            </mat-form-field>
       <div class="d-flex">
            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-md-8 mobile" >
                  <mat-form-field>
                    <mat-label>Total Size</mat-label>
                    <input matInput placeholder="" formControlName="totalSize"  (input)="onInput($event)"
                    type="text"  required>
                    <mat-error *ngIf="firstFormGroup.controls['totalSize'].hasError('required')">Total Size is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-4 mobile">


                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="sizeUnit" required>
                      <mat-option value="Sqm">Square Meter</mat-option>
                      <mat-option value="Sqft">Square Foot</mat-option>
                      <mat-option value="Acre">Acre</mat-option>
                      <mat-option value="Sqyd">Square Yard</mat-option>
                      <mat-option value="Killa">Killa</mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls['sizeUnit'].hasError('required')">Unit is required</mat-error>
                  </mat-form-field>
                </div>
              </div>

            </div>
            <div class="col-sm-4" style="margin-top: 8px;">
              <div class="row">
                <div class="col-md-8 mobile">
                  <mat-form-field>
                    <mat-label>Price</mat-label>
                    <input matInput placeholder="" formControlName="aaskingPrice"  (input)="onInput($event)" maxlength="2"
                     type="text" required>
                    <mat-error *ngIf="firstFormGroup.controls['aaskingPrice'].hasError('required')">Price is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-4 mobile">
                  <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="aaskingPriceUnit" required>
                      <mat-option value="Lac">Lac</mat-option>
                      <mat-option value="Cr">Crore</mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls['aaskingPriceUnit'].hasError('required')">Unit is required</mat-error>
                  </mat-form-field>
                </div>

              </div>
            </div>
          </div>

            <!-- <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Total Size</mat-label>
              <input matInput placeholder="" formControlName="totalSize" type="text" (input)="onInput($event)">
              <mat-error *ngIf="firstFormGroup.controls['totalSize'].hasError('required')">This field
                is required</mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
              <mat-label>Unit</mat-label>
              <mat-select formControlName="sizeUnit">
                <mat-option value="Sqm">Square Meter</mat-option>
                <mat-option value="Sqft">Square Foot</mat-option>
                <mat-option value="Ac">Acre</mat-option>
                <mat-option value="Sqyd">Square Yard</mat-option>
                <mat-option value="Killa">Killa</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls['sizeUnit'].hasError('required')">This field
                is required</mat-error>
            </mat-form-field> -->
          </div>
        </div>

        <br>

        <br>

        <br>
        <div class="row col-md-12">
          <div class="custom-form-field4">
            <mat-form-field class="custom-form-field1">
              <div>
                <mat-toolbar>
                  <input matInput
                    [value]="selectedFileNames.length ? selectedFileNames.join(', ') : 'Upload Plan Image'" readonly />
                  <button mat-flat-button color="primary" type="button" [disabled]="!selectedFiles.length"
                    (click)="uploadFile()">Upload</button>
                </mat-toolbar>
                <input type="file" id="fileInput" formControlName="UploadPlaneImages" (change)="handleChange($event)"
                  name="fileInput" accept="image/*" multiple hidden />
                <button mat-stroked-button type="button" color="primary" (click)="triggerFileInput()">Browse</button>
              </div>
              <div class="uploaded-images">
                <div *ngFor="let image of uploadedImages; let i = index" class="uploaded-image-container">
                  <img [src]="image" alt="Uploaded Image" class="uploaded-image" />
                  <button mat-icon-button style="color:#004aad;" (click)="removeImage(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-form-field>

          </div>
        </div>
        <div class="button-container">
          <button mat-button class="custom-button font1 onlyhover" type="button" (click)="handleCancel()">Cancel</button>
          <button mat-button class="custom-button font1 onlyhover" type="submit" style="color: whitesmoke">Next</button>
        </div>
      </form>

    </mat-step>

    <!-- <mat-step   [stepControl]="selectedValue === 'Plot' ? PlotFormGroup : selectedValue === 'Flat' ? FlatFormGroup : null" label="Features"> -->
    <mat-step [stepControl]="showPlotForm ? PlotFormGroup :
                            showFlatForm ? FlatFormGroup :
                            showVillasForm ? villasFormGroup :
                            showCommercialsForm ? commercialsFormGroup :
                            showMansionsForm ? mansionsFormGroup :
                            showOfficesForm ? officesFormGroup :
                            null" [label]="getStepLabel()">


      <div *ngIf="showPlotForm">
        <form [formGroup]="PlotFormGroup">
          <div>Plot Feature</div>
          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Title Deed</mat-label>
            <mat-select placeholder="Title Deed" formControlName="titleDeed" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['titleDeed'].hasError('required')">Title Deed is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Encumbrance Certificate</mat-label>
            <mat-select placeholder="Encumbrance Certificate" formControlName="encumbranceCertificate" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['encumbranceCertificate'].hasError('required')">Encumbrance Certificate is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Taxes</mat-label>
            <mat-select placeholder="Property Taxes" formControlName="propertyTaxes" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['propertyTaxes'].hasError('required')">Property Taxes is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location">
            <mat-error *ngIf="PlotFormGroup.controls['location'].hasError('required')">Location is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Uniformity of the Plot</mat-label>
            <mat-select formControlName="uniformityOfThePlot" required>
              <mat-option value="High Uniformity">High Uniformity</mat-option>
              <mat-option value="Medium Uniformity">Medium Uniformity</mat-option>
              <mat-option value="Low Uniformity">Low Uniformity</mat-option>
              <mat-option value="Varied Uniformity">Varied Uniformity</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['uniformityOfThePlot'].hasError('required')">Uniformity of the Plot is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Amenities</mat-label>
            <!-- <mat-option>None</mat-option> -->
            <mat-select formControlName="amenity" multiple required>
              <mat-option value="Swimming Pool">Swimming Pool</mat-option>
              <mat-option value="Gym">Gym</mat-option>
              <mat-option value="Garden">Garden</mat-option>
              <mat-option value="Parking">Parking</mat-option>
              <mat-option value="Security">Security</mat-option>
              <mat-option value="Wi-Fi">Wi-Fi</mat-option>
              <mat-option value="Fitness centre">Fitness centre</mat-option>
              <mat-option value="Billiards Table">Billiards Table</mat-option>
              <mat-option value="Package lockers">Package lockers</mat-option>
              <mat-option value="Playgrounds">Playgrounds</mat-option>
              <mat-option value="Walking paths">Walking paths</mat-option>
              <mat-option value="Valet trashvalet">Valet trash</mat-option>
              <mat-option value="Surveillance cameras">Surveillance cameras</mat-option>
              <mat-option value="Green surrounding neighborhood">Green surrounding neighborhood</mat-option>
              <mat-option value="Clubhouse">Clubhouse</mat-option>
              <mat-option value="Party room">Party room</mat-option>
              <mat-option value="Rooftop garden">rooftop garden</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['amenity'].hasError('required')">Amenities is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Completion Certificate</mat-label>
            <mat-select formControlName="completionCertificate" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['completionCertificate'].hasError('required')">Completion Certificate is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Soil Quality</mat-label>
            <mat-select formControlName="soilQuality" required>
              <mat-option value="Excellent">Excellent</mat-option>
              <mat-option value="Good">Good</mat-option>
              <mat-option value="Average">Average</mat-option>
              <mat-option value="Poor">Poor</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['soilQuality'].hasError('required')">Soil Quality is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Access to the Plot</mat-label>
            <mat-select formControlName="accessToThePlot" required>
              <mat-option value="Easy Access">Easy Access</mat-option>
              <mat-option value="Moderate Access">Moderate Access</mat-option>
              <mat-option value="Difficult Access">Difficult Access</mat-option>
              <mat-option value="No Access">No Access</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['accessToThePlot'].hasError('required')">Access to the Plot is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Avoid Verbal Agreement</mat-label>
            <mat-select formControlName="avoidVerbalAgreement" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['avoidVerbalAgreement'].hasError('required')">Avoid Verbal Agreement is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Builder</mat-label>
            <input matInput placeholder="Builder" formControlName="builder" required>
            <mat-error *ngIf="PlotFormGroup.controls['builder'].hasError('required')">Builder is required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Connectivity</mat-label>
            <mat-select formControlName="connectivity" required>
              <mat-option>None</mat-option>
              <mat-option value="Excellent">Excellent</mat-option>
              <mat-option value="Good">Good</mat-option>
              <mat-option value="Average">Average</mat-option>
              <mat-option value="Poor">Poor</mat-option>

            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['connectivity'].hasError('required')">Connectivity is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Approvals</mat-label>
            <mat-select formControlName="constructionApprovals" required >
              <mat-option value="Approved">Approved</mat-option>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Not Applicable">Not Applicable</mat-option>
              <mat-option value="Denied">Denied</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['constructionApprovals'].hasError('required')">Construction Approvals is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Flat Purchase Checklist</mat-label>
            <mat-select formControlName="flatPurchaseChecklist" required>
              <mat-option value="Documents">Documents</mat-option>
              <mat-option value="Inspection">Inspection</mat-option>
              <mat-option value="Payment">Payment</mat-option>
              <mat-option value="Agreement">Agreement</mat-option>
              <mat-option value="Registration">Registration</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['flatPurchaseChecklist'].hasError('required')">Flat Purchase Checklist is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Investment Perspective</mat-label>
            <mat-select formControlName="investmentPerspective" required>
              <mat-option value="High Return">High Return</mat-option>
              <mat-option value="Moderate Return">Moderate Return</mat-option>
              <mat-option value="Low Return">Low Return</mat-option>
              <mat-option value="Risk">Risk</mat-option>
              <mat-option value="Safe">Safe</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['investmentPerspective'].hasError('required')">Investment Perspective is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>NA Order</mat-label>
            <mat-select formControlName="naOrder" required>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Completed">Completed</mat-option>
              <mat-option value="Canceled">Canceled</mat-option>
              <mat-option value="In Progress">In Progress</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['naOrder'].hasError('required')">NA Order is required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Power of Attorney</mat-label>
            <mat-select formControlName="powerOfAttorney" required>
              <mat-option>None</mat-option>
              <mat-option value="Full">Full</mat-option>
              <mat-option value="Limited">Limited</mat-option>
              <mat-option value="Special">Special</mat-option>

            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['powerOfAttorney'].hasError('required')">Power of Attorney is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Security</mat-label>
            <mat-select formControlName="security" required>
              <mat-option>None</mat-option>
              <mat-option value="High">High</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="Low">Low</mat-option>

            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['security'].hasError('required')">Security is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Water & Electricity Supply</mat-label>
            <mat-select formControlName="waterElectricitySupply" required>
              <mat-option value="Available">Available</mat-option>
              <mat-option value="Not Available">Not Available</mat-option>
              <mat-option value="Partially Available">Partially Available</mat-option>
              <mat-option value="Pending">Pending</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['waterElectricitySupply'].hasError('required')">Water & Electricity Supply is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Zoning Risks</mat-label>
            <mat-select formControlName="zoningRisks" required>
              <mat-option>None</mat-option>
              <mat-option value="High">High</mat-option>
              <mat-option value="Moderate">Moderate</mat-option>
              <mat-option value="Low">Low</mat-option>

            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['zoningRisks'].hasError('required')">Zoning Risks is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Approvals</mat-label>
            <mat-select formControlName="approvals" required>
              <mat-option value="Approved">Approved</mat-option>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Rejected">Rejected</mat-option>
              <mat-option value="In Process">In Process</mat-option>
              <mat-option value="Not Required">Not Required</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['approvals'].hasError('required')">Approvals is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>DueDiligence</mat-label>
            <mat-select formControlName="dueDiligence" required>
              <mat-option value="Completed">Completed</mat-option>
              <mat-option value="In Progress">In Progress</mat-option>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Not Required">Not Required</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['dueDiligence'].hasError('required')">DueDiligence is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Encumbrance</mat-label>
            <mat-select formControlName="encumbrance" required>
              <mat-option value="Clear">Clear</mat-option>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Encumbered">Encumbered</mat-option>
              <mat-option value="Unknown">Unknown</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['encumbrance'].hasError('required')">Encumbrance is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>HistoryOfTheProperty</mat-label>
            <mat-select formControlName="historyOfTheProperty" required>
              <mat-option value="Recent">Recent</mat-option>
              <mat-option value="Old">Old</mat-option>
              <mat-option value="Unknown">Unknown</mat-option>
              <mat-option value="Inherited">Inherited</mat-option>
            </mat-select>
            <mat-error *ngIf="PlotFormGroup.controls['historyOfTheProperty'].hasError('required')">HistoryOfTheProperty is
              required</mat-error>
          </mat-form-field>



          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>
        </form>
      </div>
      <div *ngIf="showFlatForm">
        <form [formGroup]="FlatFormGroup">
          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Amenity</mat-label>
            <!-- <mat-option>None</mat-option> -->
            <mat-select placeholder="Select Amenities" formControlName="amenity" multiple required>
              <!-- <mat-option [value]="null">None</mat-option> -->
              <mat-option value="Swimming Pool">Swimming Pool</mat-option>
              <mat-option value="Gym">Gym</mat-option>
              <mat-option value="Garden">Garden</mat-option>
              <mat-option value="Parking">Parking</mat-option>
              <mat-option value="Security">Security</mat-option>
              <mat-option value="Wi-Fi">Wi-Fi</mat-option>
              <mat-option value="Fitness centre">Fitness centre</mat-option>
              <mat-option value="Billiards Table">Billiards Table</mat-option>
              <mat-option value="Package lockers">Package lockers</mat-option>
              <mat-option value="Playgrounds">Playgrounds</mat-option>
              <mat-option value="Walking paths">Walking paths</mat-option>
              <mat-option value="Valet trashvalet">Valet trash</mat-option>
              <mat-option value="Surveillance cameras">Surveillance cameras</mat-option>
              <mat-option value="Green surrounding neighborhood">Green surrounding neighborhood</mat-option>
              <mat-option value="Clubhouse">Clubhouse</mat-option>
              <mat-option value="Party room">Party room</mat-option>
              <mat-option value="Rooftop garden">rooftop garden</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['amenity'].hasError('required')">Amenity is required</mat-error>
          </mat-form-field>



          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>RERA Compliance</mat-label>
            <mat-select placeholder="Select RERA Compliance" formControlName="reraCompliance" required>
              <mat-option>None</mat-option>
              <mat-option value="Compliant">Compliant</mat-option>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Exempt">Exempt</mat-option>
              <mat-option value="Under Review">Under Review</mat-option>
              <mat-option value="Not Compliant">Not Compliant</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['reraCompliance'].hasError('required')">RERA Compliance is
              required</mat-error>
          </mat-form-field>


          <!-- <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Budget</mat-label>
            <input matInput placeholder="Select Budget" formControlName="budget" required>
            <mat-error *ngIf="FlatFormGroup.controls['budget'].hasError('required')">This field is required</mat-error>
          </mat-form-field> -->


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location" required>
            <mat-error *ngIf="FlatFormGroup.controls['location'].hasError('required')">Location is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Occupancy Certificate</mat-label>
            <mat-select placeholder="Select Occupancy Certificate" formControlName="occupancyCertificate" required>
              <mat-option>None</mat-option>
              <mat-option value="Issued">Issued</mat-option>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Not Applied">Not Applied</mat-option>
              <mat-option value="Exempt">Exempt</mat-option>
              <mat-option value="Under Review">Under Review</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['occupancyCertificate'].hasError('required')">Occupancy Certificate is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Area of Flat</mat-label>
            <input matInput placeholder="Area of Flat" formControlName="areaOfFlat" required>
            <mat-error *ngIf="FlatFormGroup.controls['areaOfFlat'].hasError('required')">Area of Flat is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Bank NOC</mat-label>

            <mat-select placeholder="Select Bank NOC" formControlName="bankNoc" >

            <!-- <mat-select placeholder="Select Bank NOC" formControlName="bankNOC" required > -->

              <mat-option>None</mat-option>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['bankNoc'].hasError('required')">Bank NOC is required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Quality</mat-label>
            <mat-select placeholder="Select Construction Quality" formControlName="constructionQuality"  required>
                <mat-option>None</mat-option>
              <mat-option value="Excellent">Excellent</mat-option>
              <mat-option value="Good">Good</mat-option>
              <mat-option value="Average">Average</mat-option>
              <mat-option value="Poor">Poor</mat-option>
              <mat-option value="Needs Improvement">Needs Improvement</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['constructionQuality'].hasError('required')">Construction Quality is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Choose Reputed Builders</mat-label>
            <input matInput placeholder="Choose Reputed Builders" formControlName="reputedBuilders" required>
            <mat-error *ngIf="FlatFormGroup.controls['reputedBuilders'].hasError('required')">Choose Reputed Builders is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Approvals</mat-label>
            <mat-select placeholder="Select Construction Approvals" formControlName="constructionApprovals" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['constructionApprovals'].hasError('required')">Construction Approvals is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Legal Check of Property</mat-label>
            <mat-select placeholder="Select Legal Check of Property" formControlName="legalCheckOfProperty" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['legalCheckOfProperty'].hasError('required')">Legal Check of Property is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Title Verification</mat-label>
            <mat-select placeholder="Select Title Verification" formControlName="titleVerification" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['titleVerification'].hasError('required')">Title Verification is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Apartment Possession</mat-label>
            <input matInput placeholder="Apartment Possession" formControlName="apartmentPossession" required>
            <mat-error *ngIf="FlatFormGroup.controls['apartmentPossession'].hasError('required')">Apartment Possession is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Avoid Verbal Agreement</mat-label>
            <mat-select placeholder="Select Avoid Verbal Agreement" formControlName="verbalAgreement" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['verbalAgreement'].hasError('required')">Avoid Verbal Agreement is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Builder-Buyer Agreement</mat-label>
            <mat-select placeholder="Select Agreement" formControlName="builderBuyerAgreement" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['builderBuyerAgreement'].hasError('required')">Builder-Buyer Agreement is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Encumbrance</mat-label>
            <input matInput placeholder="Encumbrance" formControlName="encumbrance" required>
            <mat-error *ngIf="FlatFormGroup.controls['encumbrance'].hasError('required')">Encumbrance is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Land Use Permissions</mat-label>
            <mat-select placeholder="Select Permission" formControlName="landUsePermissions" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['landUsePermissions'].hasError('required')">Land Use Permissions is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Physical visit of the location</mat-label>
            <mat-select placeholder="Select options" formControlName="physicalVisit"  required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['physicalVisit'].hasError('required')">Physical visit of the location is
              required</mat-error>
          </mat-form-field>



          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Location</mat-label>
            <input matInput placeholder="Property Location" formControlName="propertyLocation" required>
            <mat-error *ngIf="FlatFormGroup.controls['propertyLocation'].hasError('required')">Property Location is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Taxes</mat-label>
            <mat-select placeholder="Select options" formControlName="propertyTaxes"  required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['propertyTaxes'].hasError('required')">Property Taxes is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Resale Value</mat-label>
            <mat-select placeholder="Select Resale Values" formControlName="resaleValue" required  >
              <mat-option>None</mat-option>
              <mat-option value="Low">Low</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="High">High</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['resaleValue'].hasError('required')">Resale Value is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Sale Agreement</mat-label>
            <mat-select placeholder="Select Sale Agreements" formControlName="saleAgreement" required>
              <mat-option>None</mat-option>
              <mat-option value="Signed">Signed</mat-option>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Not Applicable">Not Applicable</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['saleAgreement'].hasError('required')">Sale Agreement is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Security</mat-label>
            <mat-select placeholder="Select Security" formControlName="security" required>
              <mat-option>None</mat-option>
              <mat-option value="High">High</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="Low">Low</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['security'].hasError('required')">Security is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Service Tax</mat-label>
            <mat-select placeholder="Select Service Tax" formControlName="serviceTax" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="FlatFormGroup.controls['serviceTax'].hasError('required')">Service Tax is
              required</mat-error>
          </mat-form-field>

          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>

        </form>
      </div>

      <div *ngIf="showVillasForm">
        <form [formGroup]="villasFormGroup">
          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Amenities</mat-label>
            <mat-select placeholder="Select Amenities" formControlName="amenity" multiple required>
              <!-- <mat-option>None</mat-option> -->
              <mat-option value="Swimming Pool">Swimming Pool</mat-option>
              <mat-option value="Gym">Gym</mat-option>
              <mat-option value="Garden">Garden</mat-option>
              <mat-option value="Parking">Parking</mat-option>
              <mat-option value="Security">Security</mat-option>
              <mat-option value="Wi-Fi">Wi-Fi</mat-option>
              <mat-option value="Fitness centre">Fitness centre</mat-option>
              <mat-option value="Billiards Table">Billiards Table</mat-option>
              <mat-option value="Package lockers">Package lockers</mat-option>
              <mat-option value="Playgrounds">Playgrounds</mat-option>
              <mat-option value="Walking paths">Walking paths</mat-option>
              <mat-option value="Valet trashvalet">Valet trash</mat-option>
              <mat-option value="Surveillance cameras">Surveillance cameras</mat-option>
              <mat-option value="Green surrounding neighborhood">Green surrounding neighborhood</mat-option>
              <mat-option value="Clubhouse">Clubhouse</mat-option>
              <mat-option value="Party room">Party room</mat-option>
              <mat-option value="Rooftop garden">rooftop garden</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['amenity'].hasError('required')">Amenities is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Taxes</mat-label>
            <mat-select placeholder="Select options" formControlName="propertyTaxes" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['propertyTaxes'].hasError('required')">Property Taxes is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Quality</mat-label>
            <mat-select placeholder="Select Construction Quality" formControlName="constructionQuality" required>
              <mat-option>None</mat-option>
              <mat-option value="Excellent">Excellent</mat-option>
              <mat-option value="Good">Good</mat-option>
              <mat-option value="Average">Average</mat-option>
              <mat-option value="Poor">Poor</mat-option>
              <mat-option value="Needs Improvement">Needs Improvement</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['constructionQuality'].hasError('required')">Construction Quality is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Occupancy Certificate</mat-label>
            <mat-select placeholder="Select Occupancy Certificate" formControlName="occupancyCertificate" required >
              <mat-option value="Issued">Issued</mat-option>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Not Applied">Not Applied</mat-option>
              <mat-option value="Exempt">Exempt</mat-option>
              <mat-option value="Under Review">Under Review</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['occupancyCertificate'].hasError('required')">Occupancy Certificate is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Security</mat-label>
            <mat-select placeholder="Select Security" formControlName="security" required>
              <mat-option>None</mat-option>
              <mat-option value="High">High</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="Low">Low</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['security'].hasError('required')">Security is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Legal Compliance</mat-label>
            <mat-select placeholder="Select Legal Compliance" formControlName="legalCompliance" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['legalCompliance'].hasError('required')">Legal Compliance is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location" required>
            <mat-error *ngIf="villasFormGroup.controls['location'].hasError('required')">Location is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location Considerations</mat-label>
            <input matInput placeholder="Location Considerations"  formControlName="locationConsiderations" required>
            <mat-error *ngIf="villasFormGroup.controls['locationConsiderations'].hasError('required')">Location Considerations is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Title Deed</mat-label>
            <mat-select placeholder="Title Deed" formControlName="titleDeed" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['titleDeed'].hasError('required')">Title Deed is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Accessibility</mat-label>
            <mat-select placeholder="Select Accessibility" formControlName="accessibility" required>
              <mat-option>None</mat-option>
              <mat-option value="Wheelchair Accessible">Wheelchair Accessible</mat-option>
              <mat-option value="Elevator">Elevator</mat-option>
              <mat-option value="Ramps">Ramps</mat-option>
              <mat-option value="Braille Signage">Braille Signage</mat-option>
              <mat-option value="Hearing Assistance">Hearing Assistance</mat-option>
              <mat-option value="Accessible Parking">Accessible Parking</mat-option>
              <mat-option value="Sign Language Interpreters">Sign Language Interpreters</mat-option>
              <mat-option value="Accessible Restrooms">Accessible Restrooms</mat-option>
              <mat-option value="Guide Dogs Allowed">Guide Dogs Allowed</mat-option>
              <mat-option value="Accessible Entrances">Accessible Entrances</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['accessibility'].hasError('required')">Accessibility is
              required</mat-error>
          </mat-form-field>


          <!-- <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Budget</mat-label>
            <input matInput placeholder="Budget" formControlName="budget" required>
            <mat-error *ngIf="villasFormGroup.controls['budget'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field> -->

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Builder Reputation</mat-label>
            <!-- <input matInput placeholder="Builder Reputation" formControlName="builderReputation"> -->
            <mat-select placeholder="Builder Reputation" formControlName="builderReputation" required>
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['builderReputation'].hasError('required')">Builder Reputation is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Approvals</mat-label>
            <mat-select placeholder="Select Construction Approvals" formControlName="constructionApprovals" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['constructionApprovals'].hasError('required')">Construction Approvals is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Documents for Property Use</mat-label>
            <mat-select placeholder="Select Option" formControlName="documentsForPropertyUse" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['documentsForPropertyUse'].hasError('required')">Documents for Property Use is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Home Loan</mat-label>
            <mat-select placeholder="Select Option" formControlName="homeLoan" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['homeLoan'].hasError('required')">Home Loan is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Investment Perspective</mat-label>
            <mat-select placeholder="Select Option" formControlName="investmentPerspective" required>
              <mat-option>None</mat-option>
              <mat-option value="High">High</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="Low">Low</mat-option>
              <mat-option value="Not Sure">Not Sure</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['investmentPerspective'].hasError('required')">Investment Perspective is
              required</mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Land Documents</mat-label>
            <mat-select placeholder="Select Land Document" formControlName="landDocuments" required>
              <mat-option>None</mat-option>
              <mat-option value="Title Deed">Title Deed</mat-option>
              <mat-option value="Sale Deed">Sale Deed</mat-option>
              <mat-option value="Land Use Certificate">Land Use Certificate</mat-option>
              <mat-option value="Encumbrance Certificate">Encumbrance Certificate</mat-option>
              <!-- Add other options as needed -->
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['landDocuments'].hasError('required')">Land Documents is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Modern Features</mat-label>
            <mat-select placeholder="Select Modern Feature" formControlName="modernFeatures" required>
              <mat-option>None</mat-option>
              <mat-option value="Swimming Pool">Swimming Pool</mat-option>
              <mat-option value="Gym">Gym</mat-option>
              <mat-option value="Home Theater">Home Theater</mat-option>
              <mat-option value="Smart Home System">Smart Home System</mat-option>
              <mat-option value="Jacuzzi">Jacuzzi</mat-option>
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['modernFeatures'].hasError('required')">Modern Features is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>No Objection Certificates</mat-label>
            <mat-select placeholder="Select No Objection Certificate" formControlName="noObjectionCertificates" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
              <!-- Add other options as needed -->
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['noObjectionCertificates'].hasError('required')">No Objection Certificates is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Outdoor Amenities</mat-label>
            <mat-select placeholder="Select Outdoor Amenities" formControlName="outdoorAmenities" required>
              <mat-option>None</mat-option>
              <mat-option value="Swimming Pool">Swimming Pool</mat-option>
              <mat-option value="Garden">Garden</mat-option>
              <mat-option value="Gym">Gym</mat-option>
              <mat-option value="Playground">Playground</mat-option>
              <mat-option value="BBQ Area">BBQ Area</mat-option>
              <!-- Add other options as needed -->
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['outdoorAmenities'].hasError('required')">Outdoor Amenities is
              required</mat-error>
          </mat-form-field>


          <!-- <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Pricing</mat-label>
            <input matInput placeholder="Enter Pricing" formControlName="pricing" required>

            <mat-error *ngIf="villasFormGroup.controls['pricing'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field> -->


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Privacy and Space</mat-label>
            <mat-select placeholder="Select Privacy and Space" formControlName="privacyAndSpace" required>
              <mat-option>None</mat-option>
              <mat-option value="High">High</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="Low">Low</mat-option>
              <!-- Add more options as needed -->
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['privacyAndSpace'].hasError('required')">Privacy and Space is
              required</mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Real Estate Developer</mat-label>
            <mat-select placeholder="Select Real Estate Developer" formControlName="realEstateDeveloper" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
              <!-- Add more options as needed -->
            </mat-select>
            <mat-error *ngIf="villasFormGroup.controls['realEstateDeveloper'].hasError('required')">Real Estate Developer is
              required</mat-error>
          </mat-form-field>


          <!-- <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Size of Property</mat-label>
            <input matInput placeholder="Size of Property" formControlName="sizeOfProperty" required>
            <mat-error *ngIf="villasFormGroup.controls['sizeOfProperty'].hasError('required')">This field is
              required</mat-error>
          </mat-form-field> -->

          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>
        </form>
      </div>
      <div *ngIf="showCommercialsForm">
        <form [formGroup]="commercialsFormGroup">

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Market Research</mat-label>
            <mat-select placeholder="Select Market Research" formControlName="marketResearch" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['marketResearch'].hasError('required')">
              Market Research is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location" required>
            <mat-error *ngIf="commercialsFormGroup.controls['location'].hasError('required')">
              Location is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Commercial Property Types</mat-label>
            <mat-select formControlName="commercialPropertyTypes" required>
              <mat-option *ngFor="let type of propertyTypes" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['commercialPropertyTypes'].hasError('required')">
              Commercial Property Types is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Floor Plan</mat-label>
            <mat-select placeholder="Select Floor Plans" formControlName="floorPlan" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['commercialPropertyTypes'].hasError('required')">
              Floor Plan is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Lease Structure</mat-label>
            <mat-select placeholder="Select Lease Structure" formControlName="leaseStructure" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['leaseStructure'].hasError('required')">
              Lease Structure is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Choose the Right Builder</mat-label>
            <input matInput placeholder="Choose the Right Builder" formControlName="rightBuilder" required>
            <mat-error *ngIf="commercialsFormGroup.controls['rightBuilder'].hasError('required')">
              Choose the Right Builder is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Risk and Return</mat-label>
            <mat-select placeholder="Select Risk and Return" formControlName="riskAndReturn" required>
              <mat-option>None</mat-option>
              <mat-option value="Low">Low</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="High">High</mat-option>
              <mat-option value="Very High">Very High</mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['riskAndReturn'].hasError('required')">
              Risk and Return is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Value Assessment</mat-label>
            <input matInput placeholder="Value Assessment" formControlName="valueAssessment" required>
            <mat-error *ngIf="commercialsFormGroup.controls['valueAssessment'].hasError('required')">
              Value Assessment is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Evaluate Rental Income</mat-label>
            <mat-select placeholder="Select Rental Income Evaluation" formControlName="rentalIncome" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="commercialsFormGroup.controls['rentalIncome'].hasError('required')">
              Evaluate Rental Income is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Tenant Profiles</mat-label>
            <input matInput placeholder="Tenant Profiles" formControlName="tenantProfiles" required>
            <mat-error *ngIf="commercialsFormGroup.controls['tenantProfiles'].hasError('required')">
              Tenant Profiles is required
            </mat-error>
          </mat-form-field>

          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>
        </form>
      </div>

      <div *ngIf="showOfficesForm">
        <form [formGroup]="officesFormGroup">

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location" required>
            <mat-error *ngIf="officesFormGroup.controls['location'].hasError('required')">
              Locationis required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Amenity</mat-label>
            <mat-select placeholder="Select Amenities" formControlName="amenity" multiple required>
              <!-- <mat-option>None</mat-option> -->
              <mat-option value="Swimming Pool">Swimming Pool</mat-option>
              <mat-option value="Gym">Gym</mat-option>
              <mat-option value="Garden">Garden</mat-option>
              <mat-option value="Parking">Parking</mat-option>
              <mat-option value="Security">Security</mat-option>
              <mat-option value="Wi-Fi">Wi-Fi</mat-option>
              <mat-option value="Fitness centre">Fitness centre</mat-option>
              <mat-option value="Billiards Table">Billiards Table</mat-option>
              <mat-option value="Package lockers">Package lockers</mat-option>
              <mat-option value="Playgrounds">Playgrounds</mat-option>
              <mat-option value="Walking paths">Walking paths</mat-option>
              <mat-option value="Valet trashvalet">Valet trash</mat-option>
              <mat-option value="Surveillance cameras">Surveillance cameras</mat-option>
              <mat-option value="Green surrounding neighborhood">Green surrounding neighborhood</mat-option>
              <mat-option value="Clubhouse">Clubhouse</mat-option>
              <mat-option value="Party room">Party room</mat-option>
              <mat-option value="Rooftop garden">rooftop garden</mat-option>
              <!-- Add more options as needed -->
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['amenity'].hasError('required')">
              Amenity is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Investment Goals</mat-label>
            <mat-select placeholder="Select Investment Goals" formControlName="investmentGoals" multiple required>
              <mat-option value="Growth">Growth</mat-option>
              <mat-option value="Income">Income</mat-option>
              <mat-option value="Preservation">Preservation</mat-option>
              <mat-option value="Speculation">Speculation</mat-option>
              <mat-option value="Diversification">Diversification</mat-option>
              <!-- Add more options as needed -->
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['investmentGoals'].hasError('required')">
              Investment Goals is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Title</mat-label>
            <input matInput placeholder="Title" formControlName="title" required>
            <mat-error *ngIf="officesFormGroup.controls['title'].hasError('required')">
              Title is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Cash Flow</mat-label>
            <mat-select placeholder="Select Cash Flow Options" formControlName="cashFlow" multiple required>

              <mat-option value="Positive">Positive</mat-option>
              <mat-option value="Negative">Negative</mat-option>
              <mat-option value="Neutral">Neutral</mat-option>
              <mat-option value="Variable">Variable</mat-option>
              <mat-option value="Stable">Stable</mat-option>
              <!-- Add more options as needed -->
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['cashFlow'].hasError('required')">
              Cash Flow is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Check Legal Documents</mat-label>
            <mat-select placeholder="Select an option" formControlName="legalDocuments" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['legalDocuments'].hasError('required')">
              Check Legal Documents is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Financial Considerations</mat-label>
            <mat-select placeholder="Select Financial Considerations" formControlName="financialConsiderations" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['financialConsiderations'].hasError('required')">
              Financial Considerations is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Infrastructure</mat-label>
            <input matInput placeholder="Infrastructure" formControlName="infrastructure" required>
            <mat-error *ngIf="officesFormGroup.controls['infrastructure'].hasError('required')">
              Infrastructure is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Lease Structure</mat-label>
            <input matInput placeholder="Lease Structure" formControlName="leaseStructure" required>
            <mat-error *ngIf="officesFormGroup.controls['leaseStructure'].hasError('required')">
              Lease Structure is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Market Research</mat-label>
            <mat-select placeholder="Market Research" formControlName="marketResearch" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['marketResearch'].hasError('required')">
              Market Research is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Tax Payment Status</mat-label>
            <mat-select placeholder="Select a status" formControlName="taxPaymentStatus" required>
              <mat-option value="Paid">Paid</mat-option>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Overdue">Overdue</mat-option>
              <mat-option value="Exempt">Exempt</mat-option>
              <mat-option value="Not Applicable">Not Applicable</mat-option>
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['taxPaymentStatus'].hasError('required')">
              Tax Payment Status is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Value Assessment</mat-label>
            <input matInput placeholder="Value Assessment" formControlName="valueAssessment" required>
            <mat-error *ngIf="officesFormGroup.controls['valueAssessment'].hasError('required')">
              Value Assessment is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Documentation</mat-label>
            <mat-select placeholder="Property Documentation" formControlName="propertyDocumentation" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="officesFormGroup.controls['propertyDocumentation'].hasError('required')">
              Property Documentation is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Choose the Right Builder</mat-label>
            <input matInput placeholder="Choose the Right Builder" formControlName="rightBuilder" required>
            <mat-error *ngIf="officesFormGroup.controls['rightBuilder'].hasError('required')">
              Choose the Right Builder is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Property Location</mat-label>
            <input matInput placeholder="Property Location" formControlName="propertyLocation" required>
            <mat-error *ngIf="officesFormGroup.controls['propertyLocation'].hasError('required')">
              Property Location is required
            </mat-error>
          </mat-form-field>


          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>
        </form>
      </div>


      <div *ngIf="showMansionsForm">
        <form [formGroup]="mansionsFormGroup">

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Location</mat-label>
            <input matInput placeholder="Location" formControlName="location" required>
            <mat-error *ngIf="mansionsFormGroup.controls['location'].hasError('required')">
              Location is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Security</mat-label>
            <mat-select placeholder="Select an option" formControlName="security" required>
              <mat-option>None</mat-option>
              <mat-option value="High">High</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="Low">Low</mat-option>

            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['security'].hasError('required')">
              Security is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Amenity</mat-label>
            <mat-select placeholder="Select an amenity" formControlName="amenity" multiple required>
              <mat-option value="Swimming Pool">Swimming Pool</mat-option>
              <mat-option value="Gym">Gym</mat-option>
              <mat-option value="Garden">Garden</mat-option>
              <mat-option value="Parking">Parking</mat-option>
              <mat-option value="Security">Security</mat-option>
              <mat-option value="Wi-Fi">Wi-Fi</mat-option>
              <mat-option value="Fitness centre">Fitness centre</mat-option>
              <mat-option value="Billiards Table">Billiards Table</mat-option>
              <mat-option value="Package lockers">Package lockers</mat-option>
              <mat-option value="Playgrounds">Playgrounds</mat-option>
              <mat-option value="Walking paths">Walking paths</mat-option>
              <mat-option value="Valet trashvalet">Valet trash</mat-option>
              <mat-option value="Surveillance cameras">Surveillance cameras</mat-option>
              <mat-option value="Green surrounding neighborhood">Green surrounding neighborhood</mat-option>
              <mat-option value="Clubhouse">Clubhouse</mat-option>
              <mat-option value="Party room">Party room</mat-option>
              <mat-option value="Rooftop garden">rooftop garden</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['amenity'].hasError('required')">
              Amenityis required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Trusted Developer</mat-label>
            <mat-select placeholder="Trusted Developer" formControlName="trustedDeveloper" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['trustedDeveloper'].hasError('required')">
              Trusted Developer is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Construction Approvals</mat-label>
            <mat-select placeholder="Construction Approvals" formControlName="constructionApprovals" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['constructionApprovals'].hasError('required')">
              Construction Approvals is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Documents for Property Use</mat-label>
            <mat-select placeholder="Select an option" formControlName="documentsForPropertyUse" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['documentsForPropertyUse'].hasError('required')">
              Documents for Property Use is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>High-End Kitchens</mat-label>
            <mat-select placeholder="Select an option" formControlName="highEndKitchens" required>
              <mat-option value="Luxury">Luxury</mat-option>
              <mat-option value="Deluxe">Deluxe</mat-option>
              <mat-option value="Elite">Elite</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['highEndKitchens'].hasError('required')">
              High-End Kitchens is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Investment Perspective</mat-label>
            <mat-select placeholder="Select Option" formControlName="investmentPerspective" required>
              <mat-option value="High">High</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="Low">Low</mat-option>
              <mat-option value="Not Sure">Not Sure</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['investmentPerspective'].hasError('required')">
              Investment Perspective is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="custom-form-field" fxFlex="calc(33.33% - 16px)">
            <mat-label>Smart Homes</mat-label>
            <mat-select placeholder="Select an option" formControlName="smartHomes" required>
              <mat-option value="Basic">Basic</mat-option>
              <mat-option value="Advanced">Advanced</mat-option>
              <mat-option value="Premium">Premium</mat-option>
            </mat-select>
            <mat-error *ngIf="mansionsFormGroup.controls['smartHomes'].hasError('required')">
              Smart Homes is required
            </mat-error>
          </mat-form-field>


          <div class="button-container">
            <button mat-button class="custom-button font1 onlyhover" type="button" (click)="stepper.previous()">Back</button>
            <button mat-button class="custom-button font1 onlyhover" style="color:white" (click)="updateprojectfeatures()">Save</button>
          </div>
        </form>
      </div>

    </mat-step>
  </mat-stepper>
</div>

</div>
<app-footer></app-footer>
</div>
