<div class="row" class="first" style="display: flex;">
  <div class="col-10" >
    <span style="align-items: center; font-weight: bold;" class="fontset">
      <span *ngIf="listingData?.user?.fullName; else projectOwner">Property</span>
      Owner Details
    </span>

    <ng-template #projectOwner>Project</ng-template>

  </div>
  <div class="col-2" >
    <span class="close-container">
      <button class="close-button"  (click)="closeDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </span>
  </div>
</div>
<div  style="width: 100%; font-size: 18px;">
  <div class="row">
    <div class="col-md-7 mt-2">
      <h3 class="m-0 fontset">Posted By Owner</h3>

      <div style="display: flex; align-items: center;">
        <span class="labelall fontset">Name : </span>&nbsp;
        <span class="labelvalue">
          {{ listingData?.projectBuilder?.fullName || listingData?.fullName }}
        </span>
      </div>

      <div style="display: flex; align-items: center;" class="mt-2">
        <span class="labelall fontset">Email : </span>&nbsp;
        <span class="labelvalue">
          {{ listingData?.projectBuilder?.email || listingData?.email }}
        </span>
      </div>

      <div style="display: flex; align-items: center;" class="mt-2">
        <span style="font-weight: 550; " class="labelall fontset">Mobile : </span>&nbsp;
        <!-- <span class="labelvalue">{{ userProfileData.mobileNumber }}</span> -->
        <span class="labelvalue"> {{ listingData?.projectBuilder?.mobileCode || listingData?.mobileCode }} {{ listingData?.projectBuilder?.mobileNumber || listingData?.mobileNumber }}</span>
      </div>
    </div>

    <div class="col-md-5 mt-2">
      <div class="mt-1">
        <h3 class="m-0 fontset">Posted On {{ formattedDate | date: 'dd-MM-yyyy' }}</h3>
      </div>
      <div style="display: flex; align-items: center;" class="mt-1">
        <div class="mt-1">
          <img alt="Profile Picture" class="profile-pic" src="../assets/images/profile.png">
        </div>
      </div>
      <div style="display: flex; align-items: center;" class="mt-2">
      </div>
      <div style="display: flex; align-items: center;" class="mt-2">
      </div>
    </div>
  </div>
</div>

<div class="second mt-0">
  <div class="row col-12">
    <span class="fontset">Please Provide Below Information</span>
  </div>
</div>

<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <div class="row" style="margin-top: 5px;">
    <div class="col-md-7">
      <h3 class="fontset">Basic Information</h3>
      <div class="row col-sm-12" style="display: flex;">
        <h3 class="bold-black fontset">Your reason to buy is : </h3>&nbsp;&nbsp;
        <mat-radio-group formControlName="reasonToBuy" aria-label="Select an option">
          <mat-radio-button value="Investment">Investment</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="Self Use">Self Use</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row col-sm-12" style="display: flex;">
        <h3 class="bold-black deepgap fontset">Are you a property dealer:</h3>&nbsp;&nbsp;
        <mat-radio-group formControlName="propertyDealer" aria-label="Select an option">
          <mat-radio-button value="Yes">Yes</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <h3 class="bold-black deepgap fontset">By when you are planning to buy the property? : </h3>
      <p>
        <mat-radio-group formControlName="planningToBuy" aria-label="Select an option">
          <mat-radio-button value="3 months">3 months</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="6 months">6 months</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="more than 6 months">more than 6 months</mat-radio-button>
        </mat-radio-group>
      </p>



    </div>
    <div class="col-md-5">
      <h3 class="fontset">Optional Information</h3>
      <div class="row col-sm-12">

        <p>
          <mat-checkbox formControlName="homeLoan">I am interested in home loan</mat-checkbox><br>
          <mat-checkbox formControlName="siteVisits">I am interested in site visits.</mat-checkbox><br>
          <mat-checkbox formControlName="terms" >I accept Terms & Policy
          </mat-checkbox>
        </p>
      </div>
      <div class="button-container mt-2">
        <button class="button1" [disabled]="contactForm.invalid" >
        Submit
        </button>
      </div>
    </div>
  </div>
</form>
