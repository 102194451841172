<!-- Add a container wrapper
<div class="page-container">
  <app-header></app-header>
  <div class="content">
    <div *ngIf="privacyContent" [innerHTML]="privacyContent" class="p-3"></div>
  </div>
  <app-footer></app-footer>
</div> -->

<div class="page-container">
  <app-header></app-header>
  <div class="content">
    <img src="../../assets/images/Frame 20.jpg" alt="About Us" class="aboutus">
    <div *ngIf="privacyContent" [innerHTML]="privacyContent" class="aboutus-container"></div>
  </div>

  <app-footer></app-footer>
</div>
