<!-- <div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>

    <div class="col-12 justify-content-center mt-4">
      <app-project-common-filter (searchTermChange)="onSearchTermChange($event)"></app-project-common-filter>
    </div>


    <div style="cursor: pointer;" *ngIf="recentList.length > 0;" class="card-container master12 col-md-12">
      <ng-container *ngIf="loading$ | async; else showListings">
        <app-loading></app-loading>
      </ng-container>
    <ng-template #showListings>
    <ng-container *ngFor="let card of recentList">
     <app-property-card [cardData]="card" class="card-item"></app-property-card>
   </ng-container>
   </ng-template>

    </div>
    <div *ngIf="!(loading$ | async) && recentList.length === 0">
      <app-not-found></app-not-found>
    </div>
    <div class="pagination-container" *ngIf="!(loading$ | async) && recentList.length > 0">
      <span class="page-info">Page {{ currentPage + 1 }} of {{ totalPages }}</span>
      <div class="pagination">
        <button
          class="page-link"
          (click)="changePage(currentPage - 1)"
          [disabled]="currentPage === 0"
        >
          &lt; Previous Page
        </button>
        <ng-container *ngFor="let page of getPages()">
          <button
            class="page-number"
            [class.active]="currentPage === page"
            (click)="changePage(page)"
          >
            {{ page + 1 }}
          </button>
        </ng-container>
        <button
          class="page-link"
          (click)="changePage(currentPage + 1)"
          [disabled]="currentPage + 1 >= totalPages"
        >
          Next Page &gt;
        </button>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>





 -->











 <div class="wrapper">
  <app-header></app-header>
  <app-menu></app-menu>
  <div class="col-md-12 content">
    <div class="row justify-content-center align-items-center mt-4">
      <div class="col-12 col-md-10 ">
        <div class="textstyle">Recently Added Project</div>
        <app-project-common-filter (searchTermChange)="onSearchTermChange($event)"></app-project-common-filter>


      </div>
      <div class="col-12 col-md-10 ">
        <ng-container *ngIf="recentList?.length > 0;">
          <div class="listings-container">
            <ng-container *ngIf="loading$ | async; else showListings">
              <app-loading></app-loading>
            </ng-container>
            <ng-template #showListings>
              <ng-container *ngFor="let card of recentList">
                <app-property-card
                  [card]="card"
            [showEditDetailsButton]="true"
            [showViewDetailsButton]="true"
            [showInventoryButton]="true"
            [showdetails]="false"
            [showdetailsbutton]="false"
            [showdsize]="false"
            [showEditInventoryButton]="false"
                ></app-property-card>
              </ng-container>
            </ng-template>

          </div>
          <!-- <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
            ⬆
          </div> -->
        </ng-container>
        <div *ngIf="!(loading$ | async) && recentList.length === 0">
          <app-not-found></app-not-found>
        </div>


      </div>
    </div>



    <!-- <ng-template #noListings>
      <app-not-found></app-not-found>
    </ng-template> -->
    <div class="pagination-container" *ngIf="!(loading$ | async) && recentList.length > 0">
      <span class="page-info">Page {{ currentPage + 1 }} of {{ totalPages }}</span>

      <div class="pagination">
        <button class="page-link" (click)="changePage(currentPage - 1)" [disabled]="currentPage === 0">
          &lt; Previous Page
        </button>

        <ng-container *ngFor="let page of getPages()">
          <button class="page-number" [class.active]="currentPage === page" (click)="changePage(page)">
            {{ page + 1 }}
          </button>
        </ng-container>

        <button class="page-link" (click)="changePage(currentPage + 1)" [disabled]="currentPage + 1 >= totalPages">
          Next Page &gt;
        </button>
      </div>
    </div>
  </div>
  <br />


  <app-footer></app-footer>
</div>
