import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  token = '';
  usertoken: any;
  key = '';
  socialOrigin: any;
  email: any;
  constructor(private tokenStorage: TokenStorageService,
    private http: HttpClient) {
      console.log(this.tokenStorage.getToken());

      const storedToken = this.tokenStorage.getToken();
      console.log('Raw Token:', storedToken);
      this.usertoken = storedToken ? JSON.parse(storedToken) : null;


    this.socialOrigin = this.tokenStorage.getSocialOrigin();
    this.email = this.tokenStorage.getSocialUserEmail();
    if (this.usertoken && this.usertoken.access_token) {
      this.token = this.usertoken.access_token;
    }
    else{
      this.token=localStorage.getItem('access_token')
    }

  }

  // access_token=this.localstorage.getItem()

  getArts(listParams) {

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + this.token,
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/artwork/search`, JSON.stringify(listParams), {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
              icon: 'error',
              title: 'Invalid request',
              showConfirmButton: false,
              timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  getMyArts(userid) {
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + this.token,
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/artwork/user?accountOwnerUserId=${userid}`, {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  getMyFavProjectsArts(pageNumber, limit, accountOwnerUserId) {

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + this.token,
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/artwork/favorite?socialOrigin=${this.socialOrigin}&email=${this.email}&pageNumber=` + pageNumber + `&limit=` + limit + `&accountOwnerUserId=` + accountOwnerUserId, JSON.stringify({}), {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }
  searchExperts(listParams) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/user/search`, JSON.stringify(listParams), {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  getUsersAllActivities(accountOwnerUserId) {
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + this.token,
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/user/activities?socialOrigin=${this.socialOrigin}&email=${this.email}&userId=` + accountOwnerUserId, JSON.stringify({}), {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  getCategories() {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/master/artwork/categories`, {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  getMediums() {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/master/mediums`, {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  getSoftwares() {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/master/softwares`, {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  getTags() {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/master/tags`, {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  saveTags(fdata) {

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + this.token,
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/master/tag?socialOrigin=${this.socialOrigin}&email=${this.email}`, JSON.stringify(fdata), {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  saveMedium(fdata) {

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + this.token,
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/master/medium?socialOrigin=${this.socialOrigin}&email=${this.email}`, JSON.stringify(fdata), {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }
  getSkills() {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/master/skills`, {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  getBackgroundContent(screenName, count) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/master/customizeBackgroundContent/screen?screenName=` + screenName + `&count=` + count, {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  getAllSocialPlatforms() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/master/socialPlatforms`, {
      headers: headers
    })
      .pipe(
        tap(data => { return data }), catchError(error => {
          /*Swal.fire({
            icon: 'error',
            title: 'Invalid request',
            showConfirmButton: false,
            timer: 6000
            });*/
          return throwError(error);
        })
      );
  }

  getMediaMetaData(purpose, contentType, file) {
    let key: string = null;
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + this.token,
      'Content-Type': 'application/json'
    });

    return this.http.get(`${environment.apiUrl}/media/presigned?socialOrigin=${this.socialOrigin}&email=${this.email}&purpose=${purpose}&contentType=${contentType}`, {
      headers: headers
    })
      .pipe(
        concatMap(
          (res) =>
            this.s3UploadCall(res['payload']['preSignedUrl'], res['payload']['key'], file, contentType)),
        concatMap(
          () => this.updateMediaInfoOnServer(this.key, purpose)));
  }

  getArtworkMetaData(purpose, contentType, file) {
    let key: string = null;
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + this.token,
      'Content-Type': 'application/json'
    });

    return this.http.get(`${environment.apiUrl}/media/presigned?socialOrigin=${this.socialOrigin}&email=${this.email}&purpose=${purpose}&contentType=${contentType}`, {
      headers: headers
    })
      .pipe(
        concatMap(
          (res) =>
            this.s3UploadCall(res['payload']['preSignedUrl'], res['payload']['key'], file, contentType)));
  }

  s3UploadCall(preSignedUrl, key, file, contentType) {
    this.key = key;
    const headersPic = new HttpHeaders({
      'Content-Type': contentType
    });
    return this.http.put(preSignedUrl, file, { headers: headersPic });
  }

  updateMediaInfoOnServer(key, purpose) {
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + this.token,
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/user/media/update?socialOrigin=${this.socialOrigin}&email=${this.email}&profilePicKey=` + key + `&purpose=` + purpose, {}, {
      headers: headers
    })
  }

  fetchMemberDetails(searchString) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + this.token
    });
    return this.http.get(`${environment.apiChatUrl}/member/search?searchString=` + searchString, {
      headers: headers
    })
      .pipe(data => {
        return data;
      });
  }
  getMyChatRoom(email) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + this.token
    });
    return this.http.get(`${environment.apiChatUrl}/chat/room/me?email=` + email, {
      headers: headers
    })
      .pipe(data => {
        return data;
      });
  }

  getMyUnReadMessageCount() {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + this.token
    });
    return this.http.get(`${environment.apiChatUrl}/chat/unread/count`, {
      headers: headers
    })
      .pipe(data => {
        return data;
      });
  }

  updateChatMessageStatus(destination) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + this.token
    });
    return this.http.put(`${environment.apiChatUrl}/chat/read/status?uniqueIdentifier=` + destination, {}, {
      headers: headers
    })
      .pipe(data => {
        return data;
      });
  }
  removeMemberFromChatRoom(memberId, uniqueIdentifier) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + this.token
    });
    return this.http.put(`${environment.apiChatUrl}/chat/room/member/remove?uniqueIdentifier=` + uniqueIdentifier + `&memberId=` + memberId, {}, {
      headers: headers
    })
      .pipe(data => {
        return data;
      });
  }
  fetchDetailsInChatRoom(uniqueIdentifier, limit, pageNumber) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + this.token
    });
    return this.http.get(`${environment.apiChatUrl}/chat/room/details/fetch?uniqueIdentifier=` + uniqueIdentifier + `&pageNumber=` + pageNumber + `&limit=` + limit, {
      headers: headers
    })
      .pipe(data => {
        return data;
      });
  }
  saveOrEditChatRoom(fdata) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + this.token
    });
    return this.http.post(`${environment.apiChatUrl}/chat/room`, JSON.stringify(fdata), {
      headers: headers
    })
      .pipe(data => {
        return data;
      });
  }


  // getstatebyCountryId() {
  // 	const headers = new HttpHeaders({
  // 		'Content-Type': 'application/json',
  // 		'Authorization': 'Basic ' + this.token
  // 	});
  // 	return this.http.get(`${environment.apiUrl}/state/by-country?countryId=101`, {
  // 		headers: headers
  // 	})
  // 		.pipe(data => {
  // 			return data;
  // 		});
  // }

  getstatebyCountryId() {

    let httpheader = new HttpHeaders()
      .set('Authorization', 'Basic + this.token');
    let options = {
      headers: httpheader
    };
    return this.http.get(`${environment.apiUrl}/global/city/by-country?countryId=101`)
  }

  getStateByCityId(cityId: number) {

    let httpheader = new HttpHeaders()
      .set('Authorization', 'Basic + this.token');
    let options = {
      headers: httpheader
    };
    return this.http.get(`${environment.apiUrl}/global/state/by-city?cityId=${cityId}`, options)
  }

  searchCity(name) {
    let httpheader = new HttpHeaders()
      .set('Authorization', 'Basic + this.token');
    let options = {
      headers: httpheader
    };
    return this.http.get(`${environment.apiUrl}/global/city/by-name?name=${name}`, options)
  }
  searchstate(name) {
    let httpheader = new HttpHeaders()
      .set('Authorization', 'Basic + this.token');
    let options = {
      headers: httpheader
    };
    return this.http.get(`${environment.apiUrl}/global/city/${name}`, options)
  }

  getCitybyStateId(stateId: number) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/global/city/by-state?stateId=${stateId}`, options);
  }

  //   getCitybyCountryId() {
  // 	let httpHeader = new HttpHeaders()
  // 	  .set('Authorization', 'Basic ' + this.token);
  // 	let options = {
  // 	  headers: httpHeader
  // 	};
  // 	return this.http.get(`${environment.apiUrl}/global/city/by-country?countryId=101`);
  //   }
  // https://api.ksquaretech.co.in/ksa_backend/api/global/city/by-name

  getCitybyCountryId() {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/global/city/by-name`);
  }


  //   userProfile(access:any) {
  // 	let httpheader = new HttpHeaders()
  // 		.set('Authorization', 'Basic ' + access);
  // 	let options = {
  // 		headers: httpheader
  // 	};

  // 	return this.http.get(`${environment.apiUrl}/user/profile`, options);
  // }
  getProprtybyUser() {
    let allcityId = localStorage.getItem('allcityId');
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    console.log(this.token);

    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/estate/fetch-properties/by-user?cityId=${allcityId}`, options);
  }

  // getrecentlyadd() {
  // let httpHeader = new HttpHeaders()
  //   .set('Authorization', 'Basic ' + this.token);

  // let options = {
  //   headers: httpHeader
  // };
  // return this.http.get(`${environment.apiUrl}/estate/recently-added/properties`,options);
  // }
  // http://localhost:8080/ksa_backend/api/estate/recently-added/properties?cityId=122032&stateId=4007
  getrecentlyadd() {
    let allcityId = localStorage.getItem('allcityId');
    let allstateId = localStorage.getItem('allstateId');

    let httpHeader = new HttpHeaders().set('Authorization', 'Basic ' + this.token);

    let options = {
      headers: httpHeader
    };

    return this.http.get(`${environment.apiUrl}/estate/recently-added/properties?cityId=${allcityId}&stateId=${allstateId}`, options);
  }

  getMyProjects() {
    let allcityId = localStorage.getItem('allcityId');
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);

    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/project/by-user?cityId=${allcityId}`, options);
  }

  CreateProperty(payload: any) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.post(`${environment.apiUrl}/estate/save`, payload, options);
  }
  //   /user_query/save?email=369_employee%40mailinator.com

  postQuery(payload: any) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.post(`${environment.apiUrl}/user_query/save`, payload, options);
  }

  saveProject(payload: any) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.post(`${environment.apiUrl}/project/save`, payload, options);
  }

  //   /inventory/save?projectId=16
  saveInventory(payload: any, ProjectId: number) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.post(`${environment.apiUrl}/inventory/save?projectId=${ProjectId}`, payload, options);
  }

  //   ownwerdetail(payload:any) {
  // 	let httpHeader = new HttpHeaders()
  // 	  .set('Authorization', 'Basic'  + this.token);
  // 	let options = {
  // 	  headers: httpHeader
  // 	};
  // 	return this.http.post(`${environment.apiUrl}/properties_leads/save`, payload,options);
  //   }
  ownwerdetail(payload: any) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    console.log(this.token);

    let options = {
      headers: httpHeader
    };
    return this.http.post(`${environment.apiUrl}/estate/leads/save`, payload, options);
  }

  Updateroperty(payload: any, id: any) {
    console.log(id);

    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.put(`${environment.apiUrl}/estate/update`, payload, {
      headers: httpHeader,
      params: { id: id }
    });
  }
  //   project/update?id=26
  //   /properties/by-id?id=26

  UpdateProject(payload: any, id: any) {
    console.log(id);

    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.put(`${environment.apiUrl}/project/update`, payload, {
      headers: httpHeader,
      params: { id: id }
    });
  }
  //   /inventory/update?id=5

  UpdateInventory(payload: any, id: any) {
    console.log(id);

    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.put(`${environment.apiUrl}/inventory/update`, payload, {
      headers: httpHeader,
      params: { id: id }
    });
  }

  getInventoryByProjectId(projectId) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/inventory/inventory-list/by-project?projectId=${projectId}`, options);

  }


  getPropertyById(propertyId) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/estate/by-id?id=${propertyId}`, options);
  }
  getuserById(propertyId) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ');
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/estate/by-id/without/user?id=${propertyId}`, options);
  }

  getInventoryByInventoryId(inventoryId) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/inventory/by-id?id=${inventoryId}`, options);
  }

  getProjectById(propertyId) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/project/by-id?id=${propertyId}`, options);
  }
  getInventoryById(propertyId) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/inventory/by-id?id=${propertyId}`, options);
  }


  //   /profession_details/fetch/by-project-name?name=property
  getprofession_details(name: any) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ');
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/profession_details/fetch/by-project-name?name=${name}`, options);
  }

  getPropertyByCityId(cityId) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ');
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/estate/by-cityId?cityId=${cityId}`, options);
  }

  // getrecentlyadd() {
  //   let allcityId = localStorage.getItem('allcityId');
  //   let allstateId = localStorage.getItem('allstateId');

  //   let httpHeader = new HttpHeaders().set('Authorization', 'Basic ' + this.token);

  //   let options = {
  //     headers: httpHeader
  //   };

  //   return this.http.get(`${environment.apiUrl}/estate/recently-added/properties?cityId=${allcityId}&stateId=${allstateId}`, options);
  // }




  getProjectTop10List(name: any) {
    let allcityId = localStorage.getItem('allcityId');
    let allstateId = localStorage.getItem('allstateId');
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ');
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/project/recently-added?project=${name}&cityId=${allcityId}&stateId=${allstateId}`, options);
  }

  //   getProjectList(type: string, page: number = 1, itemsPerPage: number = 10): Observable<any> {
  // 	return this.http.get<any>(`/api/projects?type=${type}&page=${page}&itemsPerPage=${itemsPerPage}`);
  //   }

  //   getPropertyByCityIdAndArea(cityId,area){
  //     let httpHeader = new HttpHeaders()
  // 	  .set('Authorization', 'Basic ');
  // 	let options = {
  // 	  headers: httpHeader
  // 	};
  // 	return this.http.get(`${environment.apiUrl}/=${cityId}&area=${area}`, options);
  //   }
  getPropertyByCityIdAndArea(cityId, area) {
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ');
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/estate/by-cityId?cityId=${cityId}&area=${area}`, options);
  }

  property(pdata) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + this.token
    });
    return this.http.post(`${environment.apiChatUrl}/estate/fetch/properties?userType=User`, JSON.stringify(pdata), {
      headers: headers
    })
      .pipe(data => {
        return data;
      });
  }

  getMediaData(purpose: string, contentType: string, file: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + this.token,
      }),
    };

    return this.http
      .get(
        `${environment.apiUrl}/global/media/presigned?purpose=${purpose}&contentType=${contentType}`,
        httpOptions
      )
      .pipe(
        concatMap((res: any) =>
          this.s3Upload(
            res['payload']['preSignedUrl'],
            res['payload']['key'],
            file,
            contentType
          )
        ),
        map((response: any) => {
          return { url: response.url, type: contentType };
        })
      );
  }


  s3Upload(
    preSignedUrl: string,
    key: string,
    file: any,
    contentType: string
  ) {
    // this.key = key;
    // const formData = new FormData();
    // formData.append("file", file)
    const headersPic = new HttpHeaders({
      'Content-Type': contentType,
    });

    let httpheader = new HttpHeaders().set('Content-Type', contentType);
    let options = {
      headers: httpheader,
    };

    return this.http.put(preSignedUrl, file, options).pipe(
      map((response: any) => {
        return { url: key, type: contentType };
      })
    );
  }


  getBanner() {

    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.token);

    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/project/fetch-All-Banners`, options);
  }

  getProjectList(name: any, searchValue: any) {
    let allcityId = localStorage.getItem('allcityId');
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ');
    let options = {
      headers: httpHeader
    };
    //   /project/paginated/project?projectType=recent&page=0&size=20'
    return this.http.get(`${environment.apiUrl}/project/paginated/project?projectType=${name}&page=0&size=30&searchValue=${searchValue}&cityId=${allcityId}`, options);
  }


  ProjectList(name: any, searchValue: any, page, size) {
    let allcityId = localStorage.getItem('allcityId');
    let httpHeader = new HttpHeaders()
      .set('Authorization', 'Basic ');
    let options = {
      headers: httpHeader
    };
    return this.http.get(`${environment.apiUrl}/project/paginated/project?projectType=${name}&page=${page}&size=${size}&searchValue=${searchValue}&cityId=${allcityId}`, options);
  }
}
