import { Component, HostListener, OnInit } from '@angular/core';
import { HomeService } from '../_services/home.service';
import { Router } from '@angular/router';
import { LoadingService } from '../_services/loading.service';
import { HeaderSearchService } from '../_services/header-search.service';
import {Observable} from 'rxjs';
import { ErrorMessageService } from '../services/error-message.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-user-property',
  templateUrl: './user-property.component.html',
  styleUrls: ['./user-property.component.scss'],
})
export class UserPropertyComponent implements OnInit {
  showMore: boolean = false;
  loading$: Observable<boolean>;
  listings: any[] = [];
  filteredListings: any[] = [];
  searchValue: string = '';
  Images: string[] = [];
  showTopIcon = false;
  currentPage: number = 0;
  pageSize: number = 10;
  totalPages: number = 0;
  IsLoading: boolean = false;

  constructor(
    private homeService: HomeService,
    private router: Router,
    private loadingService: LoadingService,
    private searchService: HeaderSearchService,
     private errorMessageService: ErrorMessageService,
        private toastr:ToastrService
  ) {}

  ngOnInit(): void {
    this.getProprtybyUser();
    this.searchCity()
    this.loading$ = this.loadingService.loading$;
    const storedCityName = localStorage.getItem('selectedCityName');
    if (storedCityName) {
      this.searchCityQuery = storedCityName;
    }
  }

  // getProprtybyUser() {
  //   this.loadingService.show()
  //   this.homeService.getProprtybyUser().subscribe(
  //     (response: any) => {
  //       if (response && response.payload) {
  //         this.loadingService.hide();
  //         this.listings = response.payload;
  //         this.filteredListings = this.listings; // Initialize filtered list
  //         console.log('Listings:', this.listings);
  //       } else {
  //         console.error('Unexpected data format:', response);
  //         this.listings = [];
  //         this.filteredListings = [];
  //       }
  //     },
  //     (error) => {
  //       this.loadingService.hide();
  //       console.error('Error loading listings:', error);
  //       this.listings = [];
  //       this.filteredListings = [];
  //     }
  //   );
  // }
  getProprtybyUser() {
    this.loadingService.show();

    this.homeService.getProprtybyUser().subscribe(
      (response: any) => {
        this.loadingService.hide();

        if (response && response.payload) {
          this.listings = response.payload;

          // Set total pages and initialize pagination
          this.totalPages = Math.ceil(this.listings.length / this.pageSize);
          this.currentPage = 0;
          this.updatePagination();
        } else {
          this.listings = [];
          this.filteredListings = [];
          const errorMessage = this.errorMessageService.getErrorMessage('No properties found');
          this.toastr.error(errorMessage, 'Error');
        }
      },
      (error) => {
        this.loadingService.hide();
        this.listings = [];
        this.filteredListings = [];

        const message = error?.error?.message || 'Failed to load property listings';
        const errorMessage = this.errorMessageService.getErrorMessage(message);
        this.toastr.error(errorMessage, 'Error');
      }
    );
  }



  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTopIcon = window.scrollY > 100;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  toggleShowMore(): void {
    this.showMore = !this.showMore;
  }

  // searchListings(): void {
  //   const searchTerm = this.searchValue.toLowerCase();
  //   this.filteredListings = this.listings.filter((listing) =>
  //     Object.values(listing).some((value) =>
  //       value && value.toString().toLowerCase().includes(searchTerm).valueOf()
  //     )
  //   );
  // }
  searchListings(): void {
    const searchTerm = this.searchValue.toLowerCase().trim();

    // Filter listings based on search term
    const searchedListings = this.listings.filter((listing) => {
      const normalizedTitle = listing.title.replace(/\s+/g, ' ').toLowerCase().trim();
      return normalizedTitle.includes(searchTerm);
    });

    // Update total pages based on searched results
    this.totalPages = Math.ceil(searchedListings.length / this.pageSize);

    // Reset to first page and apply pagination
    this.currentPage = 0;
    this.filteredListings = searchedListings.slice(0, this.pageSize);
  }
  selectedCityName: string | null = localStorage.getItem('selectedCityName');
  showDropdown(inputElement: HTMLInputElement) {
    this.onCityInputClick()
    this.showCitySuggestions = true;
    inputElement.focus();  // Ensure cursor stays in the input field
  }

  hideDropdown() {
    this.showCitySuggestions = false;
  }

  clearSearch(): void {
    this.searchValue = '';
    this.filteredListings = [...this.listings];
  }


  onCityInputClick() {
    this.clearSearchdrop()
    if (this.cityDataList.length > 0) {
      this.showCitySuggestions = true;
    }
  }
  hideCitySuggestions() {
    setTimeout(() => {
      this.showCitySuggestions = false;
    }, 200); // Small delay to allow click event to register
  }
  // clearSearch() {
  //   this.searchTerm = '';
  //   this.applySearchFilter(); // Optionally apply the filter to clear results
  // }

  selectedCityId: string | null = null;
  searchCityQuery: string = 'Gurgaon';
  cityDataList: any[] = [];
  allstateId:any
  showCitySuggestions:boolean=false
  searchQuery:any

  searchCity(searchTerm: string = '') {
   if (this.selectedCityId) {
    this.selectedCityId = null;
    this.searchCityQuery = searchTerm;
  }

    this.homeService.searchCity(searchTerm).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.cityDataList = response.payload;
        } else {
          console.error('Failed to fetch city data:', response);
        }
      },
      (error) => {
        console.error('Error fetching city data:', error);
      }
    );
  }

  selectCity(city: any) {
    this.searchQuery = '';
    this.selectedCityId = city.id;
    this.allstateId = city.stateId;

    console.log('this.selectedCityId', this.selectedCityId);

    // Store city details in localStorage
    localStorage.setItem('allcityId', this.selectedCityId);
    localStorage.setItem('allstateId', this.allstateId);
    localStorage.setItem('selectedCityName', city.name);
    localStorage.setItem('selectedCityId',this.selectedCityId);
    let currentUrl = this.router.url;
    // window.location.reload();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    this.searchCityQuery = city.name; // Set input value
    this.showCitySuggestions = false;

  }


  clearSearchdrop() {
    this.searchCityQuery = '';
    this.showCitySuggestions = false;
    this.searchCity()

  }



  updatePagination(): void {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.filteredListings = this.listings.slice(startIndex, endIndex);
  }


  changePage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;

      const startIndex = page * this.pageSize;
      const endIndex = startIndex + this.pageSize;

      // Ensure correct pagination applies after searching
      this.filteredListings = this.listings.slice(startIndex, endIndex);
    }
  }


  getPages(): number[] {
    const pages: number[] = [];
    for (let i = 0; i < this.totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }






}
