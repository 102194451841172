import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HomeService } from '../_services/home.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-project-common-filter',
  templateUrl: './project-common-filter.component.html',
  styleUrls: ['./project-common-filter.component.scss']
})
export class ProjectCommonFilterComponent implements OnInit {
  @Output() searchTermChange: EventEmitter<string> = new EventEmitter<string>();
  searchTerm: string = '';
constructor(private homeService:HomeService,private router:Router){

}

  applySearchFilter() {
    // Emit the search term to the parent component
    this.searchTermChange.emit(this.searchTerm);
  }

  ngOnInit(): void {
    this.searchCity()
    const storedCityName = localStorage.getItem('selectedCityName');
    if (storedCityName) {
      this.searchCityQuery = storedCityName;
    }
  }
  onCityInputClick() {
    this.clearSearchdrop()
    if (this.cityDataList.length > 0) {
      this.showCitySuggestions = true;
    }
  }
  hideCitySuggestions() {
    setTimeout(() => {
      this.showCitySuggestions = false;
    }, 200); // Small delay to allow click event to register
  }
  clearSearch() {
    this.searchTerm = '';
    this.applySearchFilter(); // Optionally apply the filter to clear results
  }

  selectedCityId: string | null = null;
  searchCityQuery: string = 'Gurgaon';
  cityDataList: any[] = [];
  allstateId:any
  showCitySuggestions:boolean=false
  searchQuery:any

  searchCity(searchTerm: string = '') {
   if (this.selectedCityId) {
    this.selectedCityId = null;
    this.searchCityQuery = searchTerm;
  }

    this.homeService.searchCity(searchTerm).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.cityDataList = response.payload;
        } else {
          console.error('Failed to fetch city data:', response);
        }
      },
      (error) => {
        console.error('Error fetching city data:', error);
      }
    );
  }


  selectedCityName: string | null = localStorage.getItem('selectedCityName');
  showDropdown(inputElement: HTMLInputElement) {
    this.onCityInputClick()
    this.showCitySuggestions = true;
    inputElement.focus();  // Ensure cursor stays in the input field
  }

  hideDropdown() {
    this.showCitySuggestions = false;
  }









  selectCity(city: any) {
    this.searchQuery = '';
    this.selectedCityId = city.id;
    this.allstateId = city.stateId;

    console.log('this.selectedCityId', this.selectedCityId);

    // Store city details in localStorage
    localStorage.setItem('allcityId', this.selectedCityId);
    localStorage.setItem('allstateId', this.allstateId);
    localStorage.setItem('selectedCityName', city.name);
    localStorage.setItem('selectedCityId',this.selectedCityId);
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    this.searchCityQuery = city.name; // Set input value
    this.showCitySuggestions = false;
    // window.location.reload();
  }


  clearSearchdrop() {
    this.searchCityQuery = '';
    this.showCitySuggestions = false;
    this.searchCity()

  }





}
