import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CellClickedEvent, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subscription,Observable } from 'rxjs';
import { HomeService } from 'src/app/_services/home.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ErrorMessageService } from 'src/app/services/error-message.service';

@Component({
  selector: 'app-trending-project-listing',
  templateUrl: './trending-project-listing.component.html',
  styleUrls: ['./trending-project-listing.component.scss']
})
export class TrendingProjectListingComponent implements OnInit {
   loading$: Observable<boolean>;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any = {};
  rowData: any = [];
  IsLoading: boolean = false;
  paginationPageSize!: number;
  subs$!: Subscription;
  gridOptions: GridOptions = {
    pagination: true,
    rowHeight: 40,
  };
  userId:any;
  module: any;
  subModule: any;
  accessRight: any;
  createRight: boolean = false;
  deleteRight: boolean = false;
  updateRight: boolean = false;
  viewRight: boolean = false;
  userType: any;
  public frameworkComponents:any;
  user: any;
  selectedOption: any = null;
  userlist: any[] = [];
  trendingList: any[] = [];
  searchValue: string = '';
  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private errorMessageService: ErrorMessageService,
    private toastr:ToastrService

  ) { }

  ngOnInit(): void {
    this.getProjectList();
    this.loading$ = this.loadingService.loading$;
  }
  onSearchTermChange(searchTerm: string) {
    this.searchValue = searchTerm;
    this.getProjectList();
  }
  getProjectList() {
    this.loadingService.show();
    this.homeService
      .getProjectList('trending',this.searchValue || '')
      .subscribe((response: any) => {
        this.IsLoading = false;
        if (response.status === "SUCCESS") {
          this.loadingService.hide();
          this.trendingList = response.payload.items;
          console.log(this.rowData);
        } else if (response['message']) {
          let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          this.toastr.error(errorMessage, 'Error');
        }
      },(error) => {
        const message = error?.error?.message || 'Failed to fetch city data';
        const errorMessage = this.errorMessageService.getErrorMessage(message);
        this.toastr.error(errorMessage, 'Error');
      });

  }
  stripHtmlTags(input: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    return doc.body.textContent || '';
  }
}
