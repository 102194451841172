import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  showContent:boolean=false
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.showContent = true;
    },);
  }
  refresh(){
    window.history.back();
  }
}
