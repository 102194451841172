import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/_services/auth.service';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
import { ErrorMessageService } from 'src/app/services/error-message.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-emailverify',
  templateUrl: './emailverify.component.html',
  styleUrls: ['./emailverify.component.scss']
})
export class EmailverifyComponent implements OnInit {
  otp: string = '';
  number: any;
  isMessage: boolean = false;
  message: string = '';
  status: boolean = false;
  email: string;
  @Output() emailVerified = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    private errorMessageService:ErrorMessageService,
    private toastr:ToastrService,
    public dialogRef: MatDialogRef<EmailverifyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.email = data.email;
    console.log('this.email',this.email);

  }

  ngOnInit(): void {
    // Add your initialization logic here
  }

  verifyEmail(isVerified: boolean): void {
    this.emailVerified.emit(isVerified);
    this.dialogRef.close();
  }

  onInputBlur(number: string) {
    this.number = number;
  }

  onOtpChange(otp: string) {
    this.otp = otp;
    console.log(otp);

  }
  otpConfig = {
    length: 6,
    allowNumbersOnly: true
  };
  verifyOtp() {
    console.log('OTP:', this.otp);
    console.log('Email in verify token:', this.email);

    this.authService.verifyOtp(this.email, this.otp).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.emailVerified.emit(true);
          this.dialogRef.close();
          this.toastr.success('User verification is completed.', 'Success');
        } else if (response['message']) {
          let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          this.toastr.error(errorMessage, 'Error');
        }
      },
      (error) => {
        const errorMessage = 'An error occurred during OTP verification.';
        this.toastr.error(errorMessage, 'Error');
      }
    );
  }
closeDialog(){
  this.dialogRef.close();

}
}
