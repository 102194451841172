

<div class="page-container">

  <app-header></app-header>
  <app-menu></app-menu><br>
  <div class="container-fluid d-flex flex-column align-items-center " >
    <!-- Avatar/Profile Picture -->
    <div class="avatar-banner">
      <img alt="Profile Picture" src="{{profilePic}}" class="profile-pic" />
    </div>

    <!-- Form -->
    <div class="contact-form-wrapper mt-5">
      <div class="contact-form">
        <h3>Your Contact Details</h3>
        <form [formGroup]="updateform" (ngSubmit)="onSubmit()">
          <h2 class="createaccount">Update Your Profile</h2>

          <div class="form-group">
            <input type="text" class="form-control" placeholder="Full Name" formControlName="fullName" />
            <div class="errorClass" *ngIf="submitted && f.fullName.errors">
              <div *ngIf="f.fullName.errors.required">Full Name is required</div>
              <div *ngIf="f.fullName.errors.minlength">Full Name must be at least 8 characters</div>
              <div *ngIf="f.fullName.errors.pattern">Full Name must contain alphanumeric characters</div>
            </div>
          </div>

          <div class="form-group">
            <input type="email" class="form-control" placeholder="Email" formControlName="email" readonly/>
            <div class="errorClass" *ngIf="submitted && f.email.errors">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
<!--
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Mobile Number" formControlName="mobileNumber" />
            <div class="errorClass" *ngIf="submitted && f.mobileNumber.errors">
              <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
              <div *ngIf="f.mobileNumber.errors.pattern">Mobile Number must be valid</div>
            </div>
          </div> -->

          <div class="mobile-input-container">
            <!-- Country Code Dropdown -->
            <select formControlName="mobileCode" class="country-code" [(ngModel)]="selectedPhoneCode">
              <option *ngFor="let code of mobileCode" [value]="code">
                {{ code }}
              </option>
            </select>

            <!-- Mobile Number Input -->
            <input
              type="text"
              placeholder="Enter Mobile Number"
              formControlName="mobileNumber"
              (input)="onInput($event)"
              class="mobile-input"
              maxlength="10"
            />
          </div>






          <!-- <div class="form-group">
            <input type="password" class="form-control" placeholder="Password" formControlName="password" />
            <div class="errorClass" *ngIf="submitted && f.password.errors">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.pattern">Password must be valid</div>
            </div>
          </div> -->

          <!-- <div class="row col-md-12"> -->
            <div class="custom-form-field">
              <!-- <label for="About me" class="post-headings" >About me *</label> -->
              <textarea formControlName="aboutMe" class="aboutMe-textarea4" placeholder="Enter about me"></textarea>
              <div class="errorClass" *ngIf="submitted && f.aboutMe.errors">
                <div *ngIf="f.aboutMe.errors.required">About me is required</div>
                <div *ngIf="f.aboutMe.errors.pattern">About me must be valid</div>
              </div>
            </div><br>
          <!-- </div> -->


          <!-- <h2 class="createaccount1">Are You</h2> -->
          <div class="responsive-container">
            <div class="user-type-section">
              <div class="user-type-options">
                <label>
                  <input type="radio" formControlName="userType" [value]="'individual'" /> Individual
                </label>
                <label>
                  <input type="radio" formControlName="userType" [value]="'builder'" /> Builder
                </label>
                <label>
                  <input type="radio" formControlName="userType" [value]="'agent'" /> Dealer
                </label>
              </div>
            </div>

            <div class="reset-password-section">
              <div class="reset-password" (click)="navigateToReset()">
                Reset Password
              </div>
            </div>
          </div>
          <div class="button-group" style="display: flex; gap: 10px; justify-content: flex-end;">
            <button class="btn onlyhover" (click)="back()">Back</button>
            <button class="btn onlyhover" type="submit">Update & Save</button>
          </div>
        </form>
      </div>
      <!-- <div class="row col-md-12 d-flex justify-content-end">
        <div class="button-group" (click)="back()" >
          <button class="btn1 onlyhover">Back</button>
        </div>
      </div> -->
    </div>
  </div><br><br>

  <app-footer style="padding-top: 20px;" ></app-footer>
</div>
