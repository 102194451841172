import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/_services/home.service';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  // privacyContent: string | null = null;

  FAQ: string | null = null;
	projectName: any
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private homeService: HomeService,
		private cd: ChangeDetectorRef) { }


	ngOnInit(): void {
		this.projectName = "Property"
		this.getprofession_details(this.projectName)
	}

  getprofession_details(projectName: string): void {
    this.homeService.getprofession_details(projectName).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          // Find the item with type 'ContacUs'
          const FAQ = response.payload.filter((item: any) => item.type === 'FAQ');
          const letestFoAQ=FAQ[FAQ.length -1]
          if (letestFoAQ) {
            this.FAQ = letestFoAQ.content;

          } else {
            console.error('No ContacUs found in the response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }
  isOpen: { [key: string]: boolean } = {};

  // Toggle the dropdown state
  toggleDropdown(id: string) {
    this.isOpen[id] = !this.isOpen[id];
  }

}

