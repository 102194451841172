import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class CroreFormatPipe implements PipeTransform {
  transform(value: number | null, unit: string = 'Lac'): string {
    if (value === null || value === undefined) {
      return 'N/A';
    }
    
    switch (unit) {
      case 'Cr':
        return `${(value / 10000000).toFixed(2).replace(/\.00$/, '')} Crore`;
      case 'Lac':
      default:
        return `${(value / 100000).toFixed(2).replace(/\.00$/, '')} Lac`;
    }
  }
}
